import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from 'twin.macro'

import { useFetchData } from 'hooks'
import { StyledHeader, Wrapper, StyledButton, StyledEditor, StyledEditorSidebar } from './styles'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { AuthContext } from 'store/AuthContext'

const FloorMapPage = () => {
	const { t } = useTranslation()
	const [isSidebarOpen, setSidebarOpen] = useState(false)
	const [floormapConfig, setFloormapConfig] = useState<any[]>([])
	const storeCtx = useContext(StoreSettingsContext)
	const { auth } = useContext(AuthContext)

	useFetchData({
		name: 'fetchMapConfig',
		endpoint: '/settings/widget/',
		options: {
			onSuccess: (res: any) => {
				const { floormap_config } = res.data

				Array.isArray(floormap_config) ? setFloormapConfig(floormap_config) : setFloormapConfig([])
			},
			enabled: !!auth.accessToken
		}
	})

	const handleDragEnd = (e: any) => {
		const { attrs } = e.target
		const newPosition = { x: attrs.x, y: attrs.y }

		const newState = floormapConfig.map((element) => {
			return element.id === attrs.id ? { ...element, ...newPosition } : element
		})

		setFloormapConfig(newState)
	}

	const transformElement = (e: any) => {
		const { id, rotation, x, y } = e.target.attrs

		const newState = floormapConfig.map((element) =>
			element.id === id ? { ...element, rotation, x, y } : element
		)

		setFloormapConfig(newState)
	}

	const deleteElement = (id: string) => {
		const newState = floormapConfig.filter((el) => el.id !== id)

		setFloormapConfig(newState)
	}

	const handleSubmit = (data: any) => {
		setFloormapConfig(data)
	}

	return (
		<>
			<StyledHeader title={t('floorMap.title')} />
			<Wrapper>
				<StyledEditorSidebar
					floormapConfig={floormapConfig}
					onSubmit={handleSubmit}
					isSidebarOpen={isSidebarOpen}
				/>
				<StyledEditor
					isAdmin={true}
					isSidebarOpen={isSidebarOpen}
					floormapConfig={floormapConfig}
					handleDragEnd={handleDragEnd}
					transformElement={transformElement}
					deleteElement={deleteElement}
					chosenBoothColor={theme`colors.clementine`}
					boothColor={storeCtx?.primaryColor}
				/>
				<StyledButton
					text={isSidebarOpen ? 'Close sidebar' : 'Add element'}
					onClick={() => setSidebarOpen(!isSidebarOpen)}
				/>
			</Wrapper>
		</>
	)
}

export default FloorMapPage
