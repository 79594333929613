import { useState, useContext, useEffect } from 'react'
import { useForm, useController, Controller } from 'react-hook-form'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SketchPicker } from 'react-color'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import tw, { theme } from 'twin.macro'
import { useTranslation } from 'react-i18next'

import Field from './Field'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import Label from 'components/Ui/Label'

const customStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isSelected || state.isFocused ? '#fff' : '#676767',
		backgroundColor: state.isSelected || state.isFocused ? '#465CFF' : '#fff',
		cursor: 'pointer'
	}),
	control: (base: any, state: any) => {
		return {
			display: 'flex',
			boxShadow: state.isFocused ? '0 0 5px #465cff' : '0px 0px 4px rgba(0, 0, 0, 0.08)',
			borderRadius: '8px',
			backgroundColor: '#fff',
			margin: '10px 0'
		}
	}
}

interface IEditorSidebar {
	floormapConfig: any[]
	onSubmit: (data: any) => void
	className?: string
}

const EditorSidebar = ({ floormapConfig, className = '', onSubmit }: IEditorSidebar) => {
	const [rectangleBackgroundColor, setRectangleBackgroundColor] = useState('#fff')
	const [circleBackgroundColor, setCircleBackgroundColor] = useState('#fff')
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const storeCtx = useContext(StoreSettingsContext)

	const saveFloorMapSuccessMsg = useToast({
		type: 'success',
		message: t('floorMap.form.success')
	})
	const saveFloorMapFailMsg = useToast({
		type: 'error',
		message: t('floorMap.form.error')
	})

	const fetchData = async ({ pageParam }: { pageParam: string }) => {
		const res = await axiosPrivate.get(`/booths/${pageParam ? `?cursor=${pageParam}` : ''}`)

		return res.data
	}

	const {
		data: booths,
		isLoading: loadingBooths,
		fetchNextPage: fetchNextBooths
	} = useInfiniteQuery('fetchBooths', async ({ pageParam }) => fetchData({ pageParam }), {
		getNextPageParam: (lastPage) => {
			let page

			if (lastPage.next) {
				const url = new URL(lastPage?.next)
				page = url.searchParams.get('cursor')!
			}

			return page || undefined
		}
	})

	const boothSchema = yup.object({
		booth: yup.object({
			label: yup
				.string()
				.matches(/^[0-9]*$/, 'Only numbers')
				.required('This field is required')
		})
	})

	const textSchema = yup.object({
		textLayerValue: yup.string().required('This field is required')
	})

	const rectSchema = yup.object({
		width: yup
			.string()
			.matches(/^[0-9]*$/, 'Only numbers')
			.required('This field is required'),
		height: yup
			.string()
			.matches(/^[0-9]*$/, 'Only numbers')
			.required('This field is required')
	})

	const dimentionsSchema = yup.object({
		width: yup.number().min(100).max(700).typeError('Must be a number'),
		height: yup.number().min(100).max(700).typeError('Must be a number')
	})

	const circleSchema = yup.object({
		radius: yup
			.string()
			.matches(/^[0-9]*$/, 'Only numbers')
			.required('This field is required')
	})

	// TODO
	// line validation
	// const lineSchema = yup.object({
	//   radius: yup
	//     .string()
	//     .matches(/^[0-9]*$/, "Only numbers")
	//     .required("This field is required"),
	// });

	const {
		handleSubmit: handleDimentionsSubmit,
		register: dimentionsRegister,
		formState: { errors: dimentionsError },
		setValue: setDimentionsValue
	} = useForm({
		resolver: yupResolver(dimentionsSchema),
		mode: 'onChange'
	})

	useEffect(() => {
		if (floormapConfig?.findIndex((config) => config.type === 'floorDimentions') !== -1) {
			const { width, height } = floormapConfig.find((config) => config.type === 'floorDimentions')
			setDimentionsValue('width', width)
			setDimentionsValue('height', height)
		}
	}, [floormapConfig])

	const {
		handleSubmit: handleBoothSubmit,
		control: boothControl,
		setError: setBoothError,
		formState: { errors: boothErrors }
	} = useForm({
		resolver: yupResolver(boothSchema),
		mode: 'onChange'
	})

	const {
		handleSubmit: handleTextSubmit,
		register: registerText,
		formState: { errors: textErrors }
	} = useForm({
		resolver: yupResolver(textSchema),
		mode: 'onChange'
	})

	const {
		handleSubmit: handleRectSubmit,
		register: registerRect,
		formState: { errors: rectErrors },
		control: rectangleControl
	} = useForm({
		resolver: yupResolver(rectSchema),
		mode: 'onChange'
	})

	const {
		handleSubmit: handleCircleSubmit,
		register: registerCircle,
		formState: { errors: circleErrors },
		control: circleControl
	} = useForm({
		resolver: yupResolver(circleSchema),
		mode: 'onChange'
	})

	const {
		handleSubmit: handleLineSubmit,
		register: registerLine,
		formState: { errors: lineErrors }
	} = useForm({
		mode: 'onChange'
	})

	const { handleSubmit: handleImageSubmit, register: registerImage } = useForm({
		mode: 'onChange'
	})

	const handleRectangleColorChange = (data: any) => {
		setRectangleBackgroundColor(data.hex)
	}

	const handleCircleColorChange = (data: any) => {
		setCircleBackgroundColor(data.hex)
	}

	const { field: rectangleField } = useController({
		name: 'backgroundColor',
		control: rectangleControl
	})

	const { field: circleField } = useController({
		name: 'backgroundColor',
		control: circleControl
	})

	const boothSubmit = (data: any) => {
		const { label } = data.booth

		if (!floormapConfig.length) {
			return onSubmitBooth(data)
		}

		const isUnique = !floormapConfig.some((item: any) => item.id === label.toString())

		if (!isUnique) {
			return setBoothError('booth', {
				type: 'custom',
				message: 'Booth number is not unique'
			})
		}

		onSubmitBooth(data)
	}

	const dimentionsSubmit = ({ height, width }: any) => {
		const newElement = {
			id: uuidv4(),
			type: 'floorDimentions',
			width,
			height
		}

		const dimentionsIndex = floormapConfig.findIndex((config) => config.type === 'floorDimentions')

		floormapConfig.splice(dimentionsIndex, 1)
		onSubmit([...floormapConfig, newElement])
	}

	const handleSave = () => {
		const body = {
			floormap_config: floormapConfig
		}

		axiosPrivate
			.patch('/settings/widget/', body)
			.then(() => {
				saveFloorMapSuccessMsg()
			})
			.catch(() => {
				saveFloorMapFailMsg()
			})
	}

	const handleLoad = () => {
		queryClient.invalidateQueries('fetchMapConfig')
	}

	const onSubmitText = (data: any) => {
		const newElement = {
			type: 'text',
			x: 85,
			y: 90,
			text: data.textLayerValue,
			shadowBlur: 10,
			draggable: true,
			id: uuidv4()
		}

		onSubmit([...floormapConfig, newElement])
	}

	const onSubmitRectangle = (data: any) => {
		const { width, height, backgroundColor } = data
		const fill = backgroundColor?.hex || '#bbb'

		const newElement = {
			type: 'rect',
			x: 45,
			y: 70,
			width: +width,
			height: +height,
			fill,
			draggable: true,
			id: uuidv4()
		}

		onSubmit([...floormapConfig, newElement])
	}

	const onSubmitCircle = (data: any) => {
		const { radius, backgroundColor } = data
		const fill = backgroundColor?.hex || '#bbb'

		const newElement = {
			type: 'circle',
			x: 45,
			y: 70,
			radius: +radius,
			fill,
			draggable: true,
			id: uuidv4()
		}

		onSubmit([...floormapConfig, newElement])
	}

	const onSubmitLine = (data: any) => {
		const { points } = data

		const newElement = {
			type: 'line',
			x: 45,
			y: 70,
			points: points.split(',').map((point: string) => +point),
			tension: 0.1,
			stroke: 'black',
			strokeWidth: 4,
			draggable: true,
			id: uuidv4()
		}

		onSubmit([...floormapConfig, newElement])
	}

	const onSubmitImage = (data: any) => {
		const { images } = data

		const reader = new FileReader()
		reader.onloadend = () => {
			const image = new Image()

			const base64String = reader.result!.toString()

			image.src = reader.result as string

			image.onload = () => {
				const { width, height } = image

				const newElement = {
					type: 'image',
					src: base64String,
					width,
					height,
					rotation: 0,
					draggable: true,
					id: uuidv4(),
					x: 45,
					y: 70
				}

				onSubmit([...floormapConfig, newElement])
			}
		}
		try {
			reader.readAsDataURL(images[0])
		} catch (err) {
			console.log(err)
		}
	}

	const onSubmitBooth = (data: any) => {
		const newElement = {
			type: 'booth',
			x: 45,
			y: 70,
			width: 25,
			height: 25,
			fill: storeCtx?.primaryColor,
			draggable: true,
			id: data.booth.label.toString()
		}

		onSubmit([...floormapConfig, newElement])
	}

	const sidebarClassName = ['sidebar', ...className].join('')

	return (
		<StyledSidebar className={sidebarClassName}>
			<Title>Admin</Title>
			<Field label="Floor dimentions">
				<DimentionsForm onSubmit={handleDimentionsSubmit(dimentionsSubmit)}>
					<DimentionsRow>
						<div>
							<Label>Width</Label>
							<Input type="text" placeholder="width (px)" {...dimentionsRegister('width')} />
							<Error>{(dimentionsError as any)?.width?.message}</Error>
						</div>
						<div>
							<Label>Height</Label>
							<Input type="text" placeholder="height (px)" {...dimentionsRegister('height')} />
							<Error>{(dimentionsError as any)?.height?.message}</Error>
						</div>
					</DimentionsRow>
					<Button type="submit">Apply</Button>
				</DimentionsForm>
			</Field>
			<Field label="Booth">
				<Form onSubmit={handleBoothSubmit(boothSubmit)}>
					<Controller
						name="booth"
						control={boothControl}
						render={({ field }) => (
							<Select
								styles={customStyles}
								options={
									!loadingBooths && booths != null && Array.isArray(booths?.pages)
										? booths?.pages
												.reduce((total: any, page: any) => {
													return [...total, ...page.results]
												}, [])
												.map((booth: any) => {
													return {
														label: booth.number,
														value: booth.pk
													}
												})
										: []
								}
								components={{ IndicatorSeparator: () => null }}
								isLoading={loadingBooths}
								onMenuScrollToBottom={() => fetchNextBooths()}
								placeholder="Booth number"
								{...field}
							/>
						)}
					/>
					<Error>{(boothErrors as any)?.booth?.message}</Error>
					<Button type="submit">add booth</Button>
				</Form>
			</Field>
			<Field label="Text">
				{/* eslint-disable-next-line */}
				<Form onSubmit={handleTextSubmit(onSubmitText)}>
					<Input type="text" placeholder="add text" {...registerText('textLayerValue')} />
					<Error>{(textErrors as any)?.textLayerValue?.message}</Error>
					<Button type="submit">add text test</Button>
				</Form>
			</Field>
			<Field label="Rectangle">
				{/* eslint-disable-next-line */}
				<Form onSubmit={handleRectSubmit(onSubmitRectangle)}>
					<Input type="text" placeholder="width (px)" {...registerRect('width')} />
					<Error>{(rectErrors as any)?.width?.message}</Error>
					<Input type="text" placeholder="height (px)" {...registerRect('height')} />
					<Error>{(rectErrors as any)?.height?.message}</Error>
					<StyledSketchPicker
						color={rectangleBackgroundColor}
						onChangeComplete={handleRectangleColorChange}
						{...rectangleField}
					/>
					<Button type="submit">add rectangle</Button>
				</Form>
			</Field>
			<Field label="Circle">
				{/* eslint-disable-next-line */}
				<Form onSubmit={handleCircleSubmit(onSubmitCircle)}>
					<Input type="text" placeholder="radius (px)" {...registerCircle('radius')} />
					<Error>{(circleErrors as any)?.width?.message}</Error>
					<StyledSketchPicker
						color={circleBackgroundColor}
						onChangeComplete={handleCircleColorChange}
						{...circleField}
					/>
					<Button type="submit">add circle</Button>
				</Form>
			</Field>
			<Field label="Line">
				{/* eslint-disable-next-line */}
				<Form onSubmit={handleLineSubmit(onSubmitLine)}>
					<Input
						type="text"
						placeholder="points (e.g. 0, 0, 100, 0, 100, 100)"
						{...registerLine('points')}
					/>
					<Error>{(lineErrors as any)?.width?.message}</Error>
					<Button type="submit">add line</Button>
				</Form>
			</Field>
			<Field label="Image">
				{/* eslint-disable-next-line */}
				<Form onSubmit={handleImageSubmit(onSubmitImage)}>
					<Input type="file" {...registerImage('images')} />
					<Button type="submit">add image</Button>
				</Form>
			</Field>
			<Actions>
				<Button onClick={handleSave}>Save</Button>
				<Button onClick={handleLoad}>Load</Button>
			</Actions>
		</StyledSidebar>
	)
}

const Form = styled.form`
	width: 100%;
`

const StyledSidebar = styled.div`
	padding: 0 1rem;
	width: 100%;

	@media (min-width: ${theme`screens.md`}) {
		width: 250px;
	}
`

const Title = styled.h2`
	font-size: 32px;
	font-weight: bold;
`

const Button = styled.button`
	border-radius: 5px;
	background-color: #3c53ff;
	color: #fff;
	padding: 5px 10px;
	width: 100%;
`

const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`

const Input = styled.input`
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px;
	margin: 10px 0;
	max-width: 100%;
	width: 100%;
`

const Error = styled.span`
	color: red;
	font-size: 12px;
	margin-bottom: 10px;
`

const StyledSketchPicker = styled(SketchPicker)`
	max-width: calc(100% - 1rem);
`

const DimentionsForm = tw(Form)``

const DimentionsRow = tw.div`flex flex-col gap-2`

export default EditorSidebar
