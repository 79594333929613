import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Wrapper, Mobile } from './styles'
import type { BoothTypeProps } from './types'

const BoothType = ({ id, name, description, dimensions, openDeleteModal }: BoothTypeProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const ellipsisMenuOptions = [
		{
			label: t('boothTypes.boothType.ellipsisMenu.edit'),
			action: () => navigate(`/booth-types/${id}`)
		},
		{
			label: t('boothTypes.boothType.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<span>
				<Mobile>{t('boothTypes.boothType.name')}: </Mobile>
				<NavLink to={`/booth-types/${id}`}>{name}</NavLink>
			</span>
			<span>
				<Mobile>{t('boothTypes.boothType.description')}: </Mobile>
				{description}
			</span>
			<span>
				<Mobile>{t('boothTypes.boothType.height')}: </Mobile>
				{+dimensions?.height} cm
			</span>
			<span>
				<Mobile>{t('boothTypes.boothType.width')}: </Mobile>
				{+dimensions?.width} cm
			</span>
			<span>
				<Mobile>{t('boothTypes.boothType.depth')}: </Mobile>
				{+dimensions?.depth} cm
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default BoothType
