import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import Input from 'components/Ui/Input'
import { useMutation } from 'react-query'
// import { useAxiosPrivate } from 'hooks'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { useToast } from 'hooks'
import { IResetPasswordReqBody as IResetPasswordTokenReqBody } from './types'
import { ToastContainer } from 'react-toastify'
import { defaultConfig } from 'config/default'
import {
	StyledSection,
	StyledForm,
	LanguageSwitcherContainer,
	Header,
	Title,
	Subtitle,
	Inputs,
	StyledButton,
	StyledImage
} from './styles'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

const PasswordResetTokenPage = () => {
	const { t } = useTranslation()
	// const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const storeCtx = useContext(StoreSettingsContext)

	const passwordValidationSchema = yup.object({
		password: yup.string().required(t('validation.addProduct.name.required')),
		password2: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
	})
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(passwordValidationSchema)
	})
	const searchParams = new URLSearchParams(document.location.search)
	const [isSuccess, setIsSuccess] = useState<boolean>(false)

	// const resetPasswordSuccess = useToast({
	// 	type: 'success',
	// 	message: t('passwordReset.success')
	// })
	const resetPasswordFail = useToast({
		type: 'error',
		message: t('passwordResetToken.error')
	})

	const { mutate: resetPasswordMutation, isLoading: isResetPasswordLoading } = useMutation(
		({ password }: IResetPasswordTokenReqBody) => {
			const body = {
				key: searchParams.get('token'),
				password: password
			}
			// console.log('key', body.key)
			return axios.post('/accounts/reset-password/', body)
		},
		{
			onSuccess: () => {
				setIsSuccess(true)
				// resetPasswordSuccess()
				navigate('/login')
			},
			onError: () => {
				resetPasswordFail()
			}
		}
	)

	const onSubmit = (data: IResetPasswordTokenReqBody) => {
		resetPasswordMutation(data)
	}

	return (
		<StyledSection>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<LanguageSwitcherContainer>
					<LanguageSwitcher />
				</LanguageSwitcherContainer>
				{storeCtx?.storeSettings?.logo && (
					<StyledImage src={storeCtx?.storeSettings?.logo} alt="Logo" />
				)}
				<Header>
					<Title>{t('passwordResetToken.title')}</Title>
					<Subtitle to="/">{t('passwordResetToken.text')}</Subtitle>
				</Header>
				{!isSuccess ? (
					<>
						<Inputs>
							<Input
								id="password"
								label={t('passwordResetToken.password')}
								{...register('password')}
								type={'password'}
								error={(errors as any).password}
							/>
							<Input
								id="password2"
								type={'password'}
								label={t('passwordResetToken.password2')}
								{...register('password2')}
								error={(errors as any).password2}
							/>
						</Inputs>
						<StyledButton
							type="submit"
							text={t('passwordResetToken.submit')}
							icon={faArrowRight}
							isLoading={isResetPasswordLoading}
						/>
					</>
				) : (
					<>{t('passwordResetToken.success')}</>
				)}
			</StyledForm>
			<ToastContainer
				position="top-right"
				autoClose={defaultConfig.redirectTimer}
				rtl={false}
				hideProgressBar
				closeOnClick={false}
			/>
		</StyledSection>
	)
}

export default PasswordResetTokenPage
