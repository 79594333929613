import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

interface IPagination {
	prev: string | null
	next: string | null
	onClick: (data: any) => void
}

const Pagination = ({ prev, next, onClick }: IPagination) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			{prev ? (
				<Button onClick={() => onClick(prev)}>
					<FontAwesomeIcon icon={faChevronLeft} />
					<span>{t('pagination.prev')}</span>
				</Button>
			) : null}{' '}
			{next ? (
				<Button onClick={() => onClick(next)}>
					<span>{t('pagination.next')}</span>
					<FontAwesomeIcon icon={faChevronRight} />
				</Button>
			) : null}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	font-size: 14px;
`

const Button = styled.button`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`

export default Pagination
