import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import PageWrapper from 'components/Ui/PageWrapper'
import HistoryButton from 'components/Ui/Button/History'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import { StyledHeader, HeaderWrapper, Row, Fieldset, Legend } from './styles'
import { useUserRoles } from 'hooks'
import { MobileBackButton } from 'components/Ui/Button/Back'
import Checkbox from 'components/Ui/Checkbox'
import DatePickerInput from 'components/Ui/Datepicker'
import { format } from 'date-fns'
// import { editCouponValidationSchema } from 'schemas/validation'

const CouponPage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const successMessage = useToast({ type: 'success', message: t('coupon.form.success') })
	const failMessage = useToast({ type: 'error', message: t('coupon.form.error') })
	const { isUser } = useUserRoles()

	const queryClient = useQueryClient()
	const editCouponValidationSchema = yup.object({
		code: yup.string().required(t('validation.editCoupon.form.code.required')),
		price_discount_value: yup
			.number()
			.required(t('validation.editCoupon.form.price_discount_value.required'))
			.min(0)
			.when('price_discount_is_percentage', { is: true, then: yup.number().max(100) }),
		price_discount_is_percentage: yup.bool().required(),
		// commission_discount_value: yup.number().required(),
		// commission_discount_is_percentage: yup.bool().,
		// expiration_date: yup.string().required(),
		// all_users: yup.bool().required(),
		is_active: yup.bool().required()
		// max_uses: yup.number().when('max_uses_'),
		// max_uses_is_infinite: yup.bool().required(),
		// max_uses_uses_per_user: yup.number().required()
	})
	const {
		handleSubmit,
		register,
		control,
		setValue,
		setError,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(editCouponValidationSchema)
	})

	useFetchData({
		name: 'fetchCoupon',
		endpoint: `/coupons/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				setValue('code', res.data.code)
				setValue('price_discount_value', res.data.price_discount.value)
				setValue('price_discount_is_percentage', res.data.price_discount.is_percentage)
				// setValue('commission_discount_value', res.data.commission_discount.value)
				// setValue('commission_discount_is_percentage', res.data.commission_discount.is_percentage)
				// if (res.data.allowed_users) {
				// 	setValue('all_users', res.data.allowed_users.all_users)
				// }
				if (res.data.validity) {
					setValue('expiration_date', new Date(res.data.validity.expiration_date))
					setValue('is_active', res.data.validity.is_active)
				}
				// if (res.data.max_uses) {
				// 	setValue('max_uses', res.data.max_uses.max_uses)
				// 	setValue('max_uses_is_infinite', res.data.max_uses_is_infinite)
				// 	setValue('uses_per_user', res.data.max_uses.uses_per_user)
				// }
			}
		}
	})

	const { mutate: editCouponMutation, isLoading: loadingEditCoupon } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/coupons/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCoupons')
				successMessage()

				navigate('/coupons')
			},
			onError: (error) => {
				const data = (error as any).response.data
				for (const property in data) {
					if (property === 'price_discount') {
						setError('price_discount_value', {
							message: data[property].non_field_errors.join(', ')
						})
					} else {
						setError(property, { message: data[property] })
					}
				}
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		console.log(data)
		const formData = {
			code: data.code,
			price_discount: {
				value: data.price_discount_value,
				is_percentage: data.price_discount_is_percentage as boolean
			},
			// commission_discount: {
			// 	value: data.commission_discount_value,
			// 	is_percentage: !!data.commission_discount_is_percentage
			// },
			// allowed_users: {
			// 	all_users: data.all_users
			// },
			validity: {
				expiration_date: data.expiration_date
					? format(data.expiration_date, 'yyyy-MM-dd')
					: format(new Date(), 'yyyy-MM-dd'),
				is_active: !!data.is_active
			}
			// max_uses: {
			// 	max_uses: data.max_uses,
			// 	is_infinite: !!data.max_uses_is_infinite,
			// 	uses_per_user: data.uses_per_user
			// }
		}
		editCouponMutation(formData as any)
	}

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('coupon.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton to="/coupons" />
					<HistoryButton to={`/coupons/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<PageWrapper>
				<Form
					submitText={t('coupon.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					disabled={isUser}
					isLoading={loadingEditCoupon}>
					<Row>
						<Input
							id="code"
							label={t('coupon.form.code')}
							{...register('code')}
							error={(errors as any).code}
							required={true}
						/>
					</Row>
					<Fieldset>
						<Legend>{t('coupon.form.price_discount.title')}</Legend>
						<Row>
							<Input
								id="price_discount_value"
								label={t('coupon.form.price_discount.value')}
								{...register('price_discount_value')}
								type="number"
								step="0.01"
								error={(errors as any).price_discount_value}
								required={true}
							/>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="price_discount_is_percentage"
									{...register('price_discount_is_percentage')}
									label={t('coupon.form.price_discount.is_percentage')}
								/>
							</div>
						</Row>
					</Fieldset>
					{/* <Fieldset>
						<Legend>{t('coupon.form.commission_discount.title')}</Legend>
						<Row>
							<Input
								id="commission_discount_value"
								label={t('coupon.form.commission_discount.value')}
								{...register('commission_discount_value')}
								type="number"
								step="0.01"
								error={(errors as any).commission_discount_value}
							/>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="commission_discount_is_percentage"
									{...register('commission_discount_is_percentage')}
									label={t('coupon.form.commission_discount.is_percentage')}
								/>
							</div>
						</Row>
					</Fieldset> */}
					{/* <Fieldset>
						<Legend>{t('coupon.form.allowed_users.title')}</Legend>
						<Row>
							<Checkbox
								id="all_users"
								{...register('all_users')}
								label={t('coupon.form.allowed_users.all_users')}
							/>
						</Row>
					</Fieldset> */}
					<Fieldset>
						<Legend>{t('coupon.form.validity.title')}</Legend>
						<Row>
							<Controller
								control={control}
								name="expiration_date"
								render={({ field }) => (
									<DatePickerInput
										label={t('coupon.form.validity.expiration_date')}
										onChange={field.onChange}
										selected={field.value}
										minDate={new Date()}
									/>
								)}
							/>
							<span>{(errors as any).expiration_date}</span>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="is_active"
									{...register('is_active')}
									label={t('coupon.form.validity.is_active')}
								/>
							</div>
						</Row>
					</Fieldset>

					{/* <Fieldset>
						<Legend>{t('coupon.form.max_uses.title')}</Legend>
						<Row>
							<Input
								id="max_uses"
								label={t('coupon.form.max_uses.max_uses')}
								{...register('max_uses')}
								type="number"
								error={(errors as any).max_uses}
							/>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Checkbox
									id="is_infinite"
									{...register('is_infinite')}
									label={t('coupon.form.max_uses.is_infinite')}
								/>
							</div>
							<Input
								id="uses_per_user"
								label={t('coupon.form.max_uses.uses_per_user')}
								{...register('uses_per_user')}
								type="number"
								error={(errors as any).uses_per_user}
							/>
						</Row>
					</Fieldset> */}
				</Form>
			</PageWrapper>
		</>
	)
}

export default CouponPage
