import { useTranslation } from 'react-i18next'
import { LanguageIcon } from '@heroicons/react/24/outline'

import { Wrapper, IconContainer } from './styles'

import type { ILanguageSwitcher } from './types'

const LanguageSwitcher = ({ className, icon = false }: ILanguageSwitcher) => {
	const { i18n } = useTranslation()
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	return (
		<Wrapper className={className}>
			{icon && (
				<IconContainer>
					<LanguageIcon />
				</IconContainer>
			)}
			<button type="button" onClick={() => changeLanguage('en')}>
				EN
			</button>
			/
			<button type="button" onClick={() => changeLanguage('is')}>
				IS
			</button>
			/
			<button type="button" onClick={() => changeLanguage('dk')}>
				DK
			</button>
		</Wrapper>
	)
}

export default LanguageSwitcher
