import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Wrapper, Mobile } from './styles'
import type { BoothProps } from './types'

const Booth = ({ booth_type_name, number, id, openDeleteModal }: BoothProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/booths/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('booth.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('booth.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<NavLink to={`/booths/${id}`}>
				<Mobile>{t('booth.type')}: </Mobile>
				{booth_type_name}
			</NavLink>
			<span>
				<Mobile>{t('booth.number')}: </Mobile>
				{number}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Booth
