import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import { Currency } from 'components/Ui'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { RentalProps } from './types'
import { Wrapper, Mobile } from './styles'

const Rental = ({
	id,
	duration,
	commission,
	price,
	booth_type_name,
	openDeleteModal
}: RentalProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const ellipsisMenuOptions = [
		{
			label: t('rentals.rental.ellipsisMenu.edit'),
			action: () => navigate(`/rentals/${id}`)
		},
		{
			label: t('rentals.rental.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<span>
				{booth_type_name?.name ? (
					<>
						<Mobile>{t('rentals.rental.rental')}</Mobile>{' '}
						<NavLink to={`/rentals/${id}`}>
							{booth_type_name?.name ? `${booth_type_name?.name} - ` : ''} {duration}
						</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{booth_type_name?.name ? (
					<>
						<Mobile>{t('rentals.rental.boothType')}</Mobile> {booth_type_name?.name}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{duration ? (
					<>
						<Mobile>{t('rentals.rental.duration')}</Mobile> {duration}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{commission ? (
					<>
						<Mobile>{t('rentals.rental.commission')}</Mobile> {commission}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{price ? (
					<>
						<Mobile>{t('rentals.rental.price')}</Mobile> <Currency price={price} />
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Rental
