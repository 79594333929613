import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Currency } from 'components/Ui'
import { InfoHeader, SalesTableWrapper } from '../styles'
import { customTableStyles } from 'styles/general'
import { format, parseISO } from 'date-fns'
import { InfoHeaderContainer, InfoHeaderLink } from 'pages/booking'
// import {
// 	Header as TableHeader,
// 	List as TableList,
// 	Container as TableContainer
// } from 'components/Ui/Table'

export interface ProductSale {
	id: string
	created: string
	receipt_reference: string
	product_name: string
}

export interface ProductSalesTableProps {
	sales: ProductSale[]
	product_id: number
}

export const ProductSalesTable = ({ sales, product_id }: ProductSalesTableProps) => {
	const { t } = useTranslation()

	if (sales.length == 0) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Sales</InfoHeader>
				<div>No sales yet</div>
			</>
		)
	}

	const tableHeaders = [
		{
			id: 'sales-created',
			label: 'Created'
		},
		{
			id: 'sales-receipt-reference',
			label: 'Receipt reference'
		},
		{
			id: 'sales-product-name',
			label: 'Name'
		},
		{
			id: 'sales-product-sku',
			label: 'SKU'
		},
		{
			id: 'sales-unit-price',
			label: 'Unit price'
		},
		{
			id: 'sales-quantity',
			label: 'Qty'
		},
		{
			id: 'sales-discount',
			label: 'Discount'
		},
		{
			id: 'sales-subtotal',
			label: 'Subtotal'
		},
		{
			id: 'sales-commission-percentage',
			label: 'Commissoin %'
		},
		{
			id: 'sales-created',
			label: 'Commission'
		}
	]

	const columns: Column[] = [
		{
			label: t('sales.tableHeaders.date'),
			renderCell: (item: any) => (
				<NavLink to={`/sales/${item.id}`}>{format(parseISO(item.created), 'yy-MM-dd')}</NavLink>
			)
		},
		{
			label: t('sales.tableHeaders.reference'),
			renderCell: (item: any) => <>{item.receipt_reference}</>
		},
		// {
		// 	label: 'Name',
		// 	renderCell: (item: any) => <>{item.product_name}</>
		// },
		// {
		// 	label: 'SKU',
		// 	renderCell: (item: any) => <>{item.product_sku}</>
		// },
		{
			label: t('sales.tableHeaders.price'),
			renderCell: (item: any) => <Currency price={item.product_unit_price}></Currency>
		},
		{
			label: t('sales.tableHeaders.quantity'),
			renderCell: (item: any) => <>{item.quantity}</>
		},
		{
			label: t('sales.tableHeaders.discount'),
			renderCell: (item: any) => <Currency price={item.discount}></Currency>
		},
		{
			label: 'Subtotal',
			renderCell: (item: any) => <Currency price={item.subtotal}></Currency>
		},
		{
			label: t('sales.tableHeaders.commission'),
			renderCell: (item: any) => <div>{item.commission_percentage}%</div>
		},
		{
			label: t('sales.tableHeaders.commissionTotal'),
			renderCell: (item: any) => <Currency price={item.commission_amount}></Currency>
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 65px repeat(${columns.length - 1}, 1fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Sales</InfoHeader>
				{!!sales.length && (
					<InfoHeaderLink to={'/sales?product_id=' + product_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				<CompactTable
					columns={columns}
					data={{ nodes: sales }}
					theme={theme}
					layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
			</SalesTableWrapper>
		</>
	)
}
