import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Wrapper, Mobile } from './styles'

import type { ICategory } from './types'

const Category = ({ id, name, products_count, openDeleteModal }: ICategory) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/categories/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('categories.category.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('categories.category.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<span>
				{name ? (
					<>
						<Mobile>{t('categories.category.name')}</Mobile>{' '}
						<NavLink to={`/categories/${id}`}>{name}</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{products_count ? (
					<>
						<Mobile>{t('categories.category.products_count')}</Mobile> {products_count}
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Category
