import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Column } from '@table-library/react-table-library/compact'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useFetchData } from 'hooks'
import User from 'components/User'
import { usePagination } from 'hooks'
import { useModal } from 'hooks'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import { useAxiosPrivate } from 'hooks'
import EmptyTable from 'components/Ui/Table/Empty'
import Filters from 'components/Filters'
import { IActionFilter } from 'interfaces/filters.interface'
import CtaLink from 'components/Ui/CtaLink'
import { useSearch } from 'hooks'
import ConfirmModal from 'components/Modals/confirm'
import { useFilter } from 'hooks'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import { useToast } from 'hooks'
import FormatModal from 'components/Modals/format'
import { IOption } from 'interfaces/select.interface'
import {
	StyledFiltersWrapper,
	FiltersContainer,
	StyledSearchInput,
	StyledTable,
	MobileCtaContainer,
	FiltersModalContent,
	ModalFilters,
	DesktopCtaContainer,
	HeaderContainer,
	StyledHeader,
	StyledTable2
} from './styles'
import MobileFilters from 'components/Filters/mobile'
import FiltersModal from 'components/Modals/filters'
import { customTableStyles, TableWrapper } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import Status from 'components/Ui/Status'
import { EllipsisText } from 'components/Ui'
import { useTableSelect } from 'hooks'
import { AuthContext } from 'store/AuthContext'
import { theme } from 'twin.macro'

const UsersPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const [checkedUsers, setCheckedUsers] = useState<number[]>([])
	const { handlePagination } = usePagination(setQuery)
	const axiosPrivate = useAxiosPrivate()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { handleFilter } = useFilter(setQuery)
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const queryClient = useQueryClient()
	const { isMobileOrTablet } = useDevice()
	const [usersData, setUsersData] = useState({ nodes: [] })
	const { select } = useTableSelect(usersData, onSelectChange)
	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isBulkPayoutModalOpen,
		openModal: openBulkPayoutModal,
		closeModal: closeBulkPayoutModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()
	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()
	const {
		isOpen: isPayoutModalOpen,
		openModal: openPayoutModalOpen,
		closeModal: closePayoutModal
	} = useModal()
	const {
		isOpen: isPayoutReminderEmailOpen,
		openModal: openPayoutReminderEmailModal,
		closeModal: closePayoutReminderEmailModal
	} = useModal()
	const {
		isOpen: isBulkPayoutReminderEmailOpen,
		openModal: openBulkPayoutReminderEmailModal,
		closeModal: closeBulkPayoutReminderEmailModal
	} = useModal()

	const { data: users, isFetching: loadingUsers } = useFetchData({
		name: 'fetchUsers',
		endpoint: '/accounts/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setUsersData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const exportSuccessMsg = useToast({
		type: 'success',
		message: t('users.modal.export.success')
	})
	const exportFailMsg = useToast({
		type: 'error',
		message: t('users.modal.export.error')
	})

	const requestPayoutSuccessMsg = useToast({
		type: 'success',
		message: t('users.modal.payout.success')
	})
	const requestPayoutFailMsg = useToast({
		type: 'error',
		message: t('users.modal.payout.error')
	})

	const singleDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('users.modal.delete.success')
	})
	const singleDeleteFailMsg = useToast({
		type: 'error',
		message: t('users.modal.delete.error')
	})
	const payoutReminderEmailSuccess = useToast({
		type: 'success',
		message: t('users.modal.payoutRemindermail.success')
	})
	const payoutReminderEmailFail = useToast({
		type: 'error',
		message: t('users.modal.payoutRemindermail.error')
	})

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const { mutate: deleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/accounts/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				singleDeleteSuccessMsg()
				queryClient.invalidateQueries('fetchUsers')
				setIdToBeRemoved(null)
			},
			onError: () => {
				singleDeleteFailMsg()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const { mutate: requestBulkPayoutMutation } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/prepare_payout/', { ids: checkedUsers })
		},
		{
			onSuccess: () => {
				requestPayoutSuccessMsg()
				queryClient.invalidateQueries('fetchUsers')
			},
			onError: () => {
				requestPayoutFailMsg()
			},
			onSettled: () => {
				setCheckedUsers([])
				closeBulkPayoutModal()
			}
		}
	)

	const { mutate: requestPayoutMutation } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/prepare_payout/', { ids: checkedUsers })
		},
		{
			onSuccess: () => {
				requestPayoutSuccessMsg()
				queryClient.invalidateQueries('fetchUsers')
			},
			onError: () => {
				requestPayoutFailMsg()
			},
			onSettled: () => {
				setCheckedUsers([])
				closePayoutModal()
			}
		}
	)

	const { mutate: payoutReminderEmailBulkMutation } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/send_payout_method_reminder_email/', {
				ids: checkedUsers
			})
		},
		{
			onSuccess: () => {
				payoutReminderEmailSuccess()
			},
			onError: () => {
				payoutReminderEmailFail()
			},
			onSettled: () => {
				closeBulkPayoutReminderEmailModal()
			}
		}
	)

	const { mutate: payoutReminderEmailMutation } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/send_payout_reminder_email/', { ids: checkedUsers })
		},
		{
			onSuccess: () => {
				payoutReminderEmailSuccess()
			},
			onError: () => {
				payoutReminderEmailFail()
			},
			onSettled: () => {
				closePayoutReminderEmailModal()
			}
		}
	)

	const handleEmailPayoutReminder = (id: number) => {
		setCheckedUsers([id])
		openPayoutReminderEmailModal()
	}

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'delete':
				openDeleteModal()
				break
			case 'payout':
				openBulkPayoutModal()
				break
			case 'emailPayoutReminder':
				openBulkPayoutReminderEmailModal()
				break
			case 'export':
				openExportModal()
				break
		}
	}

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const { mutate: exportMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/export/', {
				format: chosenExportFormat,
				ids: checkedUsers
			})
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'users-export', format })
				}

				setCheckedUsers([])
				exportSuccessMsg()
			},
			onError: () => {
				exportFailMsg()
			},
			onSettled: () => {
				closeFormatModal()
				setChosenExportFormat(null)
			}
		}
	)

	const handlePayoutRequest = (id: number) => {
		setCheckedUsers([id])
		openPayoutModalOpen()
	}

	const handleSinglePayoutRequest = (id: number) => {
		handlePayoutRequest(id)
	}

	const filters = [
		{
			id: 'type',
			label: t('users.filters.type.label'),
			placeholder: t('users.filters.type.placeholder'),
			options: [
				{
					value: 'active',
					label: t('users.filters.type.options.active')
				},
				{
					value: 'administrator',
					label: t('users.filters.type.options.administrator')
				},
				{
					value: 'superuser',
					label: t('users.filters.type.options.superuser')
				}
			]
		},
		{
			id: 'payout_requested',
			label: t('users.filters.payoutStatus.label'),
			placeholder: t('users.filters.payoutStatus.placeholder'),
			options: [
				{
					value: 'true',
					label: t('users.filters.payoutStatus.options.true')
				},
				{
					value: 'false',
					label: t('users.filters.payoutStatus.options.false')
				}
			]
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('users.actions.label'),
			placeholder: t('users.actions.placeholder'),
			options: [
				{
					value: 'payout',
					label: t('users.actions.options.payout'),
					isDisabled: checkedUsers.length == 0
				},
				{
					value: 'emailPayoutReminder',
					label: t('users.actions.options.payoutReminder'),
					isDisabled: checkedUsers.length == 0
				},
				{
					value: 'export',
					label: t('users.actions.options.export')
				}
			]
		}
	]

	const tableHeaders = [
		{
			id: 'email',
			label: t('users.tableHeaders.email')
		},
		{
			id: 'name',
			label: t('users.tableHeaders.name')
		},
		{
			id: 'phone',
			label: t('users.tableHeaders.phone')
		},
		{
			id: 'ssn',
			label: t('users.tableHeaders.ssn')
		},
		{
			id: 'address',
			label: t('users.tableHeaders.address')
		},
		{
			id: 'banking',
			label: t('users.tableHeaders.banking')
		},
		{
			id: 'bookings',
			label: t('users.tableHeaders.bookings')
		},
		{
			id: 'payout',
			label: t('users.tableHeaders.payout')
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/users/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('users.user.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('users.user.ellipsisMenu.payout'),
			action: handleSinglePayoutRequest
		},
		{
			label: t('users.user.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('users.tableHeaders.email'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/users/${item.id}`}>
					{item.email}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('users.tableHeaders.name'),
			renderCell: (item: any) => item.name
		},
		{
			label: t('users.tableHeaders.phone'),
			renderCell: (item: any) =>
				item.phone_number && <span>{formatPhoneNumberIntl(item.phone_number)}</span>
		},
		{
			label: t('users.tableHeaders.ssn'),
			renderCell: (item: any) => item.ssn
		},
		{
			label: t('users.tableHeaders.address'),
			renderCell: (item: any) => (
				<div>
					<div>{item?.address?.address}</div>
					<div>
						{item?.address?.postal_code} - {item?.address?.city}
					</div>
				</div>
			)
		},
		{
			label: t('users.tableHeaders.banking'),
			renderCell: (item: any) =>
				item.default_payout_method[item.default_payout_method.kind].account && (
					<div>
						<span>{item.default_payout_method[item.default_payout_method.kind].branch}</span>
						<span>-</span>
						<span>{item.default_payout_method[item.default_payout_method.kind].ledger}</span>
						<span>-</span>
						<span>{item.default_payout_method[item.default_payout_method.kind].account}</span>
					</div>
				)
		},
		{
			label: t('users.tableHeaders.bookings'),
			renderCell: (item: any) => {
				return (
					<EllipsisText>
						{item.current_bookings != null &&
							Array.isArray(item.current_bookings) &&
							item.current_bookings.map((booking: any) => (
								<>
									<div key={booking.id}>
										#{booking?.ref_nr} ({booking.start_date}){', '}
									</div>
								</>
							))}
					</EllipsisText>
				)
			}
		},
		{
			label: t('users.tableHeaders.payout'),
			renderCell: (item: any) => <Status active={item.has_payout_request} />
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu options={contextMenuOptions} dataId={item.id} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedUsers(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px 2fr repeat(7, 1fr) 40px;
	`
	}

	return (
		<>
			<HeaderContainer>
				<StyledHeader title={t('users.title')} handleSearch={handleSearch} />
				<DesktopCtaContainer>
					<CtaLink to="/users/add" text={t('users.cta')} />
				</DesktopCtaContainer>
			</HeaderContainer>
			<StyledFiltersWrapper>
				<FiltersContainer>
					<StyledSearchInput
						label={t('users.filters.search.label')}
						placeholder={t('users.filters.search.placeholder')}
						onChange={handleSearch}
					/>
					<Filters filters={filters} handleFilter={handleFilter} />
					<Filters
						filters={actionFilters}
						disableActions={false}
						handleFilter={handleActionFilter}
					/>
				</FiltersContainer>
				<MobileCtaContainer>
					<CtaLink to="/users/add" text={t('users.cta')} />
				</MobileCtaContainer>
				<MobileFilters openFilters={openFiltersModal} />
			</StyledFiltersWrapper>
			{usersData.nodes != null && Array.isArray(usersData.nodes) && usersData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={loadingUsers}
							prev={users?.data?.previous}
							next={users?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{users?.data?.results.map((user: any) => (
								<User
									key={user.id}
									{...user}
									openDeleteModal={handleDelete}
									handleEmailPayoutReminder={handleEmailPayoutReminder}
									handlePayoutRequest={handlePayoutRequest}
								/>
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={usersData}
							isLoading={loadingUsers}
							select={select}
							prev={users?.data?.previous}
							next={users?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('users.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={deleteMutation}>
							<p>{t('users.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isExportModalOpen && (
						<ConfirmModal
							title={t('users.modal.export.title')}
							isOpened={isExportModalOpen}
							closeModal={closeExportModal}
							confirm={handleExportModal}>
							<p>{t('users.modal.export.text')}</p>
						</ConfirmModal>
					)}
					{isBulkPayoutReminderEmailOpen && (
						<ConfirmModal
							title={t('users.modal.bulkPayoutReminderEmail.title')}
							isOpened={isBulkPayoutReminderEmailOpen}
							closeModal={closeBulkPayoutReminderEmailModal}
							confirm={payoutReminderEmailBulkMutation}>
							<p>{t('users.modal.bulkPayoutReminderEmail.text')}</p>
						</ConfirmModal>
					)}
					{isPayoutReminderEmailOpen && (
						<ConfirmModal
							title={t('users.modal.payoutReminderEmail.title')}
							isOpened={isPayoutReminderEmailOpen}
							closeModal={closePayoutReminderEmailModal}
							confirm={payoutReminderEmailMutation}>
							<p>{t('users.modal.welcomeEmail.text')}</p>
						</ConfirmModal>
					)}
					{isBulkPayoutModalOpen && (
						<ConfirmModal
							title={t('users.modal.bulkPayout.title')}
							isOpened={isBulkPayoutModalOpen}
							closeModal={closeBulkPayoutModal}
							confirm={requestBulkPayoutMutation}>
							<p>{t('users.modal.bulkPayout.text', { count: checkedUsers.length })}</p>
						</ConfirmModal>
					)}
					{isPayoutModalOpen && (
						<ConfirmModal
							title={t('users.modal.payout.title')}
							isOpened={isPayoutModalOpen}
							closeModal={closePayoutModal}
							confirm={requestPayoutMutation}>
							<p>{t('users.modal.payout.text')}</p>
						</ConfirmModal>
					)}
					{isFormatModalOpen && (
						<FormatModal
							disableButton={chosenExportFormat === null}
							isOpened={isFormatModalOpen}
							closeModal={closeFormatModal}
							confirm={exportMutation}
							isLoading={isExportLoading}
							onChange={handleFormatChange}
						/>
					)}
					{isFiltersOpen && (
						<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
							<FiltersModalContent>
								<ModalFilters filters={filters} handleFilter={handleFilter} />
							</FiltersModalContent>
						</FiltersModal>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={loadingUsers} />
			)}
		</>
	)
}

export default UsersPage
