import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { components } from 'react-select'

interface IControl {
	children?: ReactNode
	// onClick: () => void
	// onBlur: () => void
}

const Control = ({ children, ...rest }: IControl) => {
	const { t } = useTranslation()
	const Component = components.Control as any

	return (
		<Component {...rest}>
			{children}
			<StyledButton>
				{t('sortBy.text')}{' '}
				<StyledIcon>
					<BarsArrowUpIcon />
				</StyledIcon>
			</StyledButton>
		</Component>
	)
}

const StyledIcon = styled.div`
	width: 1.125rem;
	color: ${theme`colors.grayChateau`};
`

const StyledButton = styled.button`
	display: flex;
	padding: 9px 14px;
	font-size: 0.875rem;
	align-items: center;
	gap: 12px;
	font-weight: 600;

	&:focus {
		outline: none;
	}
`

export default Control
