import { useTranslation } from 'react-i18next'

import { StyledButton } from './styles'

interface IHistoryButton {
	to: string
}

const HistoryButton = ({ to }: IHistoryButton) => {
	const { t } = useTranslation()

	return <StyledButton to={to}>{t('historyBtn')}</StyledButton>
}

export default HistoryButton
