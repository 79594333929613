import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
// import { useMutation } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { Currency } from 'components/Ui'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import AdvancedStatus from 'components/Status/AdvancedStatus'
import {
	Wrapper,
	Reference,
	Created,
	Mobile,
	Name,
	Email,
	SSN,
	BankInfo,
	Amount,
	StatusWrapper
} from 'components/Payout/styles'
import { EllipsisMenuItem } from 'interfaces/ellipsisMenu'
// import { downloadAs } from 'utils/downloadFile'
// import { useAxiosPrivate } from 'hooks'
// import { useToast } from 'hooks'
import { IPayout } from './types'
import { useAxiosPrivate } from 'hooks'

const Payout = ({
	id,
	reference,
	created,
	amount,
	status,
	account,
	payout_method,
	isIceland
}: IPayout) => {
	const { t } = useTranslation()
	// const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const axiosPrivate = useAxiosPrivate()
	// const payoutPdfSuccessMsg = useToast({
	// 	type: 'success',
	// 	message: t('payout.pdf.success')
	// })
	// const payoutPdfErrorMsg = useToast({
	// 	type: 'error',
	// 	message: t('payout.pdf.error')
	// })

	const handlePrint = (data: any) => {
		// const id = data.target.closest('[data-id]').getAttribute('data-id')
		const url = axios.defaults.baseURL!
		const domain = new URL(url)
		const { origin } = domain

		axiosPrivate.get(`/payouts/${id}/html/`).then((response) => {
			const invoiceURL = URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
			window.open(invoiceURL)
		})
	}

	// const { mutate: refetchPdfExport } = useMutation(
	// 	() => {
	// 		return axiosPrivate.get(`/payouts/${id}/pdf/`)
	// 	},
	// 	{
	// 		onSuccess: (res: any) => {
	// 			downloadAs({ file: res.data, name: 'payout', format: 'pdf' })
	// 			payoutPdfSuccessMsg()
	// 		},
	// 		onError: () => {
	// 			payoutPdfErrorMsg()
	// 		}
	// 	}
	// )

	const ellipsisMenuOptions: EllipsisMenuItem[] = [
		{
			label: t('payouts.ellipsisMenu.edit'),
			action: () => navigate(`/payouts/${id}`)
		},
		{
			label: t('payouts.ellipsisMenu.print'),
			action: handlePrint
		}
		// {
		// 	label: t('payouts.ellipsisMenu.pdf'),
		// 	action: refetchPdfExport
		// }
	]

	return (
		<Wrapper isIceland={isIceland}>
			<Reference to={`/payouts/${id}`}>{reference}</Reference>
			<StatusWrapper>
				<AdvancedStatus status={status} />
			</StatusWrapper>
			<Created>
				<Mobile>Created: </Mobile>
				{format(parseISO(created), 'MMM d, yyyy, hh:mm aaa')}
			</Created>
			<Name>
				<Mobile>Name: </Mobile>
				{account.name}
			</Name>
			<Email>
				<Mobile>Email: </Mobile>
				{account.email}
			</Email>
			{isIceland && (
				<SSN>
					<Mobile>SSN: </Mobile>
					{account.ssn}
				</SSN>
			)}
			<BankInfo>
				<Mobile>Bank info: </Mobile>
				<div>
					<span>{payout_method[payout_method.kind].branch}</span>
					<span>-</span>
					<span>{payout_method[payout_method.kind].ledger}</span>
					<span>-</span>
					<span>{payout_method[payout_method.kind].account}</span>
				</div>
			</BankInfo>
			<Amount>
				<Currency price={amount} />
			</Amount>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Payout
