import { useTranslation } from 'react-i18next'

import CtaLink from 'components/Ui/CtaLink'

import type { BackButtonProps } from './types'

export const BackButton = ({ to, className }: BackButtonProps) => {
	const { t } = useTranslation()

	return <CtaLink to={to} text={t('backBtn')} icon={false} className={className} />
}
