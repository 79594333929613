import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { StyledTable, StyledItem, Mobile } from './styles'
import { IHistoryTable } from './types'
import EmptyTable from 'components/Ui/Table/Empty'

const HistoryTable = ({ results, isLoading }: IHistoryTable) => {
	const { t } = useTranslation()

	const tableHeaders = [
		{
			id: 'date',
			label: t('historyTable.date')
		},
		{
			id: 'user',
			label: t('historyTable.user')
		},
		{
			id: 'action',
			label: t('historyTable.action')
		}
	]

	return results != null && Array.isArray(results) && results.length ? (
		<StyledTable isLoading={isLoading} headers={tableHeaders}>
			{results?.map((item) => (
				<StyledItem key={item.id}>
					<span>
						<Mobile>{t('historyTable.date')}: </Mobile>
						{format(parseISO(item.action_time), 'yyyy-MM-dd, hh:mm aa')}
					</span>
					<span>
						<Mobile>{t('historyTable.user')}: </Mobile>
						{item.user?.email}
					</span>
					<div>
						<Mobile>{t('historyTable.action')}: </Mobile>
						{item.change_message &&
							JSON.parse(item.change_message).map((msg: any, index: number) => {
								if (msg.added) {
									return (
										<span key={index}>
											{t('historyTable.added')} {msg.added?.name}{' '}
											{msg.added?.object ? `"${msg.added?.object}"` : ''}
										</span>
									)
								}

								if (msg.changed) {
									return (
										<span key={index}>
											{t('historyTable.changed')} {msg.changed?.name} &quot;{msg.changed?.fields}
											&quot; - {msg.changed?.object}
										</span>
									)
								}
							})}
					</div>
				</StyledItem>
			))}
		</StyledTable>
	) : (
		<EmptyTable />
	)
}

export default HistoryTable
