import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Currency } from 'components/Ui'
import { InfoHeader, SalesTableWrapper } from './styles'
import { customTableStyles } from 'styles/general'
import { ImageElement, InfoDetails, Name } from 'components/Product'
import { Sku, StyledTableImage } from 'pages/products/styles'
import { useFetchData } from 'hooks'
import { Spinner } from 'components/Ui/Spinner'
import { InfoHeaderContainer, InfoHeaderLink } from 'pages/booking'
// import {
// 	Header as TableHeader,
// 	List as TableList,
// 	Container as TableContainer
// } from 'components/Ui/Table'

// export interface BookingProduct {
// 	id: string
// 	created: string
// 	receipt_reference: string
// 	product_name: string
// }

export interface UserProductsTableProps {
	owner_id?: string
}

export const UserProductsTable = ({ owner_id: user_id }: UserProductsTableProps) => {
	const { t } = useTranslation()
	const [productsData, setProductsData] = useState({ nodes: [] })

	const tableHeaders = [
		{
			id: 'sales-created',
			label: 'Created'
		},
		{
			id: 'sales-receipt-reference',
			label: 'Receipt reference'
		},
		{
			id: 'sales-product-name',
			label: 'Name'
		},
		{
			id: 'sales-product-sku',
			label: 'SKU'
		},
		{
			id: 'sales-unit-price',
			label: 'Unit price'
		},
		{
			id: 'sales-quantity',
			label: 'Qty'
		},
		{
			id: 'sales-discount',
			label: 'Discount'
		},
		{
			id: 'sales-subtotal',
			label: 'Subtotal'
		},
		{
			id: 'sales-commission-percentage',
			label: 'Commissoin %'
		},
		{
			id: 'sales-created',
			label: 'Commission'
		}
	]
	const { data: products, isFetching: productsLoading } = useFetchData({
		name: 'fetchProducts',
		query: `owner_id=${user_id}`,
		endpoint: '/products/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setProductsData({ nodes: results })
			}
		}
	})

	if (!productsData?.nodes?.length) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Products</InfoHeader>
				<div>No products yet</div>
			</>
		)
	}

	const columns: Column[] = [
		{
			label: t('products.tableHeaders.product'),
			renderCell: (item: any) => (
				<ImageElement>
					<StyledTableImage src={item.image_thumbnail} />
					<InfoDetails>
						<Name to={`/products/${item.id}`}>{item.name}</Name>
						<Sku>{item.sku}</Sku>
					</InfoDetails>
				</ImageElement>
			),

			select: true,
			pinLeft: true
		},
		{
			label: t('products.tableHeaders.category'),
			renderCell: (item: any) => (item.category?.id ? item.category.name : '')
		},
		{
			label: t('products.tableHeaders.price'),
			renderCell: (item: any) => <Currency price={item.price} />
		},
		{
			label: t('products.tableHeaders.quantity'),
			renderCell: (item: any) => item.quantity
		},

		{
			label: t('products.tableHeaders.sold'),
			renderCell: (item: any) => item.sum_sold_quantity
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 165px repeat(${columns.length - 1}, 1fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Products</InfoHeader>
				{!!productsData?.nodes?.length && (
					<InfoHeaderLink to={'/products?owner_id=' + user_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				{productsLoading && <Spinner></Spinner>}
				{productsData.nodes != null &&
				Array.isArray(productsData?.nodes) &&
				productsData?.nodes.length ? (
					<CompactTable
						columns={columns}
						data={productsData}
						theme={theme}
						isLoading={productsLoading}
						layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
				) : (
					<></>
				)}
			</SalesTableWrapper>
		</>
	)
}
