import { Routes, Route } from 'react-router-dom'
import './i18n'

import GlobalStylesProvider from 'styles/globalStyles'
import PersistLogin from 'components/PersistLogin'
import DashboardLayout from 'layouts/dashboard'
// import PreventStaff from 'components/PreventStaff'

import LoginPage from 'pages/login'
import Dashboard from 'pages/dashboard'
import PasswordReset from 'pages/passwordReset'

import Profile from 'pages/profile'
import ChangeProfilePassword from 'pages/changeProfilePassword'

import Products from 'pages/products'
import ProductPage from 'pages/product'
import ProductAdd from 'pages/products/add'
import ProductHistory from 'pages/products/history'

import Sales from 'pages/sales'
import Sale from 'pages/sale'
import SaleHistory from 'pages/sales/history'

import Bookings from 'pages/bookings'
import Booking from 'pages/booking'
import BookingHistory from 'pages/bookings/history'
import BookingCalendar from 'pages/bookings/calendar'
import BookingAdd from 'pages/bookings/add'

import Payouts from 'pages/payouts'
import Payout from 'pages/payout'
import PayoutHistory from 'pages/payouts/history'

import Store from 'pages/settings'

import Tags from 'pages/tags'
import TagAdd from 'pages/tags/add'
import Tag from 'pages/tag'

import OpeningHours from 'pages/openingHours'
import OpeningHour from 'pages/openingHour'

import Categories from 'pages/categories'
import CategoryAdd from 'pages/categories/add'
import CategoryPage from 'pages/category'
import CategoryHistory from 'pages/categories/history'

import Coupons from 'pages/coupons'
import CouponAdd from 'pages/coupons/add'
import CouponPage from 'pages/coupon'
import CouponHistory from 'pages/coupons/history'

import Booths from 'pages/booths'
import Booth from 'pages/booth'
import BoothAdd from 'pages/booths/add'
import BoothHistory from 'pages/booths/history'

import BoothTypes from 'pages/boothTypes'
import BoothType from 'pages/boothType'
import BoothTypeAdd from 'pages/boothTypes/add'
import BoothTypeHistory from 'pages/boothTypes/history'

import Rentals from 'pages/rentals'
import RentalAdd from 'pages/rentals/add'
import Rental from 'pages/rental'
import RentalHistory from 'pages/rentals/history'

import Users from 'pages/users'
import UserAdd from 'pages/users/add'
import User from 'pages/user'
import ChangePassword from 'pages/changePassword'
import UserHistory from 'pages/users/history'

import ErrorPage from 'pages/404'

import FloorMap from 'pages/floorMap'
import TagHistory from 'pages/tags/history'
import PreventUser from 'components/PreventUser'
import { useTranslation } from 'react-i18next'
import useFetchStoreData from 'hooks/useFetchStoreData'
import { useContext } from 'react'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import PasswordResetToken from 'pages/passwordResetToken'

import { TStoreRes } from 'types/store'

function App() {
	const { i18n } = useTranslation()
	const storeCtx = useContext(StoreSettingsContext)

	useFetchStoreData({
		name: 'fetchStoreData',
		endpoint: '/store/',
		options: {
			onSuccess: async (res: TStoreRes) => {
				storeCtx?.setStoreSettings(res?.data)
				storeCtx?.setPrimaryColor(res?.data.primary_color)
				i18n.changeLanguage(res?.data?.default_language ? res.data.default_language : 'en')
			}
		}
	})

	return (
		<>
			<GlobalStylesProvider />
			<Routes>
				{/* public routes */}
				<Route path="/login" element={<LoginPage />} />
				<Route path="/password-reset" element={<PasswordReset />} />
				<Route path="/password-reset-continue" element={<PasswordResetToken />} />

				{/* private routes */}
				<Route element={<PersistLogin />}>
					<Route element={<DashboardLayout />}>
						<Route path="/" element={<Dashboard />} />
						<Route path="/products" element={<Products />} />
						<Route path="/products/add" element={<ProductAdd />} />
						<Route path="/products/:id/history" element={<ProductHistory />} />
						<Route path="/products/:id" element={<ProductPage />} />

						<Route path="/sales" element={<Sales />} />
						<Route path="/sales/:id/history" element={<SaleHistory />} />
						<Route path="/sales/:id" element={<Sale />} />

						<Route path="/bookings" element={<Bookings />} />
						<Route path="/bookings/calendar" element={<BookingCalendar />} />
						<Route path="/bookings/add" element={<BookingAdd />} />
						<Route path="/bookings/:id" element={<Booking />} />
						<Route path="/bookings/:id/history" element={<BookingHistory />} />

						<Route path="/payouts" element={<Payouts />} />
						<Route path="/payouts/:id" element={<Payout />} />
						<Route path="/payouts/:id/history" element={<PayoutHistory />} />

						<Route path="/profile" element={<Profile />} />
						<Route path="/profile/password-change" element={<ChangeProfilePassword />} />

						<Route element={<PreventUser />}>
							<Route path="/booths/add" element={<BoothAdd />} />

							<Route path="/floor-map" element={<FloorMap />} />

							<Route path="/categories/add" element={<CategoryAdd />} />

							<Route path="/coupons/add" element={<CouponAdd />} />

							<Route path="/tags/add" element={<TagAdd />} />

							<Route path="/booth-types/add" element={<BoothTypeAdd />} />

							<Route path="/rentals/add" element={<RentalAdd />} />

							<Route path="/users" element={<Users />} />
							<Route path="/users/add" element={<UserAdd />} />
							<Route path="/users/:id" element={<User />} />
							<Route path="/users/:id/password-change" element={<ChangePassword />} />
							<Route path="/users/:id/history" element={<UserHistory />} />

							<Route path="/opening-hours" element={<OpeningHours />} />
							<Route path="/opening-hours/:id" element={<OpeningHour />} />

							<Route path="/tags" element={<Tags />} />
							<Route path="/tags/:id/history" element={<TagHistory />} />
							<Route path="/tags/:id" element={<Tag />} />

							<Route path="/booths" element={<Booths />} />
							<Route path="/booths/:id/history" element={<BoothHistory />} />
							<Route path="/booths/:id" element={<Booth />} />

							<Route path="/booth-types" element={<BoothTypes />} />
							<Route path="/booth-types/:id/history" element={<BoothTypeHistory />} />
							<Route path="/booth-types/:id" element={<BoothType />} />

							<Route path="/rentals" element={<Rentals />} />
							<Route path="/rentals/:id/history" element={<RentalHistory />} />
							<Route path="/rentals/:id" element={<Rental />} />

							<Route path="/categories" element={<Categories />} />
							<Route path="/categories/:id/history" element={<CategoryHistory />} />
							<Route path="/categories/:id" element={<CategoryPage />} />

							<Route path="/coupons" element={<Coupons />} />
							<Route path="/coupons/:id/history" element={<CouponHistory />} />
							<Route path="/coupons/:id" element={<CouponPage />} />

							<Route path="/store" element={<Store />} />
						</Route>
					</Route>
				</Route>

				{/* error page */}
				<Route path="/*" element={<ErrorPage />} />
			</Routes>
		</>
	)
}

export default App
