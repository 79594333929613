import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Compressor from 'compressorjs'

import Input from 'components/Ui/Input'
import ColorPicker from 'components/ColorPicker'
import Select from 'components/Ui/Select'
import FileUploader from 'components/FileUploader'
import Header from 'components/Header'
import { useFetchData } from 'hooks'
import { useFetchInfinite } from 'hooks'
import { AuthContext } from 'store/AuthContext'
import Form from 'components/Ui/Form'
import PriceInput from 'components/PriceInput'
// import { productValidationSchema } from 'schemas/validation'
import { Wrapper, Row, TagsDesc, StyledSwitch } from 'pages/products/add/styles'
import { ITag } from 'interfaces/tag.interface'
import { useToast } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import useSelect from 'hooks/useSelect'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { useUserRoles } from 'hooks'
import { defaultConfig } from 'config/default'
import { Booking } from 'components/Booking/types'

const defaultColor = '#FFFFFF'

const ProductAddPage = () => {
	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)
	const storeCtx = useContext(StoreSettingsContext)
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const axiosPrivate = useAxiosPrivate()
	const addProductSuccessMsg = useToast({
		type: 'success',
		message: t('addProduct.form.success')
	})
	const addProductFailMsg = useToast({
		type: 'error',
		message: t('addProduct.form.error')
	})
	const [productColor, setProductColor] = useState(defaultColor)
	const yupCostPrice = storeCtx?.storeSettings?.cost_price_enabled
		? yup.string().matches(/^[+-]?([0-9]*[.])?[0-9]+$/, t('validation.addProduct.price.matches'))
		: yup.string()
	const productValidationSchema = yup.object({
		name: yup
			.string()
			.required(t('validation.addProduct.name.required'))
			.max(
				defaultConfig.maxProductNameLength,
				t('validation.addProduct.name.maxLength', { length: defaultConfig.maxProductNameLength })
			),
		booking: yup.object({
			value: yup.string().required(t('validation.addProduct.booking.value.required')).nullable()
		}),
		// category: yup.object({
		// 	value: yup.string().required(t('validation.addProduct.category.value.required'))
		// }),
		size: yup
			.string()
			.max(
				defaultConfig.maxProductSizeLength,
				t('validation.addProduct.size.maxLength', { length: defaultConfig.maxProductSizeLength })
			),
		quantity: yup
			.string()
			.default('1')
			.matches(/^[0-9]+$/, t('validation.addProduct.quantity.matches'))
			.required(t('validation.addProduct.quantity.required')),
		price: yup
			.string()
			.matches(/^[+-]?([0-9]*[.])?[0-9]+$/, t('validation.addProduct.price.matches'))
			.required(t('validation.addProduct.price.required')),
		cost_price: yupCostPrice
	})
	const {
		register,
		handleSubmit,
		control,
		setValue,
		getValues,
		trigger,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(productValidationSchema),
		mode: 'onChange'
	})
	const { handleSelectChange } = useSelect({ setValue, trigger })
	const { isUser } = useUserRoles()

	const handleProductColorChange = (data: any) => {
		const { hex } = data

		setProductColor(hex)
	}

	const { mutate: addProductMutation, isLoading: loadingAddProduct } = useMutation(
		(data: any) => {
			return axiosPrivate.post('/products/', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				addProductSuccessMsg()
				navigate('/products')
			},
			onError: () => {
				addProductFailMsg()
			}
		}
	)

	const {
		data: categories,
		isLoading: loadingCategories,
		fetchNextPage: fetchNextCategories
	} = useFetchInfinite({
		name: 'fetchCategories',
		endpoint: '/categories/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const { data: tags, isLoading: loadingTags } = useFetchData({
		name: 'fetchTags',
		endpoint: '/tags/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	type BookingRes = {
		results: Booking[]
	}

	const { data: bookings, fetchNextPage } = useFetchInfinite({
		name: 'fetchBookings',
		endpoint: `/bookings/?user=${auth?.user?.pk}&end_date__gt=${
			new Date().toISOString().split('T')[0]
		}&is_active=true`,
		enabled: !!auth?.user?.pk.toString(),
		options: {
			onSuccess(res: { pages: BookingRes[] }) {
				const data = res.pages[0]

				data.results.length === 1 &&
					setValue('booking', {
						value: data.results[0]?.id,
						label: data.results[0]?.booth
							? `${data.results[0]?.booth?.booth_type?.name} #${data.results[0]?.booth?.number} (#${data.results[0]?.ref_nr} ${data.results[0]?.start_date})`
							: `(#${data.results[0]?.ref_nr} ${data.results[0]?.start_date})`
					})
			}
		}
	})

	const onSubmit = (data: any) => {
		const formattedTags =
			data.tags != null && Array.isArray(data.tags) ? data.tags?.map((tag: ITag) => tag.label) : []
		const image = data.image ?? []

		const formData = new FormData()
		formData.append('name', data.name)
		if (!isUser) {
			formData.append('owner_id', auth?.user?.pk)
		}
		if (storeCtx?.storeSettings?.cost_price_enabled) {
			formData.append('cost_price', data?.cost_price)
		}
		formData.append('price.amount', data.price)
		formData.append('booking_id', data?.booking?.value)
		if (storeCtx?.storeSettings?.quantity_enabled) {
			formData.append('quantity', data.quantity)
		}

		formData.append('size', data.size)
		if (data.category) {
			formData.append('category_id', data.category.value)
		} else {
			formData.append('category_id', '')
		}
		if (storeCtx?.storeSettings?.photos_required_to_publish && !getValues('image')) {
			formData.append('published', 'false')
		} else if (storeCtx?.storeSettings?.users_can_publish_products) {
			formData.append('published', data?.published ? 'true' : 'false')
		} else {
			formData.append(
				'published',
				storeCtx?.storeSettings?.default_product_publish_value ? 'true' : 'false'
			)
		}
		formData.append('color', productColor)

		for (const tag of formattedTags) {
			formData.append('tags', tag)
		}

		if (image?.name) {
			// const fileExt = image.name.split('.').pop()
			// console.log(image)
			// Convert the image data from Base64 to binary
			// const reader = new FileReader()
			// reader.readAsArrayBuffer(image)
			// reader.onloadend = () => {
			// 	// const hash = Rusha.createHash().update(binaryImage)
			// }
			formData.append('image', image, image.name) //`${hash.digest('hex')}.${fileExt}`)
		}

		addProductMutation(formData)
	}

	const handleImageChange = (data: any) => {
		if (data.length > 0) {
			new Compressor(data[0], {
				quality: 0.6,
				maxHeight: 800,
				maxWidth: 800,
				success: (compressedResult) => {
					// compressedResult has the compressed file.
					// Use the compressed file to upload the images to your server.
					// setCompressedFile(res)
					setValue('image', compressedResult)
					// console.log('image', compressedResult)
				}
			})
		} else {
			setValue('image', null)
		}
	}
	useEffect(() => {
		setValue('published', storeCtx?.storeSettings?.default_product_publish_value)
	}, [storeCtx?.storeSettings])

	useEffect(() => {
		setValue('quantity', 1)
	}, [])

	return (
		<>
			<Header title={t('addProduct.title')} />
			<Wrapper>
				<Form
					submitText={t('addProduct.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={loadingAddProduct}>
					<Input
						id="name"
						label={t('product.form.title')}
						required={true}
						{...register('name')}
						error={(errors as any).name}
					/>
					<Controller
						name="booking"
						control={control}
						render={({ field }) => (
							<Select
								id="booking"
								placeholder={t('filters.placeholder')}
								label={t('product.form.booking')}
								required={true}
								options={bookings?.pages
									.reduce((total: any, page: any) => {
										return [...total, ...page.results]
									}, [])
									.map((booking: any) => {
										return {
											label: booking?.booth
												? `${booking?.booth?.booth_type?.name} #${booking?.booth?.number} (#${booking?.ref_nr} ${booking?.start_date})`
												: `(#${booking?.ref_nr} ${booking?.start_date})`,
											value: booking.id
										}
									})}
								{...field}
								error={(errors as any).booking?.value}
								handleInfiniteScroll={fetchNextPage}
								onChange={(data: any) =>
									handleSelectChange({
										id: 'booking',
										value: data?.value,
										label: data?.label
									})
								}
							/>
						)}
					/>
					{storeCtx?.storeSettings?.categories_enabled && (
						<Controller
							name="category"
							control={control}
							render={({ field }) => (
								<Select
									id="category"
									label={t('product.form.category')}
									options={
										!loadingCategories && categories != null && Array.isArray(categories?.pages)
											? categories?.pages
													.reduce((total: any, page: any) => {
														return [...total, ...page.results]
													}, [])
													.map((option: any) => ({
														label: option.name,
														value: option.id
													}))
											: []
									}
									handleInfiniteScroll={fetchNextCategories}
									{...field}
									isLoading={loadingCategories}
									error={(errors as any).category?.value}
									isSearchable={true}
									onChange={(data: any) =>
										handleSelectChange({
											id: 'category',
											value: data?.value,
											label: data?.label
										})
									}
								/>
							)}
						/>
					)}

					<Row>
						<Input
							id="size"
							label={t('product.form.size')}
							{...register('size')}
							error={(errors as any).size}
						/>
						{storeCtx?.storeSettings?.quantity_enabled && (
							<Input
								id="quantity"
								label={t('product.form.quantity')}
								required={true}
								{...register('quantity')}
								error={(errors as any).quantity}
								disabled={!storeCtx?.storeSettings?.quantity_enabled}
							/>
						)}
					</Row>
					<Row>
						{storeCtx?.storeSettings?.cost_price_enabled && (
							<PriceInput
								id="cost_price"
								label={t('product.form.costPrice')}
								required={true}
								{...register('cost_price')}
								error={(errors as any).cost_price}
								disabled={!storeCtx?.storeSettings?.cost_price_enabled}
							/>
						)}
						<PriceInput
							id="price"
							label={t('product.form.price')}
							required={true}
							{...register('price')}
							error={(errors as any).price}
						/>
					</Row>
					{storeCtx?.storeSettings?.tags_enabled && (
						<>
							<Controller
								name="tags"
								control={control}
								render={({ field }) => (
									<Select
										id="tags"
										label={t('product.form.tags')}
										options={tags?.data?.results.map((option: any) => ({
											label: option.name,
											value: option.id
										}))}
										isLoading={loadingTags}
										{...field}
										isSearchable
										isMulti
									/>
								)}
							/>

							<TagsDesc>{t('product.form.tagsDesc')}</TagsDesc>
						</>
					)}

					<Controller
						name="image"
						control={control}
						render={({ field }) => (
							<FileUploader
								{...field}
								label={t('product.form.images')}
								onChange={handleImageChange}
							/>
						)}
					/>
					{storeCtx?.storeSettings?.color_enabled && (
						<ColorPicker
							label={t('product.form.productColor.label')}
							color={productColor}
							onChange={handleProductColorChange}
						/>
					)}

					{(storeCtx?.storeSettings?.users_can_publish_products ||
						!!auth?.user?.scopes.includes('staff')) && (
						<StyledSwitch
							label={t('product.form.published.label')}
							description={
								storeCtx?.storeSettings.photos_required_to_publish
									? t('product.form.published.description_photos_required')
									: t('product.form.published.description')
							}
							{...register('published')}
							disabled={storeCtx?.storeSettings.photos_required_to_publish && !getValues('image')}
						/>
					)}
				</Form>
			</Wrapper>
		</>
	)
}

export default ProductAddPage
