import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const CategoryHistory = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: categoryHistory, isLoading } = useFetchData({
		name: 'fetchCategoryHistory',
		endpoint: `/categories/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('categoryHistory.title')} />
			<TableWrapper>
				<HistoryTable results={categoryHistory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default CategoryHistory
