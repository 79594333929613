import { useState, forwardRef, useRef, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPen } from '@fortawesome/free-solid-svg-icons'
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'

import Label from 'components/Ui/Label'
import { defaultConfig } from 'config/default'
import { Modal } from 'components/Ui'
import {
	Wrapper,
	Dropzone,
	UploadFileText,
	UploadFileRestrictions,
	ThumbsContainer,
	Thumb,
	Edit,
	Remove,
	StyledImage,
	ModalContent,
	RotateButtons,
	Actions
} from './styles'
import { Button } from 'components/Ui'

interface IFileUploader {
	maxSize?: number
	label?: string
	multiple?: boolean
	onChange: (e: any) => void
}

const defaultImageData = {
	image: null,
	position: { x: 0.5, y: 0.5 },
	scale: 1,
	rotate: 0,
	width: 600,
	height: 800,
	allowZoomOut: false
}

const FileUploaderComp = forwardRef(
	({ onChange, label, multiple = false, ...rest }: IFileUploader, ref) => {
		const data = rest as any

		const [isEditModalOpen, setEditModalOpen] = useState(false)
		const imageRef = useRef<any | null>(null)
		const [editedImageData, setEditedImageData] = useState<any>(defaultImageData)
		const [files, setFiles] = useState<any[]>([])
		const { t } = useTranslation()

		const onDrop = useCallback(
			(acceptedFiles: any) => {
				onChange([...acceptedFiles])
				setFiles([...acceptedFiles])
			},
			[files]
		)

		const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
			accept: {
				'image/*': []
			},
			maxSize: defaultConfig.maxFileSize,
			onDrop,
			multiple
		})

		const removeFile = (fileToBeRemoved: any) => {
			const filteredFiles = files.filter((file) => file.name !== fileToBeRemoved.name)

			setFiles(filteredFiles)
			acceptedFiles.splice(acceptedFiles.indexOf(fileToBeRemoved), 1)

			onChange(filteredFiles)
		}

		const editFile = (fileToEdit: any) => {
			setEditedImageData({
				...editedImageData,
				image: fileToEdit
			})

			setEditModalOpen(true)
		}

		const rotateLeft = () => {
			setEditedImageData({
				...editedImageData,
				rotate: editedImageData.rotate - 90
			})
		}

		const rotateRight = () => {
			setEditedImageData({
				...editedImageData,
				rotate: editedImageData.rotate + 90
			})
		}

		const handlePositionChange = (position: any) => {
			setEditedImageData({
				...editedImageData,
				position
			})
		}

		const handleScale = (e: any) => {
			const scale = parseFloat(e.target.value)
			setEditedImageData({
				...editedImageData,
				scale
			})
		}

		const saveEditedImage = () => {
			if (imageRef?.current) {
				const canvas = imageRef.current.getImage()

				canvas.toBlob((blob: any) => {
					const file = new File([blob], editedImageData.image.name)
					const filesClone = [...files]
					filesClone.splice(files.indexOf(editedImageData.image), 1, file)

					setFiles(filesClone)

					acceptedFiles.splice(acceptedFiles.indexOf(editedImageData.image), 1, file)

					setEditedImageData(defaultImageData)
					setEditModalOpen(false)

					onChange(acceptedFiles)
				})
			}
		}

		useEffect(() => {
			if (data.value) {
				setFiles([data.value])
			}
		}, [data.value])

		return (
			<Wrapper>
				<Label>{label}</Label>
				<Dropzone {...getRootProps({ className: 'dropzone' })} {...rest} ref={ref}>
					<input {...getInputProps()} multiple={multiple} />
					<UploadFileText>{t('fileUploader.text')}</UploadFileText>
					<UploadFileRestrictions>{t('fileUploader.description')}</UploadFileRestrictions>
				</Dropzone>
				{files !== null && Array.isArray(files) && files.length ? (
					<ThumbsContainer>
						{files.map((file, index) => (
							<Thumb key={index}>
								<Edit onClick={() => editFile(file)}>
									<FontAwesomeIcon icon={faPen} />
								</Edit>
								<Remove onClick={() => removeFile(file)}>
									<FontAwesomeIcon icon={faTimes} />
								</Remove>
								<StyledImage
									src={URL.createObjectURL(file)}
									onLoad={() => {
										URL.revokeObjectURL(URL.createObjectURL(file))
									}}
								/>
							</Thumb>
						))}
					</ThumbsContainer>
				) : (
					''
				)}

				{isEditModalOpen && (
					<Modal isOpened={isEditModalOpen} closeModal={() => setEditModalOpen(false)}>
						<ModalContent>
							{editedImageData?.image && (
								<AvatarEditor
									ref={imageRef}
									image={editedImageData.image}
									width={editedImageData.width}
									height={editedImageData.height}
									rotate={editedImageData.rotate}
									scale={parseFloat(editedImageData.scale)}
									position={editedImageData.position}
									onPositionChange={handlePositionChange}
								/>
							)}
							<Actions>
								<input
									name="scale"
									type="range"
									onChange={handleScale}
									min={editedImageData.allowZoomOut ? '0.1' : '1'}
									max="2"
									step="0.01"
									defaultValue="1"
								/>
								<RotateButtons>
									<Button
										onClick={rotateLeft}
										text={t('fileUploader.rotate.left')}
										styleType="cancel"
									/>
									<Button
										onClick={rotateRight}
										text={t('fileUploader.rotate.left')}
										styleType="cancel"
									/>
								</RotateButtons>
								<div>
									<Button onClick={saveEditedImage} text={t('fileUploader.save')} />
								</div>
							</Actions>
						</ModalContent>
					</Modal>
				)}
			</Wrapper>
		)
	}
)

export default FileUploaderComp
