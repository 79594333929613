import { useEffect, useState } from 'react'
import { theme } from 'twin.macro'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Dot from 'components/Ui/Dot'
import { StyledCardWrapper, Header, Title, Booth, Legend, LegendItem } from './styles'
import { IStatisticsCard } from './types'

const StatisticsCard = ({ className, start_date, end_date, booth }: IStatisticsCard) => {
	const [status, setStatus] = useState('')
	const [dotColor1, setDotColor1] = useState('#fff')
	const [dotColor2, setDotColor2] = useState('#fff')
	const { t } = useTranslation()

	useEffect(() => {
		const today = new Date()

		if (end_date && today > new Date(end_date)) {
			// expired
			setStatus(t('dashboard.statistics.status.expired'))
			setDotColor1(theme`colors.cardinal`)
			setDotColor2(theme`colors.azalea`)
		} else if (today < new Date(start_date)) {
			// inactive
			setStatus(t('dashboard.statistics.status.inactive'))
			setDotColor1(theme`colors.sun`)
			setDotColor2(theme`colors.colonialWhite`)
		} else {
			// active
			setStatus(t('dashboard.statistics.status.active'))
			setDotColor1(theme`colors.apple`)
			setDotColor2(theme`colors.zahan`)
		}
	}, [])

	return (
		<StyledCardWrapper className={className}>
			<Header>
				<Title>{t('dashboard.statistics.title')}</Title>
				{booth && <Booth>#{booth.number}</Booth>}
			</Header>
			<Legend>
				<LegendItem>
					<Dot color={dotColor1} />
					<span>{status}</span>
				</LegendItem>
				<LegendItem>
					<Dot color={dotColor2} />
					<span>
						{format(parseISO(start_date), 'dd.MM.yyyy')} -{' '}
						{end_date && format(parseISO(end_date), 'dd.MM.yyyy')}
					</span>
				</LegendItem>
			</Legend>
		</StyledCardWrapper>
	)
}

export default StatisticsCard
