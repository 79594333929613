import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { Column } from '@table-library/react-table-library/compact'
import { format } from 'date-fns'
import { enGB, is } from 'date-fns/locale'

import { usePagination } from 'hooks'
import { useFetchData } from 'hooks'
import OpeningHour from 'components/OpeningHour'
import EmptyTable from 'components/Ui/Table/Empty'
import { StyledHeader, StyledTable, StyledTable2 } from './styles'
import { customTableStyles, TableWrapper } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import Status from 'components/Ui/Status'
import { theme } from 'twin.macro'

const OpeningHoursPage = () => {
	const { t, i18n } = useTranslation()
	const { language } = i18n
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const { isMobileOrTablet } = useDevice()
	const [openingHoursData, setOpeningHoursData] = useState({ nodes: [] })
	const navigate = useNavigate()

	const { data: openingHours, isFetching: loadingOpeningHours } = useFetchData({
		name: 'fetchOpeningHours',
		endpoint: '/settings/business_days/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { data } = res

				setOpeningHoursData({ nodes: data })
			}
		}
	})

	const tableHeaders = [
		{
			id: 'date',
			label: t('openingHours.tableHeaders.date')
		},
		{
			id: 'open',
			label: t('openingHours.tableHeaders.open')
		},
		{
			id: 'bookable',
			label: t('openingHours.tableHeaders.bookable')
		},
		{
			id: 'countsTowardsDuration',
			label: t('openingHours.tableHeaders.countsTowardsDuration')
		},
		{
			id: 'weekday',
			label: t('openingHours.tableHeaders.weekday')
		},
		{
			id: 'holiday',
			label: t('openingHours.tableHeaders.holiday')
		},
		{
			id: 'reason',
			label: t('openingHours.tableHeaders.reason')
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/opening-hours/${id}`)
	}

	const contextMenuOptions = [
		{
			label: t('openingHours.openingHour.ellipsisMenu.edit'),
			action: handleEdit
		}
	]

	const columns: Column[] = [
		{
			label: t('openingHours.tableHeaders.date'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/opening-hours/${item.date}`}>
					{item.date}
				</NavLink>
			),
			pinLeft: true
		},
		{
			label: t('openingHours.tableHeaders.open'),
			renderCell: (item: any) => <Status active={item.is_open} />
		},
		{
			label: t('openingHours.tableHeaders.bookable'),
			renderCell: (item: any) => <Status active={item.is_bookable} />
		},
		{
			label: t('openingHours.tableHeaders.countsTowardsDuration'),
			renderCell: (item: any) => <Status active={item.counts_towards_duration} />
		},
		{
			label: t('openingHours.tableHeaders.weekday'),
			renderCell: (item: any) =>
				format(new Date(item.date), 'EEEE', { locale: language === 'is' ? is : enGB })
		},
		{
			label: t('openingHours.tableHeaders.holiday'),
			renderCell: (item: any) => item.is_holiday
		},
		{
			label: t('openingHours.tableHeaders.reason'),
			renderCell: (item: any) => item.reason
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.date} options={contextMenuOptions} />
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  repeat(7, 1fr) 40px;
	`
	}

	return (
		<>
			<StyledHeader title={t('openingHours.title')} />
			{openingHoursData.nodes != null &&
			Array.isArray(openingHoursData.nodes) &&
			openingHoursData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={loadingOpeningHours}
							headers={tableHeaders}
							onClick={handlePagination}>
							{openingHours?.data?.map((openingHour: any, index: number) => (
								<OpeningHour key={index} {...openingHour} />
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={openingHoursData}
							isLoading={loadingOpeningHours}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={loadingOpeningHours} />
			)}
		</>
	)
}

export default OpeningHoursPage
