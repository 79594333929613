import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from 'locales/en-GB.json'
import translationIS from 'locales/is.json'
import translationDK from 'locales/dk.json'

// the translations
import i18next from 'i18next'

i18next.use(initReactI18next).init({
	lng: 'en',
	fallbackLng: 'en',
	debug: false,
	resources: {
		en: {
			translation: translationEN
		},
		is: {
			translation: translationIS
		},
		dk: {
			translation: translationDK
		}
	}
})

export default i18n
