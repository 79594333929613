import { useState, useContext } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Column } from '@table-library/react-table-library/compact'
import { format, parseISO } from 'date-fns'

import Header from 'components/Header'
import FiltersWrapper from 'components/FiltersWrapper'
import Filters from 'components/Filters'
import MobileFilters from 'components/Filters/mobile'
import Payout from 'components/Payout'
import SortModal from 'components/Modals/sort'
import FiltersModal from 'components/Modals/filters'
import { useFetchData } from 'hooks'
import { usePagination } from 'hooks'
import { useSearch } from 'hooks'
import { useFilter } from 'hooks'
import { useModal } from 'hooks'
import EmptyTable from 'components/Ui/Table/Empty'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { IActionFilter } from 'interfaces/filters.interface'
import { useAxiosPrivate } from 'hooks'
import ConfirmModal from 'components/Modals/confirm'
import FormatModal from 'components/Modals/format'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import { useToast } from 'hooks'
import { IOption } from 'interfaces/select.interface'
import SortSelect from 'components/Ui/Select/Sort'
import {
	FiltersContainer,
	StyledSearchInput,
	StyledTable,
	FiltersModalContent,
	ModalFilters,
	DesktopSortSelect,
	StyledTable2
} from 'pages/payouts/styles'
import { customTableStyles, TableWrapper } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import { Currency } from 'components/Ui'
import AdvancedStatus from 'components/Status/AdvancedStatus'
import { useTableSelect } from 'hooks'
import { useUserRoles } from 'hooks'
import { AuthContext } from 'store/AuthContext'
import { theme } from 'twin.macro'

const PayoutsPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { handlePagination } = usePagination(setQuery)
	const [checkedPayouts, setCheckedPayouts] = useState<number[]>([])
	const { handleFilter } = useFilter(setQuery)
	const storeCtx = useContext(StoreSettingsContext)
	const axiosPrivate = useAxiosPrivate()
	const isIceland = storeCtx?.storeSettings?.legal_address?.country === 'IS'
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const { isMobileOrTablet } = useDevice()
	const [payoutsData, setPayoutsData] = useState({ nodes: [] })
	const { select } = useTableSelect(payoutsData, onSelectChange)
	const navigate = useNavigate()
	const userRoles = useUserRoles()
	const { auth } = useContext(AuthContext)

	const exportSuccess = useToast({
		type: 'success',
		message: t('payouts.modal.export.success')
	})
	const exportFail = useToast({
		type: 'error',
		message: t('payouts.modal.export.error')
	})

	const bulkStatusSuccess = useToast({
		type: 'success',
		message: t('payouts.modal.bulkStatus.success')
	})
	const bulkStatusFail = useToast({
		type: 'error',
		message: t('payouts.modal.bulkStatus.error')
	})

	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const { isOpen: isSortOpen, openModal: openSortModal, closeModal: closeSortModal } = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()

	const {
		isOpen: isBulkStatusModalOpen,
		openModal: openBulkStatusModal,
		closeModal: closeBulkStatusModal
	} = useModal()

	const { data: payouts, isFetching: payoutsLoading } = useFetchData({
		name: 'fetchPayouts',
		query,
		endpoint: '/payouts/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setPayoutsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'export':
				openExportModal()
				break
			case 'bulkStatus':
				openBulkStatusModal()
				break
		}
	}

	const { mutate: exportBulkMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post(
				'/payouts/export/',
				{ format: chosenExportFormat, ids: checkedPayouts },
				{ responseType: 'arraybuffer' }
			)
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'payouts-export', format })
				}

				setCheckedPayouts([])
				exportSuccess()
			},
			onError: () => {
				exportFail()
			},
			onSettled: () => {
				closeFormatModal()
				setChosenExportFormat(null)
			}
		}
	)

	const { mutate: bulkStatusMutation } = useMutation(
		() => {
			return axiosPrivate.post('/payouts/bulk_status/', {
				status: 'paid',
				ids: checkedPayouts
			})
		},
		{
			onSuccess: () => {
				setCheckedPayouts([])
				bulkStatusSuccess()
			},
			onError: () => {
				bulkStatusFail()
			},
			onSettled: () => {
				closeBulkStatusModal()
				setChosenExportFormat(null)
			}
		}
	)

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const handleBulkStatusModal = () => {
		closeBulkStatusModal()
		bulkStatusMutation()
	}

	const requestExport = () => {
		exportBulkMutation()
	}

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const filters = [
		{
			id: 'status',
			label: t('payouts.filters.status.label'),
			placeholder: t('payouts.filters.status.placeholder'),
			options: [
				{
					value: 'init',
					label: t('payouts.filters.status.options.initial')
				},
				{
					value: 'requested',
					label: t('payouts.filters.status.options.requested')
				},
				{
					value: 'processing',
					label: t('payouts.filters.status.options.processing')
				},
				{
					value: 'paid',
					label: t('payouts.filters.status.options.paid')
				},
				{
					value: 'cancelled',
					label: t('payouts.filters.status.options.cancelled')
				}
			]
		}
	]

	const sortingFilters = {
		id: 'ordering',
		label: t('payouts.filters.sort.label'),
		placeholder: t('payouts.filters.sort.placeholder'),
		options: [
			{
				value: 'status',
				label: `${t('payouts.filters.sort.options.status')} asc`
			},
			{
				value: '-status',
				label: `${t('payouts.filters.sort.options.status')} desc`
			},
			{
				value: 'ref_nr',
				label: `${t('payouts.filters.sort.options.reference')} asc`
			},
			{
				value: '-ref_nr',
				label: `${t('payouts.filters.sort.options.reference')} desc`
			}
		]
	}

	const actionFilters = [
		{
			id: 'actions',
			label: t('payouts.actions.label'),
			placeholder: t('payouts.actions.placeholder'),
			options: [
				{
					value: 'export',
					label: t('payouts.actions.options.export')
				},
				...(userRoles.isStaff
					? [
							{
								value: 'bulkStatus',
								label: t('payouts.actions.options.bulkStatus')
							}
					  ]
					: [])
			]
		}
	]

	const tableHeaders = [
		{
			id: 'reference',
			label: t('payouts.tableHeaders.reference')
		},
		{
			id: 'tx_reference',
			label: t('payouts.tableHeaders.tx_reference')
		},
		{
			id: 'created',
			label: t('payouts.tableHeaders.created')
		},
		{
			id: 'name',
			label: t('payouts.tableHeaders.name')
		},
		{
			id: 'email',
			label: t('payouts.tableHeaders.email')
		},
		...(isIceland
			? [
					{
						id: 'ssn',
						label: t('payouts.tableHeaders.ssn')
					}
			  ]
			: []),
		{
			id: 'bankInfo',
			label: t('payouts.tableHeaders.bankInfo')
		},
		{
			id: 'amount',
			label: t('payouts.tableHeaders.amount')
		},
		{
			id: 'status',
			label: t('payouts.tableHeaders.status')
		}
	]

	const handlePrint = (id: number) => {
		axiosPrivate.get(`/payouts/${id}/html/`).then((response) => {
			const invoiceURL = URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
			window.open(invoiceURL)
		})
	}

	const handleRedirect = (id: number) => {
		navigate(`/payouts/${id}`)
	}

	const contextMenuOptions = [
		{
			label: t('payouts.ellipsisMenu.edit'),
			action: handleRedirect
		},
		{
			label: t('payouts.ellipsisMenu.print'),
			action: handlePrint
		}
	]

	const columns: Column[] = [
		{
			label: t('payouts.tableHeaders.reference'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/payouts/${item.id}`}>
					{item.reference}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('payouts.tableHeaders.created'),
			renderCell: (item: any) => format(parseISO(item.created), 'MMM d, yyyy, hh:mm aaa')
		},
		{
			label: t('payouts.tableHeaders.name'),
			renderCell: (item: any) => item.account.name
		},
		{
			label: t('payouts.tableHeaders.email'),
			renderCell: (item: any) => item.account.email
		},
		...(isIceland
			? [
					{
						label: t('payouts.tableHeaders.ssn'),
						renderCell: (item: any) => item.account.ssn
					}
			  ]
			: [
					{
						label: '',
						renderCell: () => ''
					}
			  ]),
		{
			label: t('payouts.tableHeaders.bankInfo'),
			renderCell: (item: any) =>
				item.payout_method[item.payout_method.kind].account && (
					<div>
						<span>{item.payout_method[item.payout_method.kind].branch}</span>
						<span>-</span>
						<span>{item.payout_method[item.payout_method.kind].ledger}</span>
						<span>-</span>
						<span>{item.payout_method[item.payout_method.kind].account}</span>
					</div>
				)
		},
		{
			label: t('payouts.tableHeaders.total'),
			renderCell: (item: any) => <Currency price={item?.total} />
		},
		{
			label: t('payouts.tableHeaders.commission'),
			renderCell: (item: any) => <Currency price={item?.commission} />
		},
		{
			label: t('payouts.tableHeaders.amount'),
			renderCell: (item: any) => <Currency price={item.amount} />
		},
		{
			label: t('payouts.tableHeaders.status'),
			renderCell: (item: any) => <AdvancedStatus status={item.status} />
		},
		{
			label: t('payouts.tableHeaders.tx_reference'),
			renderCell: (item: any) => item.tx_reference
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedPayouts(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns: 40px repeat(${columns.length - 1}, 1fr) 40px;
	`
	}

	return (
		<>
			<Header title={t('payouts.title')} handleSearch={handleSearch} />
			<FiltersWrapper>
				<FiltersContainer>
					<StyledSearchInput
						label={t('payouts.filters.search.label')}
						placeholder={t('payouts.filters.search.placeholder')}
						onChange={handleSearch}
					/>
					<Filters filters={filters} handleFilter={handleFilter} />
					<Filters
						filters={actionFilters}
						disableActions={false}
						handleFilter={handleActionFilter}
					/>
					<MobileFilters openFilters={openFiltersModal} openSort={openSortModal} />
				</FiltersContainer>
				<DesktopSortSelect {...sortingFilters} onChange={handleFilter} />
			</FiltersWrapper>
			{payoutsData?.nodes != null &&
			Array.isArray(payoutsData.nodes) &&
			payoutsData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isIceland={isIceland}
							isLoading={payoutsLoading}
							prev={payouts?.data?.previous}
							next={payouts?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{payouts?.data.results.map((payout: any) => (
								<Payout key={payout.id} {...payout} isIceland={isIceland} />
							))}
						</StyledTable>
					) : (
						// Some bug causes this component to crash on page reload.
						// it seems to be a bug in the library.
						<StyledTable2
							columns={columns}
							data={payoutsData}
							isLoading={payoutsLoading}
							select={select}
							prev={payouts?.data?.previous}
							next={payouts?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}

					{isFiltersOpen && (
						<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
							<FiltersModalContent>
								<ModalFilters filters={filters} handleFilter={handleFilter} />
							</FiltersModalContent>
						</FiltersModal>
					)}
					{isSortOpen && (
						<SortModal isOpened={isSortOpen} closeModal={closeSortModal}>
							<SortSelect {...sortingFilters} onChange={handleFilter} />
						</SortModal>
					)}
					{isExportModalOpen && (
						<ConfirmModal
							title={t('payouts.modal.export.title')}
							isOpened={isExportModalOpen}
							closeModal={closeExportModal}
							confirm={handleExportModal}>
							<p>{t('payouts.modal.export.text')}</p>
						</ConfirmModal>
					)}
					{isBulkStatusModalOpen && (
						<ConfirmModal
							title={t('payouts.modal.bulkStatus.title')}
							isOpened={isBulkStatusModalOpen}
							closeModal={closeBulkStatusModal}
							confirm={handleBulkStatusModal}>
							<p>{t('payouts.modal.bulkStatus.text')}</p>
						</ConfirmModal>
					)}
					{isFormatModalOpen && (
						<FormatModal
							disableButton={chosenExportFormat === null}
							isOpened={isFormatModalOpen}
							closeModal={closeFormatModal}
							confirm={requestExport}
							isLoading={isExportLoading}
							onChange={handleFormatChange}
						/>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={payoutsLoading} />
			)}
		</>
	)
}

export default PayoutsPage
