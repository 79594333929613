import { useContext } from 'react'
import { format, parseISO } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import useSelect from 'hooks/useSelect'
import Form from 'components/Ui/Form'
import { useAxiosPrivate } from 'hooks'
import Input from 'components/Ui/Input'
import PhoneNumberInput from 'components/Ui/PhoneNumberInput'
import { useFetchData } from 'hooks'
import Checkbox from 'components/Ui/Checkbox'
import SecurityCard from 'components/SecurityCard'
import { useToast } from 'hooks'
import {
	HeaderWrapper,
	StyledHeader,
	Wrapper,
	Row,
	Right,
	Info,
	InfoHeader,
	InfoRow
} from './styles'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import HistoryButton from 'components/Ui/Button/History'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { MobileBackButton } from 'components/Ui/Button/Back'
// import { editUserValidationSchema } from 'schemas/validation'
import { useUserRoles } from 'hooks'
import { Country } from 'react-phone-number-input'
import { UserSalesTable } from './sales'
import { UserProductsTable } from './products'
import Select from 'components/Ui/Select'
import { UserBookingsTable } from './bookings'

const UserPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const params = useParams()
	const navigate = useNavigate()
	const { isSuperuser, isAdmin } = useUserRoles()
	const settingsCtx = useContext(StoreSettingsContext)
	const isIceland = settingsCtx?.storeSettings?.country == 'IS'
	const bankInfoYup = isIceland
		? yup.object({
				ledger: yup
					.string()
					.max(2, t('validation.editUser.bankInfo.ledger.max', { count: 2 }))
					.required(t('validation.editUser.bankInfo.ledger.required')),
				branch: yup
					.string()
					.max(4, t('validation.editUser.bankInfo.branch.max', { count: 4 }))
					.required(t('validation.editUser.bankInfo.branch.required')),
				account: yup
					.string()
					.max(6, t('validation.editUser.bankInfo.account.max', { count: 6 }))
					.required(t('validation.editUser.bankInfo.account.required'))
		  })
		: yup.object({
				// ledger: yup.string().max(2, t('validation.editUser.bankInfo.ledger.max')).required(),
				branch: yup
					.string()
					.max(4, t('validation.editUser.bankInfo.branch.max', { count: 4 }))
					.required(t('validation.editUser.bankInfo.branch.required')),
				account: yup
					.string()
					.max(10, t('validation.editUser.bankInfo.account.max', { count: 10 }))
					.required(t('validation.editUser.bankInfo.account.required'))
		  })
	const editUserValidationSchema = yup.object({
		email: yup.string().required(t('validation.editUser.email.required')),
		// phone_number: yup
		// 	.string()
		// 	.matches(/^\+?\d*$/, t('validation.editUser.phoneNumber.matches'))
		// 	.required(t('validation.editUser.phoneNumber.required')),
		bank_info: bankInfoYup,
		address: yup.object({
			address: yup.string().required(t('validation.editUser.address.address.required')),
			postal_code: yup.string().required(t('validation.editUser.address.postalCode.required')),
			city: yup.string().required(t('validation.editUser.address.city.required'))
		}),
		country: yup.object({
			value: yup.string().required(t('validation.editUser.country.value.required'))
		})
	})
	const fetchCountries = () =>
		axiosPrivate
			.options('/settings/')
			.then((res) => res?.data?.actions?.PUT?.address?.children?.country?.choices)

	const { data: countries, isLoading: loadingCountries } = useQuery(
		'fetchCountries',
		fetchCountries
	)
	const {
		handleSubmit,
		register,
		setValue,
		control,
		trigger,
		setError,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(editUserValidationSchema)
	})
	const queryClient = useQueryClient()

	const editProductSuccessMsg = useToast({
		type: 'success',
		message: t('user.form.success')
	})
	const editProductFailMsg = useToast({
		type: 'error',
		message: t('user.form.error')
	})
	const { handleSelectChange } = useSelect({ setValue, trigger })

	const { data: user } = useFetchData({
		name: 'fetchUser',
		endpoint: `/accounts/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				setValue('email', res.data.email)
				setValue('name', res.data.name)
				setValue('ssn', res.data.ssn)
				setValue('phone_number', res.data.phone_number)
				setValue('is_active', res.data.is_active)
				setValue('is_staff', res.data.is_staff)
				setValue('is_admin', res.data.is_admin)
				setValue('is_superuser', res.data.is_superuser)

				setValue('address.address', res.data?.address?.address)
				setValue('address.postal_code', res.data?.address?.postal_code)
				setValue('address.city', res.data?.address?.city)

				setValue('bank_info.branch', res.data.bank_info[res.data.bank_info.kind].branch)
				setValue('bank_info.ledger', res.data.bank_info[res.data.bank_info.kind].ledger)
				setValue('bank_info.account', res.data.bank_info[res.data.bank_info.kind].account)
			}
		}
	})

	const { mutate: editUserMutation, isLoading: editUserLoading } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/accounts/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchUsers')
				editProductSuccessMsg()

				navigate('/users')
			},
			onError: (err: any) => {
				for (const item in err.response.data) {
					setError(item, { message: err.response.data[item] })
				}

				editProductFailMsg()
			}
		}
	)

	const onSubmit = (data: any) => {
		const formattedData = {
			...data,
			bank_info: {
				[user?.data?.bank_info?.kind]: {
					ledger: data.bank_info?.ledger,
					branch: data.bank_info?.branch,
					account: data.bank_info?.account
				}
			}
		}

		editUserMutation(formattedData)
	}

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('user.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton to="/users" />
					<HistoryButton to={`/users/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<Wrapper>
				<Form
					submitText={t('user.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={editUserLoading}>
					<FormSubtitle title={t('user.form.account.title')} hasBorder={false} />
					<Input
						id="email"
						label={t('user.form.email')}
						{...register('email')}
						type="email"
						error={(errors as any).email}
						required={true}
					/>
					<FormSubtitle title={t('user.form.info.title')} hasBorder={false} />
					<Input
						id="name"
						label={t('user.form.name')}
						{...register('name')}
						error={(errors as any).name}
					/>
					<Row>
						{isIceland && (
							<Input
								id="ssn"
								label={t('user.form.ssn')}
								{...register('ssn')}
								error={(errors as any).ssn}
							/>
						)}
						<PhoneNumberInput
							id="phone"
							control={control}
							defaultCountry={settingsCtx?.storeSettings?.address?.country as Country}
							label={t('user.form.phone')}
							{...register('phone_number')}
							error={(errors as any).phone_number}
						/>
					</Row>
					<Controller
						name="country"
						control={control}
						render={({ field }) => (
							<Select
								id="country"
								label={t('filters.country')}
								options={countries?.map((option: any) => ({
									label: option.display_name,
									value: option.value
								}))}
								{...field}
								isLoading={loadingCountries}
								error={(errors as any).country?.value}
								required={true}
								onChange={(data: any) =>
									handleSelectChange({
										id: 'country',
										value: data?.value,
										label: data?.label
									})
								}
							/>
						)}
					/>
					<Input
						id="address"
						{...register('address.address')}
						label={t('store.form.address')}
						error={(errors as any).address?.address}
						required={true}
					/>
					<Row>
						<Input
							id="city"
							{...register('address.city')}
							label={t('store.form.city')}
							error={(errors as any).address?.city}
							required={true}
						/>
						<Input
							id="postal_code"
							{...register('address.postal_code')}
							label={t('store.form.postal')}
							error={(errors as any).address?.postal_code}
							required={true}
						/>
					</Row>

					<FormSubtitle title={t('user.form.bank.title')} hasBorder={false} />
					<Row>
						<Input
							id="bank"
							label={t('user.form.bank.label')}
							{...register('bank_info.branch')}
							error={(errors as any).bank_info?.branch}
							required={true}
						/>
						{isIceland && (
							<Input
								id="ledger"
								label={t('user.form.ledger')}
								{...register('bank_info.ledger')}
								error={(errors as any).bank_info?.ledger}
							/>
						)}
						<Input
							id="account"
							label={t('user.form.bank_account')}
							{...register('bank_info.account')}
							error={(errors as any).bank_info?.account}
							required={true}
						/>
					</Row>
					<FormSubtitle title={t('user.form.access.title')} hasBorder={false} />
					<Row>
						<Checkbox
							{...register('is_active')}
							label={t('user.form.active.label')}
							disabled={!isAdmin && !isSuperuser}
						/>
						<Checkbox
							{...register('is_staff')}
							label={t('user.form.staff.label')}
							disabled={!isAdmin || (!isAdmin && !isSuperuser)}
						/>
						<Checkbox
							{...register('is_admin')}
							label={t('user.form.administrator.label')}
							disabled={!isAdmin}
						/>
						<Checkbox
							{...register('is_superuser')}
							label={t('user.form.superuser.label')}
							disabled={!isSuperuser}
						/>
					</Row>
				</Form>
				<Right>
					<Info>
						<InfoHeader>{t('user.info.title')}</InfoHeader>
						{user?.data?.date_joined && (
							<InfoRow>
								<span>{t('user.info.firstLogin')}</span>
								<span>{format(parseISO(user?.data?.date_joined), 'dd/MM/yyyy, hh:mm aaa')}</span>
							</InfoRow>
						)}
						{user?.data?.last_login && (
							<InfoRow>
								<span>{t('user.info.lastLogin')}</span>
								<span>{format(parseISO(user?.data?.last_login), 'dd/MM/yyyy, hh:mm aaa')}</span>
							</InfoRow>
						)}
					</Info>
					<SecurityCard passwordChangeLink="password-change" />

					<UserBookingsTable user_id={params.id}></UserBookingsTable>
					<UserSalesTable account_id={params.id}></UserSalesTable>
					<UserProductsTable owner_id={params.id}></UserProductsTable>
				</Right>
			</Wrapper>
		</>
	)
}

export default UserPage
