import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { format } from 'date-fns'

import { useAxiosPrivate } from 'hooks'
import Header from 'components/Header'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'hooks'
import { Row, Wrapper, Fieldset, Legend } from './styles'
import Checkbox from 'components/Ui/Checkbox'
import DatePickerInput from 'components/Ui/Datepicker'
// import { addCouponValidationSchema } from 'schemas/validation'

const CouponAddPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const successMessage = useToast({ type: 'success', message: t('addCoupon.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addCoupon.form.error') })
	const addCouponValidationSchema = yup.object({
		code: yup.string().required(t('validation.addCoupon.code.required')),
		price_discount_value: yup
			.number()
			.required(t('validation.addCoupon.price_discount_value.required'))
			.min(0)
			.when('price_discount_is_percentage', { is: true, then: yup.number().max(100) }),
		price_discount_is_percentage: yup.bool().required(),
		// commission_discount_value: yup.number().required(),
		// commission_discount_is_percentage: yup.bool().,
		// expiration_date: yup.string(),
		// all_users: yup.bool().required(),
		is_active: yup.bool().required()
		// max_uses: yup.number().required(),
		// max_uses_is_infinite: yup.bool().required(),
		// max_uses_uses_per_user: yup.number().required()
	})
	const {
		handleSubmit,
		register,
		control,
		setError,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addCouponValidationSchema)
	})

	const { mutate: createCoupon, isLoading: createCouponLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/coupons/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCoupons')
				successMessage()

				navigate('/coupons')
			},
			onError: (error) => {
				const data = (error as any).response.data
				for (const property in data) {
					if (property === 'price_discount') {
						setError('price_discount_value', {
							message: data[property].non_field_errors.join(', ')
						})
					} else {
						setError(property, { message: data[property] })
					}
				}
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		console.log(data)
		const formData = {
			code: data.code,
			price_discount: {
				value: data.price_discount_value,
				is_percentage: data.price_discount_is_percentage as boolean
			},
			// commission_discount: {
			// 	value: data.commission_discount_value,
			// 	is_percentage: !!data.commission_discount_is_percentage
			// },
			// allowed_users: {
			// 	all_users: data.all_users
			// },
			validity: {
				expiration_date: data.expiration_date
					? format(data.expiration_date, 'yyyy-MM-dd')
					: format(new Date(), 'yyyy-MM-dd'),
				is_active: !!data.is_active
			}
			// max_uses: {
			// 	max_uses: data.max_uses,
			// 	is_infinite: !!data.max_uses_is_infinite,
			// 	uses_per_user: data.uses_per_user
			// }
		}
		createCoupon(formData as any)
	}

	return (
		<>
			<Header title={t('addCoupon.title')} />
			<Wrapper>
				<Form
					submitText={t('addCoupon.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={createCouponLoading}>
					<Row>
						<Input
							id="code"
							label={t('addCoupon.form.code')}
							{...register('code')}
							error={(errors as any).code}
							required={true}
						/>
					</Row>
					<Fieldset>
						<Legend>{t('addCoupon.form.price_discount.title')}</Legend>
						<Row>
							<Input
								id="price_discount_value"
								label={t('addCoupon.form.price_discount.value')}
								{...register('price_discount_value')}
								type="number"
								step="0.01"
								error={(errors as any).price_discount_value}
								required={true}
							/>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="price_discount_is_percentage"
									{...register('price_discount_is_percentage')}
									label={t('addCoupon.form.price_discount.is_percentage')}
								/>
							</div>
						</Row>
					</Fieldset>
					{/* <Fieldset>
						<Legend>{t('addCoupon.form.commission_discount.title')}</Legend>
						<Row>
							<Input
								id="commission_discount_value"
								label={t('addCoupon.form.commission_discount.value')}
								{...register('commission_discount_value')}
								type="number"
								step="0.01"
								error={(errors as any).commission_discount_value}
							/>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="commission_discount_is_percentage"
									{...register('commission_discount_is_percentage')}
									label={t('addCoupon.form.commission_discount.is_percentage')}
								/>
							</div>
						</Row>
					</Fieldset>
					<Fieldset>
						<Legend>{t('addCoupon.form.allowed_users.title')}</Legend>
						<Row>
							<Checkbox
								id="all_users"
								{...register('all_users')}
								label={t('addCoupon.form.allowed_users.all_users')}
							/>
						</Row>
					</Fieldset> */}
					<Fieldset>
						<Legend>{t('addCoupon.form.validity.title')}</Legend>
						<Row>
							<Controller
								control={control}
								name="expiration_date"
								render={({ field }) => (
									<DatePickerInput
										label={t('addCoupon.form.validity.expiration_date')}
										onChange={field.onChange}
										selected={field.value}
										minDate={new Date()}
									/>
								)}
							/>
							<span>{(errors as any).expiration_date}</span>
						</Row>
						<Row>
							<div style={{ marginTop: 10 }}>
								<Checkbox
									id="is_active"
									{...register('is_active')}
									label={t('addCoupon.form.validity.is_active')}
								/>
							</div>
						</Row>
					</Fieldset>

					{/* <Fieldset>
						<Legend>{t('addCoupon.form.max_uses.title')}</Legend>
						<Row>
							<Input
								id="max_uses"
								label={t('addCoupon.form.max_uses.max_uses')}
								{...register('max_uses')}
								type="number"
								error={(errors as any).max_uses}
							/>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Checkbox
									id="is_infinite"
									{...register('is_infinite')}
									label={t('addCoupon.form.max_uses.is_infinite')}
								/>
							</div>
							<Input
								id="uses_per_user"
								label={t('addCoupon.form.max_uses.uses_per_user')}
								{...register('uses_per_user')}
								type="number"
								error={(errors as any).uses_per_user}
							/>
						</Row>
					</Fieldset> */}
				</Form>
			</Wrapper>
		</>
	)
}

export default CouponAddPage
