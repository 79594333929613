import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const CouponHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: couponHistory, isLoading } = useFetchData({
		name: 'fetchCouponHistory',
		endpoint: `/coupons/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('couponHistory.title')} />
			<TableWrapper>
				<HistoryTable results={couponHistory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default CouponHistoryPage
