import { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import {
	RectangleGroupIcon,
	ShoppingBagIcon,
	Bars4Icon,
	ClipboardDocumentCheckIcon,
	BanknotesIcon,
	UsersIcon,
	EyeIcon,
	AdjustmentsHorizontalIcon,
	UserCircleIcon,
	ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline'
import { theme } from 'twin.macro'

import Logo from 'components/Ui/Logo'
import { AuthContext, DashboardContext, StoreSettingsContext, NavContext } from 'store'
import { useUserRoles, useLogout } from 'hooks'
import {
	Wrapper,
	Top,
	CloseBtn,
	StyledNavLink,
	StyledLink,
	StyledLanguageSwitcher,
	Logout,
	LogoutText,
	Bottom,
	IconContainer,
	TopContent,
	PoweredBy,
	SidebarBalance,
	BalanceWrapper,
	BalanceTitle
} from './styles'
import DropdownItem from './DropdownItem'
import { Currency, EllipsisText } from 'components/Ui'
import { IconWrapper } from 'components'

import type { IDropdownItem } from './types'

const Sidebar = () => {
	const { t } = useTranslation()
	const navCtx = useContext(NavContext)
	const settingsCtx = useContext(StoreSettingsContext)
	const dashboardCtx = useContext(DashboardContext)

	// TODO
	// restrict access functionality
	const { auth } = useContext(AuthContext)
	const [restrictedAccess, setRestrictedAccess] = useState(true)
	const { isUser } = useUserRoles()

	const logout = useLogout()
	const handleLogout = () => logout()

	const closeSidebar = () => {
		navCtx?.toggleNavOpened(false)
	}

	// restrict access functionality
	useEffect(() => {
		if (isUser) {
			setRestrictedAccess(true)
		} else {
			setRestrictedAccess(false)
		}
	}, [auth?.user])

	const settingsItems: IDropdownItem[] = [
		{
			to: '/store',
			label: t('sidebar.store')
		},
		{
			label: t('sidebar.settings.products')
		},
		{
			to: '/categories',
			label: t('sidebar.categories')
		},
		{
			to: '/tags',
			label: t('sidebar.tags')
		},
		{
			label: t('sidebar.settings.widget')
		},
		{
			to: '/coupons',
			label: t('sidebar.coupons')
		},
		{
			to: '/booth-types',
			label: t('sidebar.boothTypes')
		},
		{
			to: '/booths',
			label: t('sidebar.booths')
		},
		{
			to: '/floor-map',
			label: t('sidebar.floorMap')
		},
		{
			to: '/rentals',
			label: t('sidebar.rentals')
		},
		{
			to: '/opening-hours',
			label: t('sidebar.openingHours')
		}
	]

	const isSettingsDropdownActive = !!settingsItems.find(
		(item) => item.to === window.location.pathname
	)

	return (
		<Wrapper isOpen={navCtx?.isNavOpened || false}>
			<Top>
				<CloseBtn onClick={closeSidebar}>
					<FontAwesomeIcon icon={faTimes} />
				</CloseBtn>
				<Logo />
				<TopContent>
					<StyledNavLink to="/" onClick={closeSidebar} end>
						<IconContainer>
							<RectangleGroupIcon />
						</IconContainer>
						<span>{t('sidebar.dashboard')}</span>
					</StyledNavLink>
					<StyledNavLink to="/sales" onClick={closeSidebar}>
						<IconContainer>
							<ShoppingBagIcon />
						</IconContainer>
						<span>{t('sidebar.sales')}</span>
					</StyledNavLink>
					<StyledNavLink to="/products" onClick={closeSidebar}>
						<IconContainer>
							<Bars4Icon />
						</IconContainer>
						<span>{t('sidebar.products')}</span>
					</StyledNavLink>
					<StyledNavLink to="/bookings" onClick={closeSidebar}>
						<IconContainer>
							<ClipboardDocumentCheckIcon />
						</IconContainer>
						<span>{t('sidebar.bookings')}</span>
					</StyledNavLink>
					<StyledNavLink to="/payouts" onClick={closeSidebar}>
						<IconContainer>
							<BanknotesIcon />
						</IconContainer>
						<span>{t('sidebar.payouts')}</span>
					</StyledNavLink>
					{!restrictedAccess && (
						<StyledNavLink to="/users" onClick={closeSidebar}>
							<IconContainer>
								<UsersIcon />
							</IconContainer>
							<span>{t('sidebar.users')}</span>
						</StyledNavLink>
					)}

					{!restrictedAccess && (
						<DropdownItem
							icon={<AdjustmentsHorizontalIcon />}
							labelText={t('sidebar.settings.title')}
							items={settingsItems}
							isActive={isSettingsDropdownActive}
						/>
					)}
				</TopContent>
			</Top>
			<Bottom>
				<SidebarBalance>
					<IconWrapper iconBgColor={theme`colors.zahan`} iconColor={theme`colors.apple`}>
						<BanknotesIcon />
					</IconWrapper>
					<BalanceWrapper>
						<BalanceTitle>{t('dashboard.cards.card1.title')}</BalanceTitle>
						<Currency
							price={{
								amount: dashboardCtx?.dashboard?.data?.balance as string,
								currency: dashboardCtx?.dashboard?.data?.currency as string
							}}
						/>
					</BalanceWrapper>
				</SidebarBalance>
				<StyledNavLink to="/profile" onClick={closeSidebar}>
					<IconContainer>
						<UserCircleIcon />
					</IconContainer>
					<EllipsisText>
						<div>{t('sidebar.profile')}</div>
						<div style={{ fontSize: 10 }}>
							{auth?.user?.name ? (
								<>
									<span>{auth.user.name}</span>{' '}
								</>
							) : (
								<>
									<span>{auth?.user?.email}</span>{' '}
								</>
							)}
						</div>
					</EllipsisText>
				</StyledNavLink>
				{settingsCtx?.storeSettings?.website_url && (
					<StyledLink href={settingsCtx?.storeSettings?.website_url} target="_blank">
						<IconContainer>
							<EyeIcon />
						</IconContainer>
						<EllipsisText>{t('sidebar.visitStore')}</EllipsisText>
					</StyledLink>
				)}
				<StyledLanguageSwitcher icon />
				<Logout onClick={handleLogout}>
					<IconContainer>
						<ArrowLeftOnRectangleIcon />
					</IconContainer>
					<LogoutText>{t('sidebar.logout')}</LogoutText>
				</Logout>
				<PoweredBy>
					<a href="https://smartgo.is">{t('sidebar.poweredby')}</a>
				</PoweredBy>
			</Bottom>
		</Wrapper>
	)
}

export default Sidebar
