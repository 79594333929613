import { useState, useEffect } from 'react'
import { MonthlyBody, MonthlyDay } from '@zach.codes/react-calendar'
import {
	format,
	startOfMonth,
	subMonths,
	getYear,
	getMonth,
	addMonths,
	parseISO,
	isSameDay
} from 'date-fns'
import { useTranslation } from 'react-i18next'
import { enGB, is } from 'date-fns/locale'
import { theme } from 'twin.macro'

import { Modal, Button, BackButton } from 'components/Ui'
import { StyledCalendar, TableWrapper } from 'styles/general'
import {
	CalendarNav,
	ModalContent,
	ModalItem,
	HeaderWrapper,
	Label,
	CalendarStatus,
	ModalContentTitle,
	ModalItemHeader,
	modalCustomStyles,
	ModalDates
} from './styles'
import { useModal, useFetchData } from 'hooks'

import type { BookingFormattedInfo, IBookingInfo } from './types'

const BookingCalendar = () => {
	const [currentMonth, setCurrentMonth] = useState<Date>(startOfMonth(new Date()))
	const [query, setQuery] = useState<string | null>(
		`year=${getYear(new Date())}&month=${getMonth(new Date()) + 1}`
	)
	const { t, i18n } = useTranslation()
	const { language } = i18n
	const [items, setItems] = useState<BookingFormattedInfo[]>([])
	const [chosenDate, setChosenDate] = useState('')
	const [startingDates, setStartingDates] = useState<BookingFormattedInfo[]>([])
	const [endingDates, setEndingDates] = useState<any[]>([])

	useFetchData({
		name: 'fetchMonth',
		endpoint: '/bookings/diff_calendar/',
		query,
		options: {
			onSuccess: (res: { data: IBookingInfo[] }) => {
				const { data } = res

				const formattedData = data.reduce(
					(total: BookingFormattedInfo[], current: IBookingInfo) => {
						return [
							...total,
							{
								id: current.id,
								title: 'start',
								date: parseISO(current.start_date),
								ref: current.ref_nr,
								start_date: current.start_date,
								end_date: current.end_date,
								name: current.booth?.booth_type?.name
									? `${current.booth?.booth_type?.name} - #${current.booth?.number}`
									: '-',
								user: current.user.name ? current.user.name : current.user.email,
								boothNumber: current.booth?.number && `#${current.booth?.number}`,
								boothType: current.booth?.booth_type?.name
							},
							{
								id: current.id,
								title: 'end',
								date: current.end_date ? parseISO(current.end_date) : null,
								ref: current.ref_nr,
								start_date: current.start_date,
								end_date: current.end_date,
								name: current.booth?.booth_type?.name
									? `${current.booth?.booth_type?.name} - #${current.booth?.number}`
									: '-',
								user: current.user.name ? current.user.name : current.user.email,
								boothNumber: current.booth?.number && `#${current.booth?.number}`,
								boothType: current.booth?.booth_type?.name
							}
						]
					},
					[]
				) as BookingFormattedInfo[]

				setItems(formattedData)
			}
		}
	})

	const { isOpen, openModal, closeModal } = useModal()

	const handleModalOpen = (date: Date) => {
		setChosenDate(format(date, 'dd. LLLL'))

		const newCurrentDates = items.filter((item) => isSameDay(item.date, date))

		const startingDates = newCurrentDates.filter((item) => item.title === 'start')
		const endingDates = newCurrentDates.filter((item) => item.title === 'end')

		setStartingDates(startingDates)
		setEndingDates(endingDates)
		openModal()
	}

	const handleCalendarNav = (direction: string) => {
		if (direction === 'prev') {
			setCurrentMonth(subMonths(currentMonth, 1))
		} else {
			setCurrentMonth(addMonths(currentMonth, 1))
		}
	}

	useEffect(() => {
		setQuery(`year=${getYear(currentMonth)}&month=${getMonth(currentMonth) + 1}`)
	}, [currentMonth])

	return (
		<TableWrapper>
			<HeaderWrapper>
				<BackButton to="/bookings" />
				<CalendarNav>
					<Button onClick={() => handleCalendarNav('prev')} text={t('scheduler.nav.prev')} />
					<div>{format(currentMonth, 'LLLL yyyy', { locale: language === 'is' ? is : enGB })}</div>
					<Button onClick={() => handleCalendarNav('next')} text={t('scheduler.nav.next')} />
				</CalendarNav>
			</HeaderWrapper>
			<StyledCalendar
				currentMonth={currentMonth}
				onCurrentMonthChange={(date) => setCurrentMonth(date)}>
				<MonthlyBody events={items}>
					<MonthlyDay
						renderDay={(data: BookingFormattedInfo[]) => {
							if (data.length === 0) {
								return <></>
							}
							const date = data[0].date
							const start = data.filter((item) => item.title === 'start')
							const end = data.filter((item) => item.title === 'end')

							return (
								<>
									<div onClick={() => handleModalOpen(date)}>
										<CalendarStatus
											status={true}
											text={'' + start.length}
											deactivatedColor={theme`colors.red`}
										/>
									</div>
									<div onClick={() => handleModalOpen(date)}>
										<CalendarStatus
											status={false}
											text={'' + end.length}
											deactivatedColor={theme`colors.red`}
										/>
									</div>
								</>
							)
						}}
					/>
				</MonthlyBody>
			</StyledCalendar>
			{isOpen && (
				<Modal
					isOpened={isOpen}
					closeModal={closeModal}
					customStyles={modalCustomStyles}
					title={chosenDate}>
					<ModalContent>
						<div>
							<ModalContentTitle color={theme`colors.apple`}>
								{t('calendar.modal.in')}
							</ModalContentTitle>
							{startingDates.map((item, index: number) => (
								<ModalItem key={index}>
									<ModalItemHeader>
										<Label to={`/bookings/${item.id}`}>{item.ref}</Label>
										<span style={{ paddingLeft: 10 }}>
											{item.boothType} {item.boothNumber}
										</span>
									</ModalItemHeader>
									<div>{item.user}</div>
									<ModalDates>
										{item.start_date ? format(new Date(item.start_date), 'dd.MM.yyyy') : '-'} -{' '}
										{item.end_date ? format(new Date(item.end_date), 'dd.MM.yyyy') : '-'}
									</ModalDates>
								</ModalItem>
							))}
						</div>
						<div>
							<ModalContentTitle color={theme`colors.red`}>
								{t('calendar.modal.out')}
							</ModalContentTitle>
							{endingDates.map((item: any, index: number) => (
								<ModalItem key={index}>
									<ModalItemHeader>
										<Label to={`/bookings/${item.id}`}>{item.id}</Label>
										<span style={{ paddingLeft: 10 }}>
											{item.boothType} {item.boothNumber}
										</span>
									</ModalItemHeader>
									<span>{item.user}</span>
									<ModalDates>
										{item.start_date ? format(new Date(item.start_date), 'dd.MM.yyyy') : '-'} -{' '}
										{item.end_date ? format(new Date(item.end_date), 'dd.MM.yyyy') : '-'}
									</ModalDates>
								</ModalItem>
							))}
						</div>
					</ModalContent>
				</Modal>
			)}
		</TableWrapper>
	)
}

export default BookingCalendar
