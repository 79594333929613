import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { format, addDays } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Select from 'components/Ui/Select'
import { useFetchInfinite } from 'hooks'
import DatePickerInput from 'components/Ui/Datepicker'
import { HeaderWrapper, StyledHeader, Wrapper, Row } from './styles'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import useSelect from 'hooks/useSelect'
import { AuthContext } from 'store/AuthContext'
import UserSelect from 'components/UserSelect'
// import { addBookingValidationSchema } from 'schemas/validation'

const BookingsAddPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)

	const addBookingSuccessMsg = useToast({
		type: 'success',
		message: t('addBooking.form.success')
	})

	const addBookingFailMsg = (message: string) => {
		return useToast({
			type: 'error',
			message: message ? message : t('addBooking.form.error')
		})
	}

	const addBookingValidationSchema = yup.object({
		user: yup.object({
			value: yup.string().required(t('validation.addBooking.user.value.required'))
		}),
		rental: yup.object({
			value: yup.string().required(t('validation.addBooking.rental.value.required'))
		}),
		booth: yup.object({
			value: yup.string().required(t('validation.addBooking.booth.value.required'))
		})
	})
	const {
		handleSubmit,
		control,
		register,
		setValue,
		trigger,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addBookingValidationSchema)
	})
	const { handleSelectChange } = useSelect({ setValue, trigger })
	const watchFrom = watch('from')

	const { data: rentals, fetchNextPage: fetchNextRentals } = useFetchInfinite({
		name: 'fetchRental',
		endpoint: '/rentals/?is_active=true',
		enabled: !!auth.accessToken
	})

	const { data: booths, fetchNextPage: fetchNextBooths } = useFetchInfinite({
		name: 'fetchBooths',
		endpoint: '/booths/',
		enabled: !!auth.accessToken
	})

	const { mutate: addBookingMutation, isLoading: addBookingLoading } = useMutation(
		(data: any) => {
			return axiosPrivate.post('/bookings/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBookings')
				addBookingSuccessMsg()
				navigate('/bookings')
			},
			onError: (err: any) => {
				const data = err.response.data
				const nonFieldErrors = 'non_field_errors' in data ? data.non_field_errors : []
				const catchAllErrors = '__all__' in data ? data.__all__ : []
				console.log('error:', data)
				const allErrors = [...nonFieldErrors, ...catchAllErrors]
				addBookingFailMsg(allErrors.join('\n'))()
			}
		}
	)

	const onSubmit = (data: any) => {
		const formData = {
			is_active: true,
			user: +data.user?.value,
			rental: +data.rental?.value,
			start_date: data.from ? format(data.from, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
			end_date: data.to ? format(data.to, 'yyyy-MM-dd') : undefined,
			booth: +data.booth?.value,
			commission: data.commission,
			payment: {
				status: data.payment?.status?.value,
				method: data.payment?.method?.value
			},
			store_notes: data.store_notes
		}

		addBookingMutation(formData)
	}

	const paymentStatus = [
		{
			value: 'init',
			label: t('addBooking.form.payment.status.options.initial')
		},
		{
			value: 'success',
			label: t('addBooking.form.payment.status.options.success')
		},
		{
			value: 'pending',
			label: t('addBooking.form.payment.status.options.pending')
		},
		{
			value: 'error',
			label: t('addBooking.form.payment.status.options.error')
		},
		{
			value: 'canceled',
			label: t('addBooking.form.payment.status.options.cancelled')
		},
		{
			value: 'undefined',
			label: t('addBooking.form.payment.status.options.undefined')
		}
	]

	const paymentMethod = [
		{
			value: 'external',
			label: t('addBooking.form.payment.method.options.external')
		},
		{
			value: 'korta',
			label: t('addBooking.form.payment.method.options.korta')
		},
		{
			value: 'rapyd',
			label: t('addBooking.form.payment.method.options.rapyd')
		},
		{
			value: 'netgiro',
			label: t('addBooking.form.payment.method.options.netgiro')
		}
	]

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('addBooking.title')} />
			</HeaderWrapper>
			<Wrapper>
				<Form
					submitText={t('addBooking.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={addBookingLoading}>
					<FormSubtitle title={t('addBooking.form.info.title')} hasBorder={false} />
					<Row>
						<Controller
							name="user"
							control={control}
							render={({ field }) => (
								<UserSelect
									label={t('addBooking.form.user.label')}
									placeholder={t('addBooking.form.user.placeholder')}
									error={(errors as any).user?.value}
									required={true}
									{...field}
									onChange={(data: any) => {
										handleSelectChange({
											id: 'user',
											value: data?.value,
											label: data?.label
										})
									}}
								/>
							)}
						/>

						<Controller
							name="rental"
							control={control}
							render={({ field }) => (
								<Select
									id="rental"
									placeholder={t('addBooking.form.rental.placeholder')}
									label={t('addBooking.form.rental.label')}
									required={true}
									options={rentals?.pages
										.reduce((total: any, page: any) => [...total, ...page.results], [])
										.map((rental: any) => ({
											label: `${rental.booth_type_name?.name ?? 'None'} - ${rental.duration} days`,
											value: rental.id
										}))}
									{...field}
									error={(errors as any).rental?.value}
									handleInfiniteScroll={fetchNextRentals}
									onChange={(data: any) => {
										handleSelectChange({
											id: 'rental',
											value: data?.value,
											label: data?.label
										})
									}}
								/>
							)}
						/>
					</Row>
					<FormSubtitle title={t('addBooking.form.period.title')} hasBorder={false} />
					<Row>
						<Controller
							control={control}
							name="from"
							render={({ field }) => (
								<DatePickerInput
									label={t('addBooking.form.from')}
									onChange={field.onChange}
									selected={field.value}
									minDate={new Date()}
								/>
							)}
						/>
						<Controller
							control={control}
							name="to"
							render={({ field }) => (
								<DatePickerInput
									label={t('addBooking.form.to')}
									onChange={field.onChange}
									selected={field.value}
									minDate={watchFrom ? addDays(new Date(watchFrom), 1) : new Date()}
								/>
							)}
						/>
					</Row>
					<FormSubtitle title={t('addBooking.form.rental.title')} hasBorder={false} />
					<Row>
						<Controller
							name="booth"
							control={control}
							render={({ field }) => (
								<Select
									id="booth"
									placeholder={t('addBooking.form.booth.placeholder')}
									label={t('addBooking.form.booth.label')}
									required={true}
									options={booths?.pages
										.reduce((total: any, page: any) => [...total, ...page.results], [])
										.map((booth: any) => ({
											label: `${booth.booth_type_name} #${booth.number}`,
											value: booth.id
										}))}
									{...field}
									error={(errors as any).booth?.value}
									handleInfiniteScroll={fetchNextBooths}
									onChange={(data: any) => {
										handleSelectChange({
											id: 'booth',
											value: data?.value,
											label: data?.label
										})
									}}
								/>
							)}
						/>
						<Input
							id="commission"
							type="number"
							step="0.01"
							{...register('commission')}
							label={t('addBooking.form.commission')}
						/>
					</Row>
					<FormSubtitle title={t('addBooking.form.payment.title')} hasBorder={false} />
					<Row>
						<Controller
							name="payment.status"
							control={control}
							render={({ field }) => (
								<Select
									id="payment.status"
									placeholder={t('addBooking.form.payment.status.placeholder')}
									label={t('addBooking.form.payment.status.label')}
									options={paymentStatus?.map((status: any) => ({
										label: status.label,
										value: status.value
									}))}
									{...field}
								/>
							)}
						/>
						<Controller
							name="payment.method"
							control={control}
							render={({ field }) => (
								<Select
									id="payment.method"
									placeholder={t('addBooking.form.payment.method.placeholder')}
									label={t('addBooking.form.payment.method.label')}
									options={paymentMethod?.map((method: any) => ({
										label: method.label,
										value: method.value
									}))}
									{...field}
								/>
							)}
						/>
					</Row>
					<FormSubtitle title={t('addBooking.form.notes.title')} hasBorder={false} />
					<Input
						id="store_notes"
						{...register('store_notes')}
						label={t('addBooking.form.notes.label')}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default BookingsAddPage
