import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const BoothHistory: FC = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: boothHIstory, isLoading } = useFetchData({
		name: 'fetchBoothHistory',
		endpoint: `/booths/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('boothHistory.title')} />
			<TableWrapper>
				<HistoryTable results={boothHIstory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default BoothHistory
