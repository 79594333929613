import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const RentalHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: rentalHistory, isLoading } = useFetchData({
		name: 'fetchRentalHistory',
		endpoint: `/rentals/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('rentalHistory.title')} />
			<TableWrapper>
				<HistoryTable results={rentalHistory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default RentalHistoryPage
