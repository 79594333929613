import { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import Status from 'components/Status'
import { Currency } from 'components/Ui'
import TableImage from 'components/Ui/Table/Image'
import { EllipsisText } from 'components/Ui'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { useAxiosPrivate } from 'hooks'
import {
	Wrapper,
	ImageElement,
	Info,
	InfoDetails,
	Name,
	SoldMobile,
	SoldCountMobile,
	Price,
	PriceDetails
} from './styles'
import { ProductProps } from './types'
import { useDevice } from 'hooks'
import { useToast } from 'hooks'
import { useUserRoles } from 'hooks'
import { StoreSettingsContext } from 'store/StoreSettingsContext'

export const Product = ({
	id,
	name,
	booking,
	price,
	quantity,
	category,
	image_thumbnail,
	published,
	owner,
	sum_sold_quantity,
	openDeleteModal,
	openPrintModal
}: ProductProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const privateAxios = useAxiosPrivate()
	const { isMobileOrTablet } = useDevice()
	const [searchParams] = useSearchParams()
	const storeCtx = useContext(StoreSettingsContext)

	const productStatusUpdateSuccessMsg = useToast({
		type: 'success',
		message: t('product.status.success')
	})
	const productStatusUpdateFailMsg = useToast({
		type: 'error',
		message: t('product.status.error')
	})

	const { mutate: updateProductStatusMutation } = useMutation(
		() => {
			return privateAxios.patch(`/products/${id}/`, { published: !published })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchProducts')
				productStatusUpdateSuccessMsg()
			},
			onError: () => {
				productStatusUpdateFailMsg()
			}
		}
	)

	const redirectToEditPage = () =>
		navigate(
			searchParams.toString() ? `/products/${id}?${searchParams.toString()}` : `/products/${id}`
		)

	const { isStaff } = useUserRoles()

	return (
		<Wrapper>
			<ImageElement>
				<TableImage src={image_thumbnail} count={quantity} />
				<Info>
					<InfoDetails>
						<EllipsisText>
							<Name
								to={
									searchParams.toString()
										? `/products/${id}?${searchParams.toString()}`
										: `/products/${id}`
								}>
								{name}
							</Name>
						</EllipsisText>
						<span>{owner?.name || owner?.email}</span>
						<span>{category?.id && category.name}</span>
						<div>&nbsp;</div>
						{booking?.booth && (
							<div>
								#{booking.booth.number} {booking.booth.booth_type.name}
							</div>
						)}
						{booking?.reference && (
							<div>
								(#{booking.reference} {booking.start_date})
							</div>
						)}
					</InfoDetails>
					{sum_sold_quantity > 0 ? (
						<SoldMobile>
							<span>{t('products.product.sold')}</span>{' '}
							<SoldCountMobile>{sum_sold_quantity}</SoldCountMobile>
						</SoldMobile>
					) : null}
				</Info>
			</ImageElement>
			<Price>
				<Currency price={price} /> <PriceDetails>{t('products.product.perItem')}</PriceDetails>
			</Price>
			<Status status={published} />
			<EllipsisMenu
				options={[
					{
						label: t('products.product.edit'),
						action: redirectToEditPage
					},
					{
						label: t('products.product.print'),
						action: () => openPrintModal(id)
					},
					{
						label: published ? t('products.product.archive') : t('products.product.publish'),
						action: updateProductStatusMutation,
						borderTop: !isMobileOrTablet,
						borderBottom: !isMobileOrTablet,
						disabled: !(
							published ||
							isStaff ||
							(storeCtx?.storeSettings?.users_can_publish_products &&
								(!storeCtx?.storeSettings.photos_required_to_publish ||
									(storeCtx?.storeSettings.photos_required_to_publish && !!image_thumbnail)))
						)
					},
					{
						label: t('products.product.delete'),
						action: () => openDeleteModal(id)
					}
				]}
			/>
		</Wrapper>
	)
}
