import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Currency } from 'components/Ui'
import TableImage from 'components/Ui/Table/Image'
import { EllipsisText } from 'components/Ui'
import { EllipsisMenuItem } from 'interfaces/ellipsisMenu'
import { ISale } from './types'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import {
	Wrapper,
	ImageElement,
	Info,
	InfoDetails,
	Name,
	InfoMobile,
	InfoMobileData,
	Date,
	Total,
	PriceDetails
} from './styles'
import { useUserRoles } from 'hooks'

export const Sale = ({
	id,
	account_email,
	account_name,
	product_name,
	product_unit_price,
	total,
	booking,
	quantity,
	category,
	product_image,
	discount,
	commission_percentage,
	created
}: ISale) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { isUser } = useUserRoles()

	const ellipsisMenuOptions: EllipsisMenuItem[] = [
		{
			label: t('sales.ellipsisMenu.edit'),
			action: () => navigate(`/sales/${id}`)
		}
	]

	return (
		<Wrapper>
			<ImageElement>
				<TableImage src={product_image} count={quantity} />

				<Info>
					<InfoDetails>
						<EllipsisText>
							<Name to={`/sales/${id}`}>{product_name}</Name>
						</EllipsisText>
						{!isUser && (
							<span>
								{account_name} ({account_email})
							</span>
						)}
						<span>{category?.id && category.name}</span>
						<div>&nbsp;</div>
						{!!booking?.booth?.number && (
							<div>
								#{booking.booth.number} {booking.booth.booth_type.name}
							</div>
						)}
						{booking?.reference && (
							<div>
								(#{booking.reference} {booking.start_date})
							</div>
						)}
					</InfoDetails>
					<InfoMobile>
						<InfoMobileData>
							<Currency price={product_unit_price} /> {quantity && `x ${quantity}`}
						</InfoMobileData>
						<InfoMobileData>
							<Currency price={discount} /> {t('sales.sale.discount')}
						</InfoMobileData>
						<InfoMobileData>
							<span>{+commission_percentage}%</span> {t('sales.sale.commission')}
						</InfoMobileData>
					</InfoMobile>
				</Info>
			</ImageElement>
			<Date to={`/sales/${id}`}>{format(parseISO(created), 'yy-MM-dd')}</Date>
			<Total>
				<Currency price={total} />
				<PriceDetails>{t('sales.sale.total')}</PriceDetails>
			</Total>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}
