import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Link } from 'react-router-dom'
// import { FC } from 'react'
// import { useTranslation } from 'react-i18next'

export const ModalItem = styled.div`
	padding: 0.5rem 10px;
	color: ${theme`colors.boulder2`};

	&:nth-of-type(n + 2) {
		border-top: 1px solid ${theme`colors.iron`};
	}
`

export const ModalContent = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	overflow: auto;
	max-height: calc(50vh - 100px);

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: row;
		justify-content: space-between;
	}

	& > div {
		flex: 1;
	}
`

export const CalendarNav = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 2rem;
`

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	gap: 1rem;

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: row;
	}
`

export const Label = styled(Link)`
	font-weight: 700;
	color: ${theme`colors.blueRibbon`};
`

export const ModalContentTitle = styled.h4<{ color?: string }>`
	padding: 0 10px;
	font-size: 1rem;
	font-weight: 700;
	color: ${({ color }) => (color ? color : 'inherit')};
	margin-bottom: 0.5rem;
`

export const ModalItemHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const StatusWrapper = styled.div<{
	status: boolean
	deactivatedColor: string
	activeColor: string
}>`
	padding: 2px 4px;
	border-radius: 7px;
	grid-column-start: 2;
	// background-color: ${({ status }) => (status ? '#36B059' : theme`colors.copper`)};
	background-color: ${({ status, activeColor, deactivatedColor }) =>
		status ? activeColor : deactivatedColor};
	text-transform: uppercase;
	color: #fff;
	justify-self: end;
	font-size: 0.75rem;
	grid-column-start: auto;
	border-radius: 0.375rem;
	padding: 0.25rem 0.5rem;
	gap: 0.25rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	justify-self: center;
	border: 1px solid #7c7c7c;
	background-color: transparent;
	color: #656565;
`

export const StatusIndicator = styled.div<{
	status: boolean
	deactivatedColor: string
	activeColor: string
}>`
	display: block;
	width: 8px;
	height: 8px;
	background-color: ${({ status, activeColor, deactivatedColor }) =>
		status ? activeColor : deactivatedColor};
`

export interface IStatus {
	status: boolean
	text?: string
	activeColor?: string
	deactivatedColor?: string
	className?: string
}

export const Status = ({
	status,
	text,
	activeColor = '#12CD0E',
	deactivatedColor = '#B07136',
	className
}: IStatus) => (
	<StatusWrapper
		status={status}
		className={className}
		activeColor={activeColor}
		deactivatedColor={deactivatedColor}>
		<StatusIndicator
			status={status}
			activeColor={activeColor}
			deactivatedColor={deactivatedColor}
		/>
		{text}
	</StatusWrapper>
)

export const CalendarStatus = styled(Status)`
	height: 18px;
	cursor: pointer;
	margin-bottom: 6px;
`

export const modalCustomStyles = {
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		zIndex: '100',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	content: {
		position: 'relative',
		maxHeight: '50vh',
		width: 'calc(100vw - 2rem)',
		maxWidth: '590px',
		top: '0',
		left: '0',
		overflow: 'hidden',
		borderRadius: '12px'
	}
}

export const ModalDates = styled.div`
	font-size: 0.75rem;
	font-weight: 600;
`
