import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
	Wrapper,
	BookingText,
	Dates,
	Booth,
	ProductDaysRemainingMobile,
	ProductsRemaining,
	ProductsRemainingCount,
	ProductsRemainingMobile,
	Header,
	DateText
} from './styles'
import type { BookingProps } from './types'
import { EllipsisMenuItem } from 'interfaces/ellipsisMenu'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { useUserRoles } from 'hooks'
import { Currency } from 'components/Ui'

const Booking = ({
	is_active,
	booth,
	id,
	start_date,
	end_date,
	remaining_products,
	user,
	rental,
	total_value,
	type,
	handleSmsReminder,
	handleEmailWelcome,
	handleEmailReminder
}: BookingProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const userRoles = useUserRoles()
	const [searchParams] = useSearchParams()

	const ellipsisMenuOptions: EllipsisMenuItem[] = [
		{
			label: t('bookings.ellipsisMenu.edit'),
			action: () =>
				navigate(
					searchParams.toString() ? `/bookings/${id}?${searchParams.toString()}` : `/bookings/${id}`
				)
		},
		...(userRoles.isStaff
			? [
					{
						label: t('bookings.filters.actions.options.emailWelcome'),
						action: () => handleEmailWelcome(id)
					},
					{
						label: t('bookings.filters.actions.options.emailReminder'),
						action: () => handleEmailReminder(id)
					},
					{
						label: t('bookings.filters.actions.options.smsReminder'),
						action: () => handleSmsReminder(id)
					}
			  ]
			: [])
	]

	return (
		<Wrapper>
			<Header>
				<BookingText>{user?.name || user?.email}</BookingText>
				{rental?.duration && (
					<span>{`${rental.duration} ${t('booking.days')} ${type ? type : ''}`}</span>
				)}
			</Header>
			{booth?.number && (
				<Booth>{booth?.number && `#${booth?.number} ${booth.booth_type.name}`}</Booth>
			)}
			<Dates>
				<DateText>
					<span>{`${t('booking.start')}`}</span>{' '}
					<strong>{format(new Date(start_date), 'dd.MM.yyyy')}</strong>
				</DateText>
				<DateText>
					<span>{`${t('booking.end')}`}</span>{' '}
					<strong>{format(new Date(end_date), 'dd.MM.yyyy')}</strong>
				</DateText>
			</Dates>
			<ProductsRemaining expired={!is_active}>
				<ProductsRemainingCount>{remaining_products}</ProductsRemainingCount>{' '}
				<ProductsRemainingMobile>{t('booking.productsRemaining')}</ProductsRemainingMobile>
				<div style={{ marginTop: 10 }}>
					<Currency price={total_value} /> {t('booking.productsTotalValue')}
				</div>
			</ProductsRemaining>
			<ProductDaysRemainingMobile expired={!is_active} />
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default Booking
