import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'
import { TableWrapper } from 'styles/general'

const ProductHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: productHistory, isLoading } = useFetchData({
		name: 'fetchProductHistory',
		endpoint: `/products/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('productHistory.title')} />
			<TableWrapper>
				<HistoryTable results={productHistory?.data} isLoading={isLoading} />
			</TableWrapper>
		</>
	)
}

export default ProductHistoryPage
