import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Currency } from 'components/Ui'
import { InfoHeader, SalesTableWrapper } from './styles'
import { customTableStyles } from 'styles/general'
import { format, parseISO } from 'date-fns'
import { useFetchData } from 'hooks'
import { Spinner } from 'components/Ui/Spinner'
import { ImageElement, InfoDetails, Name } from 'components/Product'
import { Sku, StyledTableImage } from 'pages/products/styles'
import { InfoHeaderContainer, InfoHeaderLink } from 'pages/booking'
// import {
// 	Header as TableHeader,
// 	List as TableList,
// 	Container as TableContainer
// } from 'components/Ui/Table'

export interface BookingSale {
	id: string
	created: string
	receipt_reference: string
	product_name: string
}

export interface UserSalesTableProps {
	account_id?: string
}

export const UserSalesTable = ({ account_id: account_id }: UserSalesTableProps) => {
	const { t } = useTranslation()
	const [salesData, setSalesData] = useState({ nodes: [] })

	const { data: sales, isFetching: salesLoading } = useFetchData({
		name: 'fetchSales',
		query: `account_id=${account_id}`,
		endpoint: '/sales/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setSalesData({ nodes: results })
			}
		}
	})
	if (salesData?.nodes?.length == 0) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Sales</InfoHeader>
				<div>No sales yet</div>
			</>
		)
	}

	const tableHeaders = [
		{
			id: 'sales-created',
			label: 'Created'
		},
		{
			id: 'sales-receipt-reference',
			label: 'Receipt reference'
		},
		{
			id: 'sales-product-name',
			label: 'Name'
		},
		{
			id: 'sales-product-sku',
			label: 'SKU'
		},
		{
			id: 'sales-unit-price',
			label: 'Unit price'
		},
		{
			id: 'sales-quantity',
			label: 'Qty'
		},
		{
			id: 'sales-discount',
			label: 'Discount'
		},
		{
			id: 'sales-subtotal',
			label: 'Subtotal'
		},
		{
			id: 'sales-commission-percentage',
			label: 'Commissoin %'
		},
		{
			id: 'sales-created',
			label: 'Commission'
		}
	]

	const columns: Column[] = [
		{
			label: t('products.tableHeaders.product'),
			renderCell: (item: any) => (
				<ImageElement>
					<StyledTableImage src={item.image_thumbnail} />
					<InfoDetails>
						<Name to={`/sales/${item.id}`}>{item.product_name}</Name>
						<Sku>{item.product_sku}</Sku>
					</InfoDetails>
				</ImageElement>
			),

			select: true,
			pinLeft: true
		},
		{
			label: t('sales.tableHeaders.date'),
			renderCell: (item: any) => (
				<div>
					<div>{format(parseISO(item.created), 'yyyy-MM-dd')}</div>
					<div>{format(parseISO(item.created), 'H:m:s')}</div>
				</div>
			)
		},
		{
			label: t('sales.tableHeaders.reference'),
			renderCell: (item: any) => <>{item.receipt_reference}</>
		},
		{
			label: t('sales.tableHeaders.price'),
			renderCell: (item: any) => <Currency price={item.product_unit_price}></Currency>
		},
		{
			label: t('sales.tableHeaders.quantity'),
			renderCell: (item: any) => <>{item.quantity}</>
		},
		{
			label: t('sales.tableHeaders.discount'),
			renderCell: (item: any) => <Currency price={item.discount}></Currency>
		},
		{
			label: 'Subtotal',
			renderCell: (item: any) => <Currency price={item.subtotal}></Currency>
		},
		{
			label: t('sales.tableHeaders.commission'),
			renderCell: (item: any) => <div>{item.commission_percentage}%</div>
		}
		// {
		// 	label: t('sales.tableHeaders.commissionTotal'),
		// 	renderCell: (item: any) => <Currency price={item.commission_amount}></Currency>
		// }
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 165px repeat(${columns.length - 1}, 1.5fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Sales</InfoHeader>
				{!!salesData?.nodes?.length && (
					<InfoHeaderLink to={'/sales?account_id=' + account_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				{salesLoading && <Spinner></Spinner>}
				{salesData.nodes != null && Array.isArray(salesData?.nodes) && salesData?.nodes.length ? (
					<CompactTable
						columns={columns}
						data={salesData}
						theme={theme}
						isLoading={salesLoading}
						layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
				) : (
					<></>
				)}
			</SalesTableWrapper>
		</>
	)
}
