import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { theme } from 'twin.macro'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { size } from 'lodash'

import Header from 'components/Header'
import Input from 'components/Ui/Input'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import Select from 'components/Ui/Select'
import PageWrapper from 'components/Ui/PageWrapper'
import PriceInput from 'components/PriceInput'
import { Settings } from './types'
import { useFetchData } from 'hooks'
import ColorPicker from 'components/ColorPicker'
import { StyledForm, Row, StyledButton, StyledSwitch, SubSwitch, Legend, Fieldset } from './styles'
import FileUploader from 'components/FileUploader'
// import { storeSettingsValidationSchema } from 'schemas/validation'
import useSelect from 'hooks/useSelect'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { useUserRoles } from 'hooks'
import { Country } from 'react-phone-number-input'
import PhoneNumberInput from 'components/Ui/PhoneNumberInput'
import { AuthContext } from 'store/AuthContext'
import Textarea from 'components/Ui/Textarea'
import { getBase64FromUrl, dataURLtoFile } from './utils'

const DEFAULT_MAX_LETTER_COUNT = 130

const SettingsPage = () => {
	const { t } = useTranslation()
	const tabs = [
		{ label: t('store.tabs.account'), value: 'account' },
		{ label: t('store.tabs.legal'), value: 'legal' },
		{ label: t('store.tabs.social'), value: 'social' },
		{ label: t('store.tabs.payments'), value: 'payments' },
		{ label: t('store.tabs.commissions'), value: 'commissions' },
		{ label: t('store.tabs.productRegistration'), value: 'productRegistration' }
	]
	const storeCtx = useContext(StoreSettingsContext)
	const axiosPrivate = useAxiosPrivate()
	const defaultColor = theme`colors.clementine` || '#EF6C00'
	const [accentColor, setAccentColor] = useState(defaultColor)
	const storeSettingsValidationSchema = yup.object({
		name: yup.string().required(t('validation.store.name.required')),
		address: yup.object({
			address: yup.string().required(t('validation.store.address.address.required')),
			postal_code: yup.string().required(t('validation.store.address.postalCode.required')),
			city: yup.string().required(t('validation.store.address.city.required'))
		}),
		legal_address: yup.object({
			address: yup.string().required(t('validation.store.legalAddress.address.required')),
			postal_code: yup.string().required(t('validation.store.legalAddress.postalCode.required')),
			city: yup.string().required(t('validation.store.legalAddress.city.required'))
		}),
		country: yup.object({
			value: yup.string().required(t('validation.store.country.value.required'))
		}),
		legal_country: yup.object({
			value: yup.string().required(t('validation.store.legalCountry.value.required'))
		}),
		start_reminder_sms_template: yup
			.string()
			.test(
				'wordCount',
				t('validation.store.sms.max', { count: DEFAULT_MAX_LETTER_COUNT }),
				(value) => size(value) <= DEFAULT_MAX_LETTER_COUNT
			)
			.required(t('validation.store.sms.required')),
		end_reminder_sms_template: yup
			.string()
			.test(
				'wordCount',
				t('validation.store.sms.max', { count: DEFAULT_MAX_LETTER_COUNT }),
				(value) => size(value) <= DEFAULT_MAX_LETTER_COUNT
			)
			.required(t('validation.store.sms.required'))
	})
	const {
		register,
		setValue,
		handleSubmit,
		control,
		trigger,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(storeSettingsValidationSchema)
	})
	const { handleSelectChange } = useSelect({ setValue, trigger })
	const watchProductPublish = watch('users_can_publish_products')
	const { isSuperuser } = useUserRoles()
	const { auth } = useContext(AuthContext)

	const {
		register: registerNewCommission,
		control: controlNewCommission,
		getValues: getCommissionsValues
	} = useForm()

	const { register: registerCommission, setValue: setCommissionValue } = useForm()

	const successMessage = useToast({ type: 'success', message: t('store.form.success') })
	const failMessage = useToast({ type: 'error', message: t('store.form.error') })

	const successRemoveCommissionMessage = useToast({
		type: 'success',
		message: t('store.form.commission.removeMutation.success')
	})
	const failRemoveCommissionMessage = useToast({
		type: 'error',
		message: t('store.form.commission.removeMutation.error')
	})

	const successAddCommissionMessage = useToast({
		type: 'success',
		message: t('store.form.commission.addMutation.success')
	})
	const failAddCommissionMessage = useToast({
		type: 'error',
		message: t('store.form.commission.addMutation.error')
	})

	const changePrimaryColorSuccessMsg = useToast({
		type: 'success',
		message: t('store.form.primaryColor.success')
	})
	const changePrimaryColorFailMsg = useToast({
		type: 'error',
		message: t('store.form.primaryColor.error')
	})

	const [activeTab, setActiveTab] = useState(tabs[0].value)

	const { data: commissions, refetch: refetchCommissions } = useFetchData({
		name: 'fetchCommissions',
		endpoint: '/settings/commission-rates/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const fetchCountries = () =>
		axiosPrivate
			.options('/settings/')
			.then((res) => res?.data?.actions?.PUT?.address?.children?.country?.choices)

	const fetchLanguages = () =>
		axiosPrivate
			.options('/settings/')
			.then((res) => res?.data?.actions?.PUT?.default_language?.choices)

	const fetchCurrencies = () =>
		axiosPrivate.options('/settings/').then((res) => res?.data?.actions?.PUT?.currency?.choices)

	const { data: countries, isLoading: loadingCountries } = useQuery(
		'fetchCountries',
		fetchCountries
	)
	const { data: languages, isLoading: loadingLanguages } = useQuery(
		'fetchLanguages',
		fetchLanguages
	)
	const { data: currencies, isLoading: loadingCurrencies } = useQuery(
		'fetchCurrencies',
		fetchCurrencies
	)

	useEffect(() => {
		const fields = [
			'name',
			'website_url',
			'phone',
			'email',
			'default_from_email',
			'legal_name',
			'legal_id',
			'facebook_url',
			'twitter_url',
			'instagram_url',
			'pinterest_url',
			'linkedin_url',
			'payment_receipt_url',
			'payment_cancel_url',
			'rapyd_access_key',
			'rapyd_secret_key',
			'rapyd_sandbox',
			'quantity_enabled',
			'categories_enabled',
			'color_enabled',
			'tags_enabled',
			'coupons_enabled',
			'photos_required_to_publish',
			'cost_price_enabled',
			'users_can_publish_products',
			'netgiro_app_id',
			'netgiro_secret_key',
			'netgiro_sandbox',
			'default_product_publish_value',
			'sendgrid_end_reminder_template_id',
			'sendgrid_receipt_template_id',
			'sendgrid_welcome_template_id',
			'start_reminder_sms_template',
			'end_reminder_sms_template',
			'sendgrid_payout_method_reminder_template_id'
		]

		fields.forEach((field) => setValue(field, storeCtx?.storeSettings?.[field]))

		setValue('address.address', storeCtx?.storeSettings?.address?.address)
		setValue('address.postal_code', storeCtx?.storeSettings?.address?.postal_code)
		setValue('address.city', storeCtx?.storeSettings?.address?.city)
		setValue('legal_address.address', storeCtx?.storeSettings?.legal_address?.address)
		setValue('legal_address.postal_code', storeCtx?.storeSettings?.legal_address?.postal_code)
		setValue('legal_address.city', storeCtx?.storeSettings?.legal_address?.city)

		setAccentColor(storeCtx?.primaryColor || defaultColor)

		if (storeCtx?.storeSettings?.logo) {
			fetch(storeCtx?.storeSettings?.logo).then((res) => {
				const name = res.url.split('/').pop()

				getBase64FromUrl(res.url).then((res: string) => setValue('logo', dataURLtoFile(res, name!)))
			})
		}
	}, [storeCtx?.storeSettings])

	useEffect(() => {
		setCommissionValue('commissions', commissions?.data?.results)
	}, [commissions?.data])

	useEffect(() => {
		if (
			countries &&
			(storeCtx?.storeSettings?.address?.country || storeCtx?.storeSettings?.legal_address?.country)
		) {
			setValue('country', {
				value: storeCtx?.storeSettings?.address?.country,
				label: countries.find(
					(country: any) => country.value === storeCtx?.storeSettings?.address?.country
				).display_name
			})

			setValue('legal_country', {
				value: storeCtx?.storeSettings?.legal_address?.country,
				label: countries.find(
					(country: any) => country.value === storeCtx?.storeSettings?.legal_address?.country
				).display_name
			})
		}
	}, [
		countries,
		storeCtx?.storeSettings?.address?.country,
		storeCtx?.storeSettings?.legal_address?.country
	])

	useEffect(() => {
		if (currencies && storeCtx?.storeSettings?.currency) {
			setValue('currency', {
				value: storeCtx?.storeSettings?.currency,
				label: currencies.find(
					(currency: any) => currency.value === storeCtx?.storeSettings?.currency
				).display_name
			})
		}
	}, [currencies, storeCtx?.storeSettings?.currency])

	useEffect(() => {
		if (languages && storeCtx?.storeSettings?.default_language) {
			setValue('default_language', {
				value: storeCtx?.storeSettings?.default_language,
				label: languages.find(
					(language: any) => language.value === storeCtx?.storeSettings?.default_language
				).display_name
			})
		}
	}, [languages, storeCtx?.storeSettings?.default_language])

	const { mutate: updateStore, isLoading: updateStoreLoading } = useMutation(
		(data: any) => {
			return axiosPrivate.patch('/settings/', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
		},
		{
			onSuccess: (res) => {
				successMessage()
				storeCtx?.setStoreSettings(res.data)
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const { mutate: removeCommissionMutation, isLoading: removeCommissionIsLoading } = useMutation(
		(id: number) => {
			return axiosPrivate.delete(`/settings/commission-rates/${id}/`)
		},
		{
			onSuccess: () => {
				refetchCommissions()
				successRemoveCommissionMessage()
			},
			onError: () => {
				failRemoveCommissionMessage()
			}
		}
	)

	const { mutate: addCommissionMutation, isLoading: addCommissionIsLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/settings/commission-rates/', data)
		},
		{
			onSuccess: () => {
				refetchCommissions().then(() => removeNewCommission())
				successAddCommissionMessage()
			},
			onError: () => {
				failAddCommissionMessage()
			}
		}
	)

	const { mutate: changePrimaryColorMutation } = useMutation(
		(data: any) => {
			return axiosPrivate.put('/settings/widget/', data)
		},
		{
			onSuccess: (res) => {
				storeCtx?.setPrimaryColor(res.data.primary_color)
				changePrimaryColorSuccessMsg()
			},
			onError: () => {
				changePrimaryColorFailMsg()
			}
		}
	)

	const onSubmit = (data: any) => {
		const logo = data.logo ?? []

		const formData = new FormData()
		formData.append('address.address', data.address.address)
		formData.append('address.postal_code', data.address.postal_code)
		formData.append('address.city', data.address?.city)
		formData.append('address.country', data.country?.value)

		formData.append('legal_address.address', data.legal_address.address)
		formData.append('legal_address.postal_code', data.legal_address.postal_code)
		formData.append('legal_address.city', data.legal_address?.city)
		formData.append('legal_address.country', data.legal_country?.value)

		formData.append('name', data.name)
		formData.append('payment_cancel_url', data.payment_cancel_url)
		formData.append('payment_receipt_url', data.payment_receipt_url)
		formData.append('currency', data.currency?.value)

		formData.append('phone', data.phone)
		formData.append('pinterest_url', data.pinterest_url)
		formData.append('rapyd_access_key', data.rapyd_access_key)
		formData.append('rapyd_secret_key', data.rapyd_secret_key)
		formData.append('rapyd_sandbox', data.rapyd_sandbox)
		formData.append('twitter_url', data.twitter_url)
		formData.append('website_url', data.website_url)
		formData.append('default_from_email', data.default_from_email)
		formData.append('email', data.email)
		formData.append('facebook_url', data.facebook_url)
		formData.append('instagram_url', data.instagram_url)
		formData.append('legal_id', data.legal_id)
		formData.append('legal_name', data.legal_name)
		formData.append('linkedin_url', data.linkedin_url)
		formData.append('quantity_enabled', data.quantity_enabled)
		formData.append('cost_price_enabled', data.cost_price_enabled)
		formData.append('categories_enabled', data.categories_enabled)
		formData.append('photos_required_to_publish', data.photos_required_to_publish)
		formData.append('tags_enabled', data.tags_enabled)
		formData.append('color_enabled', data.color_enabled)
		formData.append('users_can_publish_products', data.users_can_publish_products)
		formData.append('default_product_publish_value', data.default_product_publish_value)
		formData.append('coupons_enabled', data.coupons_enabled)
		if (isSuperuser) {
			formData.append('netgiro_app_id', data.netgiro_app_id)
			formData.append('netgiro_secret_key', data.netgiro_secret_key)
			formData.append('netgiro_sandbox', data.netgiro_sandbox)

			formData.append('sendgrid_end_reminder_template_id', data.sendgrid_end_reminder_template_id)
			formData.append(
				'sendgrid_start_reminder_template_id',
				data.sendgrid_start_reminder_template_id
			)
			formData.append('sendgrid_receipt_template_id', data.sendgrid_receipt_template_id)
			formData.append('sendgrid_welcome_template_id', data.sendgrid_welcome_template_id)
			formData.append(
				'sendgrid_payout_method_reminder_template_id',
				data.sendgrid_payout_method_reminder_template_id
			)
			formData.append('start_reminder_sms_template', data.start_reminder_sms_template)
			formData.append('end_reminder_sms_template', data.end_reminder_sms_template)
		} else {
			formData.append(
				'sendgrid_end_reminder_template_id',
				storeCtx?.storeSettings.sendgrid_end_reminder_template_id
			)
			formData.append(
				'sendgrid_start_reminder_template_id',
				storeCtx?.storeSettings.sendgrid_start_reminder_template_id
			)
			formData.append(
				'sendgrid_receipt_template_id',
				storeCtx?.storeSettings.sendgrid_receipt_template_id
			)
			formData.append(
				'sendgrid_welcome_template_id',
				storeCtx?.storeSettings.sendgrid_welcome_template_id
			)
			formData.append(
				'sendgrid_payout_method_reminder_template_id',
				storeCtx?.storeSettings.sendgrid_payout_method_reminder_template_id
			)
			formData.append(
				'start_reminder_sms_template',
				storeCtx?.storeSettings.start_reminder_sms_template
			)
			formData.append(
				'end_reminder_sms_template',
				storeCtx?.storeSettings.end_reminder_sms_template
			)
		}
		formData.append('default_language', data.default_language.value)

		formData.append('logo', logo)

		updateStore(formData)
		if (accentColor !== storeCtx?.primaryColor) {
			changePrimaryColorMutation({ name: data.name, primary_color: accentColor })
		}
	}

	const {
		fields,
		append: appendNewCommission,
		remove: removeNewCommission
	} = useFieldArray({
		name: 'newCommissions',
		control: controlNewCommission
	})

	const handleCommissionAddition = (index: number) => {
		const newCommission = getCommissionsValues(`newCommissions[${index}]`)

		addCommissionMutation(newCommission)
	}

	const handleAccentColorChange = (data: any) => {
		const { hex } = data

		setAccentColor(hex)
	}

	const handleImageChange = (data: any) => {
		setValue('logo', data[0])
	}

	const handleSwitchChange = (data: any) => {
		setValue(data.target.name, data.target.checked)

		!data.target.checked && setValue('default_product_publish_value', false)
	}

	return (
		<>
			<Header title={t('store.title')} />
			<PageWrapper>
				{activeTab !== 'commissions' ? (
					<StyledForm
						submitText={t('store.form.submitText')}
						onSubmit={handleSubmit(onSubmit)}
						setActiveTab={setActiveTab}
						activeTab={activeTab}
						isLoading={updateStoreLoading}
						tabs={tabs}
						hideBorderTop={true}>
						{activeTab === 'account' && (
							<>
								<Input
									id="name"
									{...register('name')}
									label={t('store.form.name')}
									error={(errors as any).name}
									required={true}
								/>
								<ColorPicker
									label={t('store.form.accentColor')}
									color={accentColor}
									onChange={handleAccentColorChange}
								/>
								<Input id="website" {...register('website_url')} label={t('store.form.website')} />
								{/* <Input id="phone" {...register('phone')} label={t('store.form.phone')} /> */}
								<PhoneNumberInput
									id="phone"
									control={control}
									defaultCountry={storeCtx?.storeSettings?.address?.country as Country}
									label={t('store.form.phone')}
									{...register('phone')}
									// error={(errors as any).phone_number}
								/>
								<Input
									id="email"
									{...register('email')}
									label={t('store.form.email')}
									type="email"
								/>
								<Input
									id="default_email"
									{...register('default_from_email')}
									label={t('store.form.default_email')}
									type="email"
								/>
								<Controller
									name="default_language"
									control={control}
									render={({ field }) => (
										<Select
											id="default_language"
											label={t('store.form.default_language')}
											options={languages?.map((option: any) => ({
												label: option.display_name,
												value: option.value
											}))}
											{...field}
											isLoading={loadingLanguages}
											error={(errors as any).default_language?.value}
											onChange={(data: any) =>
												handleSelectChange({
													id: 'default_language',
													value: data?.value,
													label: data?.label
												})
											}
										/>
									)}
								/>
								<Controller
									name="country"
									control={control}
									render={({ field }) => (
										<Select
											id="country"
											label={t('filters.country')}
											options={countries?.map((option: any) => ({
												label: option.display_name,
												value: option.value
											}))}
											{...field}
											isLoading={loadingCountries}
											error={(errors as any).country?.value}
											required={true}
											onChange={(data: any) =>
												handleSelectChange({
													id: 'country',
													value: data?.value,
													label: data?.label
												})
											}
										/>
									)}
								/>
								<Input
									id="address"
									{...register('address.address')}
									label={t('store.form.address')}
									error={(errors as any).address?.address}
									required={true}
								/>
								<Row>
									<Input
										id="city"
										{...register('address.city')}
										label={t('store.form.city')}
										error={(errors as any).address?.city}
										required={true}
									/>
									<Input
										id="postal_code"
										{...register('address.postal_code')}
										label={t('store.form.postal')}
										error={(errors as any).address?.postal_code}
										required={true}
									/>
								</Row>
								<Controller
									name="logo"
									control={control}
									render={({ field }) => (
										<FileUploader
											label={t('store.form.logo')}
											{...field}
											onChange={handleImageChange}
										/>
									)}
								/>
							</>
						)}
						{activeTab === 'legal' && (
							<>
								<Input
									id="legal_name"
									{...register('legal_name')}
									label={t('store.form.legal_name')}
								/>
								<Input id="legal_id" {...register('legal_id')} label={t('store.form.legal_id')} />
								<Input
									id="legal_address"
									{...register('legal_address.address')}
									label={t('store.form.legal_address')}
									error={(errors as any).legal_address?.address}
									required={true}
								/>
								<Input
									id="legal_postal_code"
									{...register('legal_address.postal_code')}
									label={t('store.form.legal_postal_code')}
									error={(errors as any).legal_address?.postal_code}
									required={true}
								/>
								<Input
									id="legal_city"
									{...register('legal_address.city')}
									label={t('store.form.legal_city')}
									error={(errors as any).legal_address?.city}
									required={true}
								/>
								<Controller
									name="legal_country"
									control={control}
									render={({ field }) => (
										<Select
											id="legal_country"
											label={t('store.form.legal_country')}
											options={countries?.map((option: any) => ({
												label: option.display_name,
												value: option.value
											}))}
											{...field}
											isLoading={loadingCountries}
											error={(errors as any).legal_country?.value}
											required={true}
											onChange={(data: any) =>
												handleSelectChange({
													id: 'legal_country',
													value: data?.value,
													label: data?.label
												})
											}
										/>
									)}
								/>
							</>
						)}
						{activeTab === 'social' && (
							<>
								<Input
									id="facebook_url"
									{...register('facebook_url')}
									label={t('store.form.facebook_url')}
									type="url"
								/>
								<Input
									id="twitter_url"
									{...register('twitter_url')}
									label={t('store.form.twitter_url')}
									type="url"
								/>
								<Input
									id="instagram_url"
									{...register('instagram_url')}
									label={t('store.form.instagram_url')}
									type="url"
								/>
								<Input
									id="pinterest_url"
									{...register('pinterest_url')}
									label={t('store.form.pinterest_url')}
									type="url"
								/>
								<Input
									id="linkedin_url"
									{...register('linkedin_url')}
									label={t('store.form.linkedin_url')}
									type="url"
								/>
							</>
						)}
						{activeTab === 'payments' && (
							<>
								<Input
									id="payment_receipt_url"
									{...register('payment_receipt_url')}
									label={t('store.form.payment_receipt_url')}
									type="url"
								/>
								<Input
									id="payment_cancel_url"
									{...register('payment_cancel_url')}
									label={t('store.form.payment_cancel_url')}
									type="url"
								/>
								<StyledSwitch
									label={t('store.form.coupons_enabled')}
									{...register('coupons_enabled')}
								/>
								<Fieldset>
									<Legend>Rapyd</Legend>
									<Input
										id="rapyd_access_key"
										{...register('rapyd_access_key')}
										label={t('store.form.rapyd_access_key')}
										type="password"
									/>
									<Input
										id="rapyd_secret_key"
										{...register('rapyd_secret_key')}
										label={t('store.form.rapyd_secret_key')}
										type="password"
									/>
									<StyledSwitch
										label={t('store.form.rapyd_sandbox')}
										{...register('rapyd_sandbox')}
									/>
									<Controller
										name="currency"
										control={control}
										render={({ field }) => (
											<Select
												id="currency"
												label={t('store.form.currency')}
												options={currencies?.map((option: any) => ({
													label: option.display_name,
													value: option.value
												}))}
												{...field}
												isLoading={loadingCurrencies}
												error={(errors as any).currency?.value}
												onChange={(data: any) =>
													handleSelectChange({
														id: 'currency',
														value: data?.value,
														label: data?.label
													})
												}
											/>
										)}
									/>
								</Fieldset>
								{isSuperuser ? (
									<>
										<Fieldset>
											<Legend>Netgíro</Legend>
											<Input
												id="netgiro_app_id"
												{...register('netgiro_app_id')}
												label={t('store.form.netgiro_app_id')}
												type="text"
											/>
											<Input
												id="netgiro_secret_key"
												{...register('netgiro_secret_key')}
												label={t('store.form.netgiro_secret_key')}
												type="password"
											/>
											<StyledSwitch
												label={t('store.form.netgiro_sandbox')}
												{...register('netgiro_sandbox')}
											/>
										</Fieldset>
										<Fieldset>
											<Legend>Sendgrid template</Legend>
											<Input
												id="sendgrid_receipt_template_id"
												{...register('sendgrid_receipt_template_id')}
												label={t('store.form.sendgrid_receipt_template_id')}
											/>
											<Input
												id="sendgrid_end_reminder_template_id"
												{...register('sendgrid_end_reminder_template_id')}
												label={t('store.form.sendgrid_end_reminder_template_id')}
											/>
											<Input
												id="sendgrid_start_reminder_template_id"
												{...register('sendgrid_start_reminder_template_id')}
												label={t('store.form.sendgrid_start_reminder_template_id')}
											/>
											<Input
												id="sendgrid_welcome_template_id"
												{...register('sendgrid_welcome_template_id')}
												label={t('store.form.sendgrid_welcome_template_id')}
											/>
											<Input
												id="sendgrid_payout_method_reminder_template_id"
												{...register('sendgrid_payout_method_reminder_template_id')}
												label={t('store.form.sendgrid_payout_method_reminder_template_id')}
											/>
										</Fieldset>
										<Fieldset>
											<Legend>SMS template</Legend>
											<Textarea
												id="start_reminder_sms_template"
												letters={size(watch('start_reminder_sms_template'))}
												maxLetterCount={DEFAULT_MAX_LETTER_COUNT}
												label={t('store.form.start_reminder_sms_template')}
												{...register('start_reminder_sms_template')}
												error={(errors as any).start_reminder_sms_template}
												required={true}
											/>
											<Textarea
												id="end_reminder_sms_template"
												letters={size(watch('end_reminder_sms_template'))}
												maxLetterCount={DEFAULT_MAX_LETTER_COUNT}
												label={t('store.form.end_reminder_sms_template')}
												{...register('end_reminder_sms_template')}
												error={(errors as any).end_reminder_sms_template}
												required={true}
											/>
										</Fieldset>
									</>
								) : null}
							</>
						)}
						{activeTab === 'productRegistration' && (
							<>
								<FormSubtitle title={t('store.tabs.productRegistration')} />
								<StyledSwitch
									label={t('store.form.registration.publishProduct.label')}
									description={t('store.form.registration.publishProduct.description')}
									{...register('users_can_publish_products')}
									onChange={handleSwitchChange}
								/>
								<SubSwitch>
									<StyledSwitch
										label={t('store.form.registration.publishButton.label')}
										description={t('store.form.registration.publishButton.description')}
										{...register('default_product_publish_value')}
										disabled={!watchProductPublish}
									/>
								</SubSwitch>

								<StyledSwitch
									label={t('store.form.registration.quantity.label')}
									description={t('store.form.registration.quantity.description')}
									{...register('quantity_enabled')}
								/>
								<StyledSwitch
									label={t('store.form.registration.categories.label')}
									description={t('store.form.registration.categories.description')}
									{...register('categories_enabled')}
								/>
								<StyledSwitch
									label={t('store.form.registration.tags.label')}
									description={t('store.form.registration.tags.description')}
									{...register('tags_enabled')}
								/>
								<StyledSwitch
									label={t('store.form.registration.color.label')}
									description={t('store.form.registration.color.description')}
									{...register('color_enabled')}
								/>

								<StyledSwitch
									label={t('store.form.registration.price.label')}
									description={t('store.form.registration.price.description')}
									{...register('cost_price_enabled')}
								/>

								<StyledSwitch
									label={t('store.form.registration.photos_required_to_publish.label')}
									description={t('store.form.registration.photos_required_to_publish.description')}
									{...register('photos_required_to_publish')}
								/>
							</>
						)}
					</StyledForm>
				) : (
					<StyledForm
						setActiveTab={setActiveTab}
						activeTab={activeTab}
						tabs={tabs}
						hideBorderTop={true}>
						{commissions?.data?.results?.map((rate: Settings, index: number) => (
							<Row key={rate.id}>
								<PriceInput
									id="minimal_product_price"
									label={t('store.form.commission.minimalProductPrice.label')}
									{...registerCommission(`commissions[${index}].minimal_price`)}
									type="number"
									step="0.01"
								/>
								<Input
									id="commission_rate"
									label={t('store.form.commission.rates.label')}
									{...registerCommission(`commissions[${index}].rate`)}
									type="number"
									step="0.01"
								/>
								<StyledButton
									text={t('store.form.commission.remove')}
									onClick={() => removeCommissionMutation(rate.id)}
									isLoading={removeCommissionIsLoading}
								/>
							</Row>
						))}
						{fields?.map((item: any, index: number) => (
							<Row key={item.id}>
								<PriceInput
									id="minimal_product_price"
									{...registerNewCommission(`newCommissions[${index}].minimal_price`)}
									label={t('store.form.commission.minimalProductPrice.label')}
									type="number"
									step="0.01"
								/>
								<Input
									id="commission_rate"
									label={t('store.form.commission.rates.label')}
									{...registerNewCommission(`newCommissions[${index}].rate`)}
									type="number"
									step="0.01"
								/>
								<StyledButton
									text={t('store.form.commission.add')}
									isLoading={addCommissionIsLoading}
									onClick={() => handleCommissionAddition(index)}
								/>
							</Row>
						))}
						<StyledButton
							text={t('store.form.commission.cta')}
							onClick={() => appendNewCommission({ minimal_price: '', rate: '' })}
							isLoading={removeCommissionIsLoading}
							disabled={fields?.length > 0}
						/>
					</StyledForm>
				)}
			</PageWrapper>
		</>
	)
}

export default SettingsPage
