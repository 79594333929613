import { useTranslation } from 'react-i18next'

import Header from 'components/Header'
import { Content } from './styles'
import { DashboardSummaries, DashboardBoothsList, DashboardLists } from './sections'

const DashboardPage = () => {
	const { t } = useTranslation()

	return (
		<>
			<Header title={t('dashboard.title')} />
			<Content>
				<DashboardSummaries />
				<DashboardBoothsList />
				<DashboardLists />
			</Content>
		</>
	)
}

export default DashboardPage
