import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { format, addDays } from 'date-fns'

import { Controller, useForm } from 'react-hook-form'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Textarea from 'components/Ui/Textarea'
import { useFetchData } from 'hooks'
import Select from 'components/Ui/Select'
import { useFetchInfinite } from 'hooks'
import DatePickerInput from 'components/Ui/Datepicker'
import {
	HeaderWrapper,
	StyledHeader,
	Wrapper,
	Row,
	Right,
	ProductLink,
	ProductLinkWrapper
} from './styles'
import { useMutation, useQueryClient } from 'react-query'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import HistoryButton from 'components/Ui/Button/History'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { useUserRoles } from 'hooks'
import { MobileBackButton } from 'components/Ui/Button/Back'
import { BookingSalesTable } from './sales'
import { BookingProductsTable } from './products'
import Checkbox from 'components/Ui/Checkbox'
import { AuthContext } from 'store/AuthContext'
import UserSelect from 'components/UserSelect'
import useSelect from 'hooks/useSelect'

const BookingPage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const storeCtx = useContext(StoreSettingsContext)
	const { isUser } = useUserRoles()
	const [searchParams] = useSearchParams()
	const { auth } = useContext(AuthContext)

	const editBookingSuccessMsg = useToast({
		type: 'success',
		message: t('booking.form.success')
	})
	const editBookingFailMsg = (message: string) => {
		return useToast({
			type: 'error',
			message: message ? message : t('booking.form.error')
		})
	}

	const {
		handleSubmit,
		control,
		register,
		setValue,
		getValues,
		watch,
		trigger,
		formState: { errors }
	} = useForm()
	const { handleSelectChange } = useSelect({ setValue, trigger })
	const watchFrom = watch('from')

	const { data: booking } = useFetchData({
		name: 'fetchBooking',
		endpoint: `/bookings/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				const { commission, payment, user, end_date, start_date, booth, store_notes, is_active } =
					res.data

				const selectedPaymentStatus = paymentStatus.find(
					(status) => status.value === payment.status
				)
				const selectedPaymentMethod = paymentMethod.find(
					(method) => method.value === payment.method
				)

				setValue('user', { label: user.email, value: user.pk })
				setValue('booth', booth)
				setValue('from', new Date(start_date))
				setValue('to', new Date(end_date))
				setValue('payment.status', selectedPaymentStatus)
				setValue('payment.method', selectedPaymentMethod)
				setValue('store_notes', store_notes)
				setValue('is_active', is_active)

				if (commission) {
					setValue('commission', commission)
				}
			},
			enabled: !!auth.accessToken
		}
	})

	const { data: booths, fetchNextPage: fetchNextBooths } = useFetchInfinite({
		name: 'fetchBooths',
		endpoint: '/booths/',
		enabled: !!auth.accessToken
	})

	const { data: rentals, fetchNextPage: fetchNextRentals } = useFetchInfinite({
		name: 'fetchRental',
		endpoint: '/rentals/', // ?is_active=true'
		enabled: !!auth.accessToken
	})

	useEffect(() => {
		if (!getValues('commission') && booking?.data?.rental) {
			const defaultRentalCommission = rentals?.pages[0].results.find(
				(rate: any) => rate.id === booking?.data?.rental
			).commission
			setValue('commission', defaultRentalCommission)
		} else if (
			!getValues('commission') &&
			!booking?.data?.rental &&
			storeCtx?.storeSettings?.rates[0]
		) {
			setValue('commission', storeCtx?.storeSettings?.rates[0].rate)
		}
	}, [booking?.data?.rental, rentals, storeCtx?.storeSettings?.rates])

	useEffect(() => {
		if (rentals && booking?.data.rental) {
			const selectedRental = rentals?.pages[0].results.find(
				(rate: any) => rate.id === booking?.data?.rental
			)
			setValue('rental', {
				value: selectedRental.id,
				label: `${selectedRental.booth_type_name?.name} - ${selectedRental.duration}`
			})
		}
	}, [booking?.data?.rental, rentals])

	useEffect(() => {
		if (booths && booking?.data.booth) {
			setValue('booth', {
				value: booking?.data.booth.id,
				label: `#${booking?.data.booth.number} ${booking?.data.booth.booth_type.name}`
			})
		}
	}, [booking?.data?.booth, booths])

	const { mutate: editBookingMutation, isLoading: editBookingLoading } = useMutation(
		(data: any) => {
			return axiosPrivate.patch(`/bookings/${params?.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBookings')
				editBookingSuccessMsg()

				navigate(searchParams.toString() ? `/bookings?${searchParams.toString()}` : '/bookings')
			},
			onError: (err: any) => {
				const data = err.response.data
				const nonFieldErrors = 'non_field_errors' in data ? data.non_field_errors : []
				const catchAllErrors = '__all__' in data ? data.__all__ : []
				console.log('error:', data)
				const allErrors = [...nonFieldErrors, ...catchAllErrors]
				editBookingFailMsg(allErrors.join('\n'))()
			}
		}
	)

	const onSubmit = (data: any) => {
		const formData = {
			user_id: data.user?.value,
			rental: data.rental?.value,
			start_date: format(data.from, 'yyyy-MM-dd'),
			end_date: format(data.to, 'yyyy-MM-dd'),
			commission: data.commission,
			payment: {
				status: data.payment.status.value,
				method: data.payment.method.value
			},
			booth_id: data.booth?.value,
			store_notes: data.store_notes,
			is_active: data.is_active
		}

		editBookingMutation(formData)
	}

	const paymentStatus = [
		{
			value: 'init',
			label: t('booking.form.payment.status.options.initial')
		},
		{
			value: 'success',
			label: t('booking.form.payment.status.options.success')
		},
		{
			value: 'pending',
			label: t('booking.form.payment.status.options.pending')
		},
		{
			value: 'error',
			label: t('booking.form.payment.status.options.error')
		},
		{
			value: 'canceled',
			label: t('booking.form.payment.status.options.cancelled')
		},
		{
			value: 'undefined',
			label: t('booking.form.payment.status.options.undefined')
		}
	]

	const paymentMethod = [
		{
			value: 'external',
			label: t('booking.form.payment.method.options.external')
		},
		{
			value: 'korta',
			label: t('booking.form.payment.method.options.korta')
		},
		{
			value: 'rapyd',
			label: t('booking.form.payment.method.options.rapyd')
		},
		{
			value: 'netgiro',
			label: t('booking.form.payment.method.options.netgiro')
		}
	]

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('booking.title')} />
				<HistoryButtonWrapper>
					<MobileBackButton
						to={searchParams.toString() ? `/bookings?${searchParams.toString()}` : '/bookings'}
					/>
					<HistoryButton to={`/bookings/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<Wrapper>
				<div>
					<Form
						submitText={t('booking.form.submitText')}
						onSubmit={handleSubmit(onSubmit)}
						isLoading={editBookingLoading}
						disabled={isUser}>
						<FormSubtitle title={t('booking.form.info.title')} hasBorder={false} />
						<Row>
							{!isUser && (
								<Controller
									name="user"
									control={control}
									render={({ field }) => (
										<UserSelect
											id="user"
											label={t('addBooking.form.user.label')}
											placeholder={t('addBooking.form.user.placeholder')}
											error={(errors as any).user?.value}
											required={true}
											{...field}
											onChange={(data: any) => {
												handleSelectChange({
													id: 'user',
													value: data?.value,
													label: data?.label
												})
											}}
										/>
									)}
								/>
							)}
							<Controller
								name="rental"
								control={control}
								render={({ field }) => (
									<Select
										id="rental"
										placeholder={t('booking.form.rental.placeholder')}
										label={t('booking.form.rental.label')}
										required={true}
										options={rentals?.pages
											.reduce((total: any, page: any) => [...total, ...page.results], [])
											.map((rental: any) => ({
												label: `${rental.booth_type_name?.name ?? 'None'} - ${rental.duration}`,
												value: rental.id
											}))}
										{...field}
										error={(errors as any).rental?.value}
										handleInfiniteScroll={fetchNextRentals}
										disabled={isUser}
									/>
								)}
							/>
						</Row>
						<FormSubtitle title={t('booking.form.period.title')} hasBorder={false} />
						<Row>
							<Controller
								control={control}
								name="from"
								render={({ field }) => (
									<DatePickerInput
										label={t('booking.form.from')}
										onChange={field.onChange}
										selected={field.value}
										disabled={isUser}
									/>
								)}
							/>
							<Controller
								control={control}
								name="to"
								render={({ field }) => (
									<DatePickerInput
										label={t('booking.form.to')}
										onChange={field.onChange}
										selected={field.value}
										disabled={isUser}
										minDate={watchFrom ? addDays(new Date(watchFrom), 1) : new Date()}
									/>
								)}
							/>
						</Row>
						<FormSubtitle title={t('booking.form.rental.title')} hasBorder={false} />
						<Row>
							{/* <Input
								id="booth"
								{...register('booth')}
								label={t('booking.form.booth')}
								disabled={true}
							/> */}
							<Controller
								name="booth"
								control={control}
								render={({ field }) => (
									<Select
										id="booth"
										placeholder={t('booking.form.booth.placeholder')}
										label={t('booking.form.booth.label')}
										required={true}
										options={booths?.pages
											.reduce((total: any, page: any) => [...total, ...page.results], [])
											.map((booth: any) => ({
												label: `#${booth.number} ${booth.booth_type_name ?? 'None'}`,
												value: booth.id
											}))}
										{...field}
										error={(errors as any).booth?.value}
										handleInfiniteScroll={fetchNextBooths}
										disabled={isUser}
									/>
								)}
							/>
							<Input
								id="commission"
								type="number"
								step="0.01"
								{...register('commission')}
								label={t('booking.form.commission')}
								disabled={isUser}
							/>
						</Row>
						<FormSubtitle title={t('booking.form.payment.title')} hasBorder={false} />
						<Row>
							<Controller
								name="payment.status"
								control={control}
								render={({ field }) => (
									<Select
										id="payment.status"
										placeholder={t('booking.form.payment.status.placeholder')}
										label={t('booking.form.payment.status.label')}
										options={paymentStatus?.map((status: any) => ({
											label: status.label,
											value: status.value
										}))}
										{...field}
										error={(errors as any).rental?.value}
										disabled={isUser}
									/>
								)}
							/>
							<Controller
								name="payment.method"
								control={control}
								render={({ field }) => (
									<Select
										id="payment.method"
										placeholder={t('booking.form.payment.method.placeholder')}
										label={t('booking.form.payment.method.label')}
										options={paymentMethod?.map((method: any) => ({
											label: method.label,
											value: method.value
										}))}
										{...field}
										error={(errors as any).rental?.value}
										disabled={isUser}
									/>
								)}
							/>
						</Row>
						{!isUser && (
							<>
								<FormSubtitle title={t('booking.form.notes.title')} hasBorder={false} />
								<Checkbox
									id="is_active"
									{...register('is_active')}
									label={t('booking.form.active.label')}
								/>
								<Textarea
									id="store_notes"
									{...register('store_notes')}
									label={t('booking.form.notes.label')}
									disabled={isUser}
								/>
							</>
						)}
					</Form>
					<ProductLinkWrapper>
						<ProductLink to={'/products?booking_id=' + params.id}>See all products</ProductLink>
					</ProductLinkWrapper>
				</div>
				<Right>
					<BookingSalesTable booking_id={params.id}></BookingSalesTable>
					<BookingProductsTable booking_id={params.id}></BookingProductsTable>
				</Right>
			</Wrapper>
		</>
	)
}

export default BookingPage
