import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import Status from 'components/Ui/Status'
import { EllipsisText } from 'components/Ui'
import { UserProps } from './types'
import { Wrapper, Mobile } from './styles'

const User = ({
	id,
	email,
	phone_number,
	name,
	ssn,
	has_payout_request,
	current_bookings,
	default_payout_method,
	openDeleteModal,
	handlePayoutRequest
}: UserProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/users/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('users.user.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('users.user.ellipsisMenu.payout'),
			action: () => handlePayoutRequest(id)
		},
		{
			label: t('users.user.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<Wrapper>
			<EllipsisText>
				{email ? (
					<>
						<Mobile>{t('users.user.email')}</Mobile>{' '}
					</>
				) : (
					''
				)}
				<NavLink to={`/users/${id}`}>{email}</NavLink>
			</EllipsisText>
			<span>
				{name ? (
					<>
						<Mobile>{t('users.user.name')}</Mobile>{' '}
					</>
				) : (
					''
				)}
				{name}
			</span>
			<span>
				{phone_number ? (
					<>
						<Mobile>{t('users.user.phone')}</Mobile>{' '}
					</>
				) : (
					''
				)}
				{phone_number}
			</span>
			<span>
				{ssn ? (
					<>
						<Mobile>{t('users.user.ssn')}</Mobile>{' '}
					</>
				) : (
					''
				)}
				{ssn}
			</span>
			<span>
				{default_payout_method[default_payout_method.kind].account ? (
					<>
						<Mobile>{t('users.user.account')}</Mobile>{' '}
					</>
				) : (
					''
				)}
				{default_payout_method[default_payout_method.kind].branch}-
				{default_payout_method[default_payout_method.kind].ledger}-
				{default_payout_method[default_payout_method.kind].account}
			</span>
			<span>
				{current_bookings != null && current_bookings.length ? (
					<>
						<Mobile>{t('users.user.bookings')} </Mobile>
						{current_bookings != null &&
							Array.isArray(current_bookings) &&
							current_bookings.map((booking: any) => (
								<>
									<span key={booking.id}>
										#{booking?.ref_nr} ({booking.start_date}){', '}
									</span>
								</>
							))}
					</>
				) : (
					''
				)}
			</span>
			<span>
				<Mobile>{t('users.user.payout_request')}</Mobile> <Status active={has_payout_request} />
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</Wrapper>
	)
}

export default User
