import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import Input from 'components/Ui/Input'
import { useMutation } from 'react-query'
// import { useAxiosPrivate } from 'hooks'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { useToast } from 'hooks'
import { IResetPasswordReqBody } from './types'
import { ToastContainer } from 'react-toastify'
import { defaultConfig } from 'config/default'
import {
	StyledSection,
	StyledForm,
	LanguageSwitcherContainer,
	Header,
	Title,
	Subtitle,
	Inputs,
	StyledButton,
	StyledImage
} from './styles'

const PasswordResetPage = () => {
	const { t } = useTranslation()
	// const axiosPrivate = useAxiosPrivate()
	const storeCtx = useContext(StoreSettingsContext)
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	})

	const [isSuccess, setIsSuccess] = useState<boolean>(false)

	// const resetPasswordSuccess = useToast({
	// 	type: 'success',
	// 	message: t('passwordReset.success')
	// })
	const resetPasswordFail = useToast({
		type: 'error',
		message: t('passwordReset.error')
	})

	const { mutate: resetPasswordMutation, isLoading: isResetPasswordLoading } = useMutation(
		({ email }: IResetPasswordReqBody) => {
			return axios.post('/accounts/request-password-reset/', { email })
		},
		{
			onSuccess: () => {
				setIsSuccess(true)
				// resetPasswordSuccess()
			},
			onError: () => {
				resetPasswordFail()
			}
		}
	)

	const onSubmit = (data: IResetPasswordReqBody) => {
		resetPasswordMutation(data)
	}

	return (
		<StyledSection>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<LanguageSwitcherContainer>
					<LanguageSwitcher />
				</LanguageSwitcherContainer>
				{storeCtx?.storeSettings?.logo && (
					<StyledImage src={storeCtx?.storeSettings?.logo} alt="Logo" />
				)}
				<Header>
					<Title>{t('passwordReset.title')}</Title>
					<Subtitle to="/">{t('passwordReset.text')}</Subtitle>
				</Header>
				{!isSuccess ? (
					<>
						<Inputs>
							<Input
								id="email"
								label={t('passwordReset.email')}
								{...register('email')}
								error={(errors as any).email}
							/>
						</Inputs>
						<StyledButton
							type="submit"
							text={t('passwordReset.submit')}
							icon={faArrowRight}
							isLoading={isResetPasswordLoading}
						/>
					</>
				) : (
					<>{t('passwordReset.success')}</>
				)}
			</StyledForm>
			<ToastContainer
				position="top-right"
				autoClose={defaultConfig.redirectTimer}
				rtl={false}
				hideProgressBar
				closeOnClick={false}
			/>
		</StyledSection>
	)
}

export default PasswordResetPage
