import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from '@table-library/react-table-library/compact'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { theme } from 'twin.macro'

import EmptyTable from 'components/Ui/Table/Empty'
import { usePagination } from 'hooks'
import { useFetchData } from 'hooks'
import BoothType from 'components/BoothType'
import { useSearch } from 'hooks'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import { useModal } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import Filters from 'components/Filters'
import CtaLink from 'components/Ui/CtaLink'
import {
	StyledFiltersWrapper,
	FiltersContainer,
	StyledSearchInput,
	StyledTable,
	HeaderContainer,
	StyledHeader,
	DesktopCtaContainer,
	MobileCtaContainer,
	StyledTable2
} from './styles'
import { IActionFilter } from 'interfaces/filters.interface'
import { customTableStyles, TableWrapper } from 'styles/general'
import { useDevice } from 'hooks'
import ContextMenu from 'components/Ui/ContextMenu'
import { useTableSelect } from 'hooks'

const BoothTypes: FC = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const { handleSearch } = useSearch({ name: 'name', cb: setQuery })
	const axiosPrivate = useAxiosPrivate()
	const [checkedBoothTypes, setCheckedBoothTypes] = useState<number[]>([])
	const queryClient = useQueryClient()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const { isMobileOrTablet } = useDevice()
	const [boothTypesData, setBoothTypesData] = useState({ nodes: [] })
	const { select } = useTableSelect(boothTypesData, onSelectChange)
	const navigate = useNavigate()

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()

	const successMessage = useToast({
		type: 'success',
		message: t('boothTypes.modal.delete.success')
	})
	const failMessage = useToast({ type: 'error', message: t('boothTypes.modal.delete.error') })

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('boothTypes.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('boothTypes.modal.bulkDelete.error')
	})

	const { data: boothTypes, isFetching: loadingBoothTypes } = useFetchData({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setBoothTypesData({ nodes: results })
			}
		}
	})

	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const { mutate: boothTypesDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/booth-types/bulk_delete/', { ids: checkedBoothTypes })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBoothTypes')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedBoothTypes([])
				closeBulkDeleteModal()
			}
		}
	)

	const deleteBoothTypeMutation = useMutation(
		() => {
			return axiosPrivate.delete(`/booth-types/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBoothTypes')
				successMessage()
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const deleteBoothType = () => deleteBoothTypeMutation.mutate()

	const deleteBulkBoothTypes = () => boothTypesDeleteMutation()

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const tableHeaders = [
		{
			id: 'name',
			label: t('boothTypes.tableHeaders.name')
		},
		{
			id: 'description',
			label: t('boothTypes.tableHeaders.description')
		},
		{
			id: 'height',
			label: t('boothTypes.tableHeaders.height')
		},
		{
			id: 'width',
			label: t('boothTypes.tableHeaders.width')
		},
		{
			id: 'depth',
			label: t('boothTypes.tableHeaders.depth')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('filters.actions.label'),
			placeholder: t('filters.actions.placeholder'),
			options: [
				{
					value: 'delete',
					label: t('boothTypes.filters.actions.options.delete')
				}
			]
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/booth-types/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('boothTypes.boothType.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('boothTypes.boothType.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('boothTypes.tableHeaders.name'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/booth-types/${item.id}`}>
					{item.name}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('boothTypes.tableHeaders.description'),
			renderCell: (item: any) => item.description
		},
		{
			label: t('boothTypes.tableHeaders.height'),
			renderCell: (item: any) => `${+item.dimensions?.height} cm`
		},
		{
			label: t('boothTypes.tableHeaders.width'),
			renderCell: (item: any) => `${+item.dimensions?.width} cm`
		},
		{
			label: t('boothTypes.tableHeaders.depth'),
			renderCell: (item: any) => `${+item.dimensions?.depth} cm`
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedBoothTypes(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(5, 1fr) 40px;
	`
	}

	return (
		<>
			<HeaderContainer>
				<StyledHeader title={t('boothTypes.title')} handleSearch={handleSearch} />
				<DesktopCtaContainer>
					<CtaLink to="/booth-types/add" text={t('boothTypes.cta')} />
				</DesktopCtaContainer>
			</HeaderContainer>
			<StyledFiltersWrapper>
				<FiltersContainer>
					<StyledSearchInput
						label={t('boothTypes.filters.search.label')}
						placeholder={t('boothTypes.filters.search.placeholder')}
						onChange={handleSearch}
					/>

					<Filters
						filters={actionFilters}
						disableActions={!checkedBoothTypes.length}
						handleFilter={handleActionFilter}
					/>
				</FiltersContainer>
				<MobileCtaContainer>
					<CtaLink to="/booth-types/add" text={t('boothTypes.cta')} />
				</MobileCtaContainer>
			</StyledFiltersWrapper>
			{boothTypesData.nodes != null &&
			Array.isArray(boothTypesData.nodes) &&
			boothTypesData.nodes.length ? (
				<TableWrapper>
					{isMobileOrTablet ? (
						<StyledTable
							isLoading={loadingBoothTypes}
							prev={boothTypes?.data?.previous}
							next={boothTypes?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{boothTypes?.data?.results.map((boothType: any) => (
								<BoothType key={boothType.id} {...boothType} openDeleteModal={handleDelete} />
							))}
						</StyledTable>
					) : (
						<StyledTable2
							columns={columns}
							data={boothTypesData}
							isLoading={loadingBoothTypes}
							select={select}
							prev={boothTypes?.data?.previous}
							next={boothTypes?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}

					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('boothTypes.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={deleteBoothType}>
							<p>{t('boothTypes.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('boothTypes.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={deleteBulkBoothTypes}>
							<p>{t('boothTypes.modal.bulkDelete.text', { count: checkedBoothTypes.length })}</p>
						</DeleteConfirmModal>
					)}
				</TableWrapper>
			) : (
				<EmptyTable isLoading={loadingBoothTypes} />
			)}
		</>
	)
}

export default BoothTypes
