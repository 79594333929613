import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Header from 'components/Header'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useForm } from 'react-hook-form'
import { Wrapper } from './styles'
import { useToast } from 'hooks'
import { useAxiosPrivate } from 'hooks'
// import { addTagValidationSchema } from 'schemas/validation'

const TagAddPage = () => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const successMessage = useToast({ type: 'success', message: t('addTag.form.success') })
	const failMessage = useToast({ type: 'error', message: t('addTag.form.error') })

	const addTagValidationSchema = yup.object({
		name: yup.string().required(t('validation.addTag.name.required'))
	})

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addTagValidationSchema)
	})

	const { mutate: createTag, isLoading: createTagLoading } = useMutation(
		(data) => {
			return axiosPrivate.post('/tags/', data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchTags')
				successMessage()
				navigate('/tags')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		const newData = {
			...data,
			slug: data.name.toLowerCase().replace(/\s+/g, '-')
		}

		createTag(newData)
	}

	return (
		<>
			<Header title={t('addTag.title')} />
			<Wrapper>
				<Form
					submitText={t('addTag.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={createTagLoading}>
					<Input
						id="tag"
						label={t('addTag.form.tag')}
						{...register('name')}
						error={(errors as any).name}
						required={true}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default TagAddPage
